import React, { useEffect, useState } from "react";
import { Card, Table, Tooltip } from "antd";
import ReportsFilter from "./ReportsFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getDayWiseAttendanceReportsPagination } from "hooks/api/reportsApi.js";
import { DAY_WISE_ATTENDANCE_REPORTS } from "constants";
import { EmployeeDetails } from "components/common/CommonComponent";
import dayjs from "dayjs";
import { formatTime12Hour, formatTimeSpan } from 'components/common/validation';

const initialData = {
  day_wise_date: new Date(),
  day_wise_designation: "",
  day_wise_department: "",
  day_wise_employee_grp: ""
}

function DayWiseAttendance() {
  const authtoken = sessionStorage.getItem("token");
  const pageSize = 10;
  const dateFormat = "YYYY-MM-DD";

  const [searchText, setSearchText] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sorting, setSorting] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ ...initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const tableContainerHeight = "72vh";
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const parsedDate = selectedDate ? dayjs(selectedDate) : null;
  const [query, setQuery] = useState(`date=${parsedDate ? parsedDate.format(dateFormat) : ""}&designation=${filterDatas.day_wise_designation ? filterDatas.day_wise_designation : ""}&department=${filterDatas.day_wise_department ? filterDatas.day_wise_department : ""}&employee_group=${filterDatas.day_wise_employee_grp ? filterDatas.day_wise_employee_grp : ""}`);

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  const [data, pageInfo, loading, _message, refetch] = useApiData(getDayWiseAttendanceReportsPagination, {
    pageNo,
    pageSize,
    query,
    searchText,
    sorting,
  });

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  useEffect(() => {
    const parsedDate = selectedDate ? dayjs(selectedDate) : null;
    const newQuery = `date=${parsedDate ? parsedDate.format(dateFormat) : ""}&designation=${filterDatas.day_wise_designation ? filterDatas.day_wise_designation : ""}&department=${filterDatas.day_wise_department ? filterDatas.day_wise_department : ""}&employee_group=${filterDatas.day_wise_employee_grp ? filterDatas.day_wise_employee_grp : ""}`;
    setQuery(newQuery);
  }, [filterDatas]);

  // filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    // refetch(1,
    //   pageSize,
    //   `date=${data.day_wise_date ? dayjs(data.day_wise_date).format(dateFormat) : ""}&designation=${data.day_wise_designation ? data.day_wise_designation : ""}&department=${data.day_wise_department ? data.day_wise_department : ""}&employee_group=${data.day_wise_employee_grp ? data.day_wise_employee_grp : ""}`,
    //   searchText,
    //   sorting);
    setFilterVisible(false);
  }

  const ClosePopUp = () => {
    setFilterVisible(false);
  }

  const clearFilter = () => {
    setFilterDatas({ ...initialData });
    setPageNo(1);
    // refetch(1,
    //   pageSize,
    //   query,
    //   searchText,
    //   sorting);
  }
  //filter end

  const handleSearch = () => {
    setPageNo(1);
    refetch(1,
      pageSize,
      query,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : "";
    refetch(pagination.current,
      pageSize,
      query,
      searchText,
      sortQuery);
    setSorting(sortQuery)
  }

  const columns = [
    {
      title: "Employee",
      dataIndex: "employee__employee_name",
      key: "employee__employee_name",
      width: "20%",
      sorter: true,
      fixed: 'left',
      render: (_, record) => {
        let empDetails = {
          ...record.employee,
          "designation_id": record?.employee.designation ? { id: record?.employee.designation.id, designation_name: record?.employee.designation.name } : { id: 0, designation_name: "" },
          "first_name": record?.employee.employee_name ? record?.employee.employee_name : "",
          "id": record?.employee.employee_id
        }
        return <EmployeeDetails details={empDetails} />;
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      width: "10.8%",
      render: (_, record) => (record?.status?.length > 28 ?
        <Tooltip title={record?.status}>{<span style={{ color: "black" }}>{record?.status?.slice(0, 25) + "..."}</span>}</Tooltip>
        : <p style={{ color: "black" }} className="statusDiv">{record?.status ? record?.status : "-"}</p>),
      align: 'center',
    },
    {
      title: "Check-In",
      dataIndex: "check_in",
      key: "check_in",
      sorter: true,
      render: (_, record) => {
        return (<p>{(record?.check_in && record?.check_in !== "NaT") ? formatTime12Hour(record?.check_in) : "00:00"}</p>)
      }
    },
    {
      title: "Check-Out",
      key: "check_out",
      dataIndex: "check_out",
      sorter: true,
      render: (_, record) => {
        return (<p>{(record?.check_out && record?.check_out !== "NaT") ? formatTime12Hour(record?.check_out) : "00:00"}</p>)
      }
    },
    {
      title: "Late Hours",
      key: "late_hours",
      dataIndex: "late_hours",
      sorter: true,
      width: "10.8%",
      fixed: 'left',
      render: (_, record) => (<span>{(record.late_hours && record?.late_hours !== "NaT") ? formatTimeSpan(record.late_hours) : "-"}</span>),
    },
    {
      title: "Break Hours",
      key: "break_hrs",
      dataIndex: "break_hrs",
      sorter: true,
      width: "10.8%",
      fixed: 'left',
      render: (_, record) => (<span>{(record.break_hrs && record?.break_hrs !== "NaT") ? formatTimeSpan(record.break_hrs)  : "-"}</span>),
    },
    {
      title: "Total Hours",
      key: "total_working_hours",
      dataIndex: "total_working_hours",
      sorter: true,
      width: "12.2%",
      fixed: 'left',
      render: (_, record) => (<span>{(record.total_working_hours && record?.total_working_hours !== "NaT") ? formatTimeSpan(record.total_working_hours)  : "-"}</span>),
    },
    {
      title: "Location",
      key: "location",
      dataIndex: "location",
      sorter: true,
      width: "10.8%",
      fixed: 'left',
      render: (_, record) => (record?.location?.length > 28 ?
        <Tooltip title={record?.location}>{<span style={{ color: "black" }}>{record?.location?.slice(0, 25) + "..."}</span>}</Tooltip>
        : <p style={{ color: "black" }}>{record?.location ? record?.location : "-"}</p>),
    },
  ];

  const handleFilter = () => {
    setFilterVisible(true)
  }

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search)
    setReorderedColumns(reorderedList)
  }

  const queryString =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&date=" + (parsedDate ? parsedDate.format(dateFormat) : "") +
    "&designation=" + (filterDatas.day_wise_designation ? filterDatas.day_wise_designation : "") +
    "&department=" + (filterDatas.day_wise_department !== undefined ? filterDatas.day_wise_department : "") +
    "&employee_group=" + (filterDatas.day_wise_employee_grp !== undefined ? filterDatas.day_wise_employee_grp : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

  // Date changes start - Filter the data based on the selected date
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFilterDatas({ ...filterDatas, day_wise_date: date });
    setDataUpdated(!dataUpdated);
  };
  // Date changes end

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"}>
        <CommonTblHeader pageName={"Day-Wise Attendance Report"}
          pageInfo={pageInfo} handleSearch={handleSearch}
          onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
          url={DAY_WISE_ATTENDANCE_REPORTS} query={queryString}
          isAddRequired={false} isDraftRequired={false}
          isDateRequired={true} handleDateChange={handleDateChange} selectedDate={selectedDate}
        />
      </div>
      <div className="relative h-5/6 mt-1">
        {filterVisible ?
          <div className="absolute w-1/4 z-10" style={{ ...styles.fltContainer, background: "white" }}>
            <ReportsFilter
              pageName={"Day-Wise Attendance Report"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter} />
          </div>
          : null}
        <div className={"mt-5 w-full"} style={styles.fltContainer}>
          <Table
            columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
            dataSource={data?.length > 0 ? data : null}
            loading={loading}
            style={{ "--table-container-height": tableContainerHeight }}
            scroll={{ y: `calc(${tableContainerHeight} - 110px)`, }}
            onChange={handleTableChange}
            pagination={{
              style: { marginRight: 20, padding: 0 },
              defaultPageSize: pageSize,
              showSizeChanger: false,
              showQuickJumper: true,
              total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
              pageSizeOptions: ["10", "20", "30"],
              pageSize,
              defaultCurrent: pageNo,
            }}
            key={dataUpdated ? "updated" : "not-updated"}
          />
        </div>
      </div>
    </Card>
  );
}

export default DayWiseAttendance;
