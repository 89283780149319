import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Card,
  Table,
  Tooltip,
  Tag,
  Dropdown,
  Space,
  Modal,
  Select,
  Form,
  Input,
  DatePicker,
} from "antd";
import IconButton from "components/ui/Button/IconButton";
import moment from "moment";
import { ImagePaths } from "utils/ImagePath";
import EmployeeFilter from "../EmployeeFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getEmployeeLeaveRequests } from "hooks/api/employeeApi/employeeLeaveApi";
import { EMPLOYEE_LEAVE } from "constants";
import AddEditLeaveRequest from "./AddEditLeaveRequest";
import { getEmployeeLeavePolicy } from "hooks/api/masterApi/employeeLeavePolicyApi";
import dayjs from "dayjs";
import {
  deleteDraftItemsById,
  deleteItemsById,
} from "components/common/CommonFuntion";
import ApprovalLogPopUp from "../ApprovalLogPopUp";
import {
  CommonCarouselPopup,
  CommonDeletePopup,
} from "components/common/CommonComponent";
import {
  extractImageName,
  noBlankSpacesValidator,
} from "components/common/validation";
import MyButton from "components/ui/Button/MyButton";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const initialData = {
  leave_type: "",
  from_date: "",
  to_date: "",
  leave_approval_status: "",
  leaveTypeList: [],
};
const LeaveRequest = () => {
  let query = "";
  let queryRef = useRef("");
  const companyid = sessionStorage.getItem("userId");
  const pageSize = 10;
  const dateFormat = "YYYY-MM-DD";
  const authtoken = sessionStorage.getItem("token");

  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sorting, setSorting] = useState("");
  const [selectedDetails, setSelectedDetails] = useState({});
  const [dataUpdated, setDataUpdated] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const [isDraft, setIsDraft] = useState(false);
  const [isApprovalLog, setIsApprovalLog] = useState(false);
  const [approvalData, setApprovalData] = useState([]);
  const [deleteItem, setDeleteClicked] = useState({
    record: undefined,
    isDeleteClicked: false,
    isDraft: false,
  });
  const tableContainerHeight = isDraft ? "77.5vh" : "72vh";
  const [isCancelPopup, setIsCancelPopup] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    leave_type: "",
    from_date: undefined,
    to_date: undefined,
    request_date_time: undefined,
    cancel_date: undefined,
    reason: "",
    cancel_reason: "",
    request_type: "cancel request",
    uploaded_documents: [],
  });
  const [form] = Form.useForm();
  const hideCursorRef = useRef(null);
  const [isImgOpen, setImgOpen] = useState(false);
  const [highlightedId, setHighlightedId] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const location = useLocation();
  
  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  useEffect(() => {
    if (location.state?.type_id) {
      setHighlightedId(location.state?.type_id);
        const timer = setTimeout(() => {
        setHighlightedId(null);
      }, 5000);
        return () => clearTimeout(timer);
    }
  }, [location]);

  const [data, pageInfo, loading, _message, refetch, extraDetails] = useApiData(
    getEmployeeLeaveRequests,
    {
      pageNo,
      pageSize,
      query,
      searchText,
      sorting,
    }
  );

  let available_leave = extraDetails.available_leave
    ? extraDetails.available_leave
    : null;

  const getLeaveTypeList = async () => {
    try {
      const apiData = await getEmployeeLeavePolicy(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setFilterDatas((prevFilterData) => ({
          ...prevFilterData,
          leaveTypeList: [...apiData.data],
        }));
      } else {
        setFilterDatas((prevFilterData) => ({
          ...prevFilterData,
          leaveTypeList: [],
        }));
      }
    } catch (error) {
      setFilterDatas((prevFilterData) => ({
        ...prevFilterData,
        leaveTypeList: [],
      }));
    }
  };

  useEffect(() => {
    getLeaveTypeList();
  }, []);

  // const userPermission = checkUserPermissionFunc("employee", "Leave Request", "SubMenu");

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg("error", _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  const addClick = () => {
    setAddVisible(true);
    setEditVisible(false);
    setSelectedDetails({});
  };

  const handleEditClick = (details) => {
    if (editVisible && Object.keys(selectedDetails).length > 0) {
      ToastMsg("warning", "Please cancel the existing edit operation!");
    } else {
      setEditVisible(true);
      setSelectedDetails(details);
      setAddVisible(false);
    }
  };

  const handleSave = () => {
    setEditVisible(false);
    setAddVisible(false);
    setSelectedDetails({});
    setIsDraft(false);
    refetch(pageNo, pageSize, query, searchText, sorting);
    setDataUpdated(!dataUpdated);
  };

  const handleCancel = () => {
    setEditVisible(false);
    setAddVisible(false);
    setSelectedDetails({});
    setIsCancelPopup(false);
  };

  const handleApprovalLog = (record) => {
    // let data = record?.approval_log?.length > 0 ? record?.approval_log : [];
    setApprovalData(record);
    setIsApprovalLog(true);
  };

  const CancelPopup = (record) => {
    let data = record ? record : null;
    let PrevData = {
      leave_type: data?.leave_type_id,
      from_date: data?.from_date
        ? dayjs(data?.from_date, "DD-MM-YYYY").locale("en")
        : undefined,
      to_date: data?.from_date
        ? dayjs(data?.from_date, "DD-MM-YYYY").locale("en")
        : undefined,
      reason: data?.reason ? data?.reason : "",
      request_date_time: data?.created_date
        ? dayjs(data?.created_date, dateFormat)
        : undefined,
      cancel_date: moment(new Date()),
      cancel_reason: "",
      uploaded_documents:
        data.uploaded_documents?.length > 0
          ? data.uploaded_documents.map((url) => ({
            name: extractImageName(url),
            url: url,
          }))
          : [],
      id: data?.id ? data?.id : null,
      company_id: parseInt(companyid),
      is_active: true,
      employee: data?.employee_id ? data?.employee_id : null,
      rejoining_date: null,
      company: parseInt(data?.company_id),
      approval_status: "cancel inprogress",
    };
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...PrevData,
    }));
    form.setFieldsValue({
      ...PrevData,
    });
    setIsCancelPopup(true);
  };

  const handleApprovalLogCls = () => {
    setIsApprovalLog(false);
    setApprovalData([]);
  };

  const renderDatePicker = (statekey) => {
    return (
      <DatePicker
        allowClear
        inputReadOnly={true}
        value={formData[statekey] ? formData[statekey] : undefined}
        format={"DD-MM-YYYY"}
        style={{ width: "100%" }}
        onChange={(date, dateString) => {
          const newFormData = {
            ...formData,
            [statekey]: date,
          };
          if (statekey === "from_date") {
            newFormData.to_date = undefined;
          }
          setFormData(newFormData);
          form.setFieldsValue(newFormData);
        }}
        disabled={true}
        placeholder=""
      />
    );
  };

  const handleClear = () => {
    setFormData({
      ...formData,
      cancel_reason: "",
    });
    form.setFieldsValue({
      ...formData,
      cancel_reason: "",
    });
  };

  const handleCancelSubmit = useCallback(() => {
    const authtoken = sessionStorage.getItem("token");
    let updatedFormData = { ...formData };
    updatedFormData.from_date = updatedFormData.from_date
      ? dayjs(updatedFormData.from_date, "DD-MM-YYYY").format(dateFormat)
      : null;
    updatedFormData.to_date = updatedFormData.to_date
      ? dayjs(updatedFormData.to_date, "DD-MM-YYYY").format(dateFormat)
      : null;
    updatedFormData.request_date_time = updatedFormData.request_date_time
      ? updatedFormData.request_date_time
      : null;
    updatedFormData.cancel_date = updatedFormData.cancel_date
      ? new Date(updatedFormData?.cancel_date).toISOString().split("T")[0]
      : null;
    updatedFormData.approval_status = "cancel inprogress";
    delete updatedFormData.uploaded_documents;
    try {
      setLoading(true);
      fetch(EMPLOYEE_LEAVE, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${authtoken}`,
        },
        body: JSON.stringify(updatedFormData),
      })
        .then((response) => response.json())
        .then(async (data) => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            handleSave();
            handleCancel();
            setLoading(false);
            setFormData({
              leave_type: "",
              from_date: undefined,
              to_date: undefined,
              request_date_time: undefined,
              reason: "",
              cancel_date: moment(new Date()),
              cancel_reason: "",
              is_active: true,
              company_id: parseInt(companyid),
              uploaded_documents: [],
            });
          } else if (data.status === "fail") {
            setLoading(false);
            ToastMsg("error", data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    }
  }, [formData, handleSave, handleCancel, companyid]);

  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const generateLeaveSentence = (data) => {
    let sentenceParts = [];
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        let readableKey = key.replace(/([A-Z])/g, " $1").toLowerCase();
        sentenceParts.push(`${data[key]} ${readableKey}`);
      }
    }
    let sentence = sentenceParts.join(", ").replace(/,([^,]*)$/, " and$1");

    return `You have ${sentence}.`;
  };

  const handlePreviewImage = (e, index) => {
    e.preventDefault()    
    if (formData?.uploaded_documents.length > 0) {
      setCurrentSlide(index); // Set the clicked image index
      setImgOpen(true);
    }
  };

  const handlePreviewCancel = () => {
    setImgOpen(false);
  };

  const handleCancelPopup = () => {
    let leaveInfo =
      available_leave !== null ? generateLeaveSentence(available_leave) : "";
    return (
      <Modal
        centered
        open={isCancelPopup}
        onCancel={handleCancel}
        footer={null}
        width={"30vw"}
      >
        <div div className="w-full">
          <p id="common_ApprovalTxt">{"Cancel Leave"}</p>
          <Form
            layout="vertical"
            form={form}
            name="basicform"
            onFinish={handleCancelSubmit}
            onSubmit={(e) => e.preventDefault()}
            colon={false}
            requiredMark={false}
            style={{ width: "100%" }}
            initialValues={true}
          >
            <div className="grid grid-cols-1">
              <Form.Item
                label="Select Leave"
                name="leave_type"
                rules={[{ required: true, message: "This field is required " }]}
                tooltip={{
                  title: "This field is required",
                  icon: <span style={{ color: "red" }}>*</span>,
                }}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  value={formData.leave_type ? formData.leave_type : ""}
                  name={"leave_type"}
                  disabled={true}
                  onChange={(value) => {
                    setFormData({
                      ...formData,
                      leave_type: value ? Number(value) : "",
                    });
                    form.setFieldValue(
                      "leave_type",
                      value ? Number(value) : ""
                    );
                  }}
                  filterOption={filterOption}
                  ref={hideCursorRef}
                  onSelect={() => {
                    hideCursorRef.current.blur();
                  }}
                >
                  {filterDatas?.leaveTypeList.map((option) =>
                    option.id === formData.leave_type || option.is_active ? (
                      <Select.Option key={option.id} value={option.id}>
                        {option.leave_name}
                      </Select.Option>
                    ) : null
                  )}
                </Select>
                {leaveInfo && (
                  <p style={{ fontSize: "0.75vw", color: "#616161" }}>
                    {leaveInfo}{" "}
                  </p>
                )}
              </Form.Item>
              <div className="grid gap-4 grid-cols-2">
                {/* from date */}
                <Form.Item
                  label="From Date"
                  name="from_date"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                  tooltip={{
                    title: "This field is required",
                    icon: <span style={{ color: "red" }}>*</span>,
                  }}
                >
                  {renderDatePicker("from_date")}
                </Form.Item>
                {/* to date */}
                <Form.Item
                  label="To Date"
                  name="to_date"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                  tooltip={{
                    title: "This field is required",
                    icon: <span style={{ color: "red" }}>*</span>,
                  }}
                >
                  {renderDatePicker("to_date")}
                </Form.Item>
              </div>
              <div className="grid gap-4 grid-cols-2">
                <Form.Item
                  label="Requested Date"
                  name="request_date_time"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                  tooltip={{
                    title: "This field is required",
                    icon: <span style={{ color: "red" }}>*</span>,
                  }}
                >
                  {renderDatePicker("request_date_time")}
                </Form.Item>
                <Form.Item
                  label="Cancel Date"
                  name="cancel_date"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                  tooltip={{
                    title: "This field is required",
                    icon: <span style={{ color: "red" }}>*</span>,
                  }}
                >
                  {renderDatePicker("cancel_date")}
                </Form.Item>
              </div>
              <div className="leaveText">
                <Form.Item name="reason">
                  <Input.TextArea
                    bordered={true}
                    className="mt-2 textarea"
                    maxLength={150}
                    disabled={true}
                    rows={3}
                    autoSize={{
                      minRows: 3,
                      maxRows: 4,
                    }}
                    placeholder="Please provide the reason for requesting leave."
                    name="reason"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        reason: e.target.value,
                      });
                    }}
                  />
                </Form.Item>
              </div>
              <div>
                {formData.uploaded_documents?.length > 0 ? (
                  <div className="grid grid-cols-2 gap-x-3 gap-y-2 mt-2">
                    {formData.uploaded_documents.map((file, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center border-b"
                        style={{ paddingInline: "0.3dvw" }}
                      >
                        {file?.name?.length > 15 ? (
                          <Tooltip title={file?.name}>
                            {
                              <span style={{ fontSize: "0.8vw" }}>
                                {"..." + file?.name?.slice(-14)}
                              </span>
                            }
                          </Tooltip>
                        ) : (
                          <p style={{ fontSize: "0.8vw" }}>
                            {file?.name ? file?.name : "-"}
                          </p>
                        )}
                        <div>
                          <IconButton
                            title="View"
                            icon={<EyeOutlined style={styles.closeIcon} />}
                            type="button"
                            onClick={(e) => {
                              e.preventDefault(); // Prevent the default form submission behavior
                              handlePreviewImage(e,index);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
              <div className="leaveText" style={{paddingTop:"0.5vw"}}>
                <Form.Item
                  label="Reason For Cancel"
                  name="cancel_reason"
                  rules={[
                    { required: true, message: "This field is required" },
                    { validator: noBlankSpacesValidator },
                  ]}
                  tooltip={{
                    title: "This field is required",
                    icon: <span style={{ color: "red" }}>*</span>,
                  }}
                >
                  <Input.TextArea
                    bordered={true}
                    className="mt-2 textarea"
                    maxLength={150}
                    rows={3}
                    autoSize={{
                      minRows: 3,
                      maxRows: 4,
                    }}
                    placeholder="Enter reason for Cancel"
                    name="cancel_reason"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        cancel_reason: e.target.value,
                      });
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item>
              <div className="flex justify-end items-end">
                <MyButton
                  htmlType="button"
                  label={"Clear"}
                  onClick={() => handleClear()}
                  bgColor={"#cbcbcb"}
                  loading={isLoading}
                  paddingX={"0 1.2vw"}
                  marginRight={"0.625vw"}
                />
                <MyButton
                  htmlType="submit"
                  label={"Submit"}
                  loading={isLoading}
                  paddingX={"0 1.2vw"}
                />
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  };

  const columns = [
    // Request Type
    {
      title: "Request Type",
      dataIndex: "leave_request_type",
      key: "leave_request_type",
      sorter: true,
      width: "15%",
      // fixed: "left",
      render: (_, record) => {
        const text =
          record.leave_request_type
            ?.replace(/_/g, " ")
            ?.toLowerCase()
            ?.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()) || "-";

        const displayedText = text.length > 20 ? `${text.substring(0, 20)}...` : text;

        return (
          <Tooltip title={text.length > 20 ? text : ""}>
            <span>{displayedText}</span>
          </Tooltip>
        );
      },
    },

    // Leave Type / Period
    {
      title: "Leave Type / Period",
      dataIndex: "leave_type/period",
      key: "leave_type/period",
      sorter: true,
      width: "18%",
      align: "center",
      ellipsis: { showTitle: false },
      // fixed: "left",
      render: (_, record) => {
        const text =
          record["leave_type/period"]
            ?.replace(/_/g, " ")
            ?.toLowerCase()
            ?.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()) || "-";

        const displayedText = text.length > 20 ? `${text.substring(0, 20)}...` : text;

        return (
          <Tooltip title={text.length > 20 ? text : ""}>
            <span>{displayedText}</span>
          </Tooltip>
        );
      },
    },

    // From Date
    {
      title: "From Date",
      dataIndex: "from_date",
      key: "from_date",
      sorter: true,
      width: "15%",
      align: "center",
      // fixed: "left",
      render: (_, record) => {
        const formattedDate =
          record.leave_request_type === "permission"
            ? record.from_date
              ? `${moment(record.from_date, "DD-MM-YYYY hh:mm:ss A").format(
                "DD-MM-YYYY"
              )} ${moment(record.from_date, "DD-MM-YYYY hh:mm:ss A").format("h:mm A")}`
              : "-"
            : moment(record.from_date, "DD-MM-YYYY").format("DD-MM-YYYY");

        return (
          <Tooltip title={formattedDate}>
            <span>{formattedDate}</span>
          </Tooltip>
        );
      },
    },

    // To Date
    {
      title: "To Date",
      dataIndex: "to_date",
      key: "to_date",
      sorter: true,
      width: "15%",
      align: "center",
      // fixed: "left",
      render: (_, record) => {
        const formattedDate =
          record.leave_request_type === "permission"
            ? record.to_date
              ? `${moment(record.to_date, "DD-MM-YYYY hh:mm:ss A").format(
                "DD-MM-YYYY"
              )} ${moment(record.to_date, "DD-MM-YYYY hh:mm:ss A").format("h:mm A")}`
              : ""
            : moment(record.to_date, "DD-MM-YYYY").format("DD-MM-YYYY");

        return (
          <Tooltip title={formattedDate}>
            <span>{formattedDate}</span>
          </Tooltip>
        );
      },
    },

    // Reason
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      sorter: true,
      width: "18%",
      ellipsis: { showTitle: false },
      // fixed: "left",
      render: (_, record) => {
        const text = record?.reason || "-";
        const displayedText = text.length > 20 ? `${text.substring(0, 20)}...` : text;

        return (
          <Tooltip title={text.length > 20 ? text : ""}>
            <span>{displayedText}</span>
          </Tooltip>
        );
      },
    },

    // Status
    {
      title: "Status",
      dataIndex: "approval_status",
      key: "approval_status",
      sorter: true,
      width: "15%",
      align: "center",
      // fixed: "left",
      render: (_, record) => {
        const statusColor =
          record.approval_status === "rejected" || record.approval_status === "cancelled"
            ? "#D94854"
            : record.approval_status === "pending" ||
              record.approval_status === "cancel inprogress"
              ? "#E28F12"
              : "#0E862A";

        return (
          <Tooltip title={record.approval_status}>
            <Tag className="statusDiv" color={statusColor}>
              {record.approval_status}
            </Tag>
          </Tooltip>
        );
      },
    },

    // Action
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "15%",
      // fixed: "left",
      render: (_, record) => {
        // Action rendering remains unchanged
        const items = [
          { key: "1", label: <div className="empApproveTxt">Approval Log</div> },
          {
            key: "2",
            label: <div className="empApproveTxt">Cancel Request</div>,
            disabled:
              record.approval_status !== "approved" ||
              !record?.enable_cancel_request,
          },
        ];
        return (
          <div className="flex justify-center items-center">
            <Space size="middle">
              <IconButton
                title="Edit"
                disabled={record.approval_status !== "pending"}
                icon={
                  <img
                    src={
                      record.approval_status === "pending"
                        ? ImagePaths.edit.default
                        : ImagePaths.editOff.default
                    }
                    alt="Edit"
                  />
                }
                onClick={() => (loading ? "" : handleEditClick(record))}
              />
              {record.approval_status === "pending" && (
                <IconButton
                  title="Delete"
                  icon={<img src={ImagePaths.delete.default} alt="Delete" />}
                  onClick={() =>
                    loading
                      ? ""
                      : record.is_draft
                        ? handleDeleteClicked(record, true)
                        : handleDeleteClicked(record, false)
                  }
                  testID={"ug_btn_delete"}
                />
              )}
              <Dropdown
                menu={{
                  items,
                  onClick: ({ key }) => {
                    if (key === "1") {
                      handleApprovalLog(record);
                    } else {
                      CancelPopup(record);
                    }
                  },
                }}
                trigger={["click"]}
                placement="bottomLeft"
                overlayStyle={styles.dropOverLay}
              >
                <IconButton
                  title=""
                  icon={<img src={ImagePaths.moreIcon.default} alt="more" />}
                />
              </Dropdown>
            </Space>
          </div>
        );
      },
    },
  ];

  //delete start
  const handleDeleteClicked = (record, isFromDraft) => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      record: record,
      isDeleteClicked: true,
      isDraft: isFromDraft,
    }));
  };

  const handleCancelBtn = () => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      record: undefined,
      isDeleteClicked: false,
      isDraft: false,
    }));
  };

  const handleDeleteBtn = async (record, isFromDraft) => {
    try {
      let apiData;
      if (isFromDraft) {
        apiData = await deleteDraftItemsById(record.id, EMPLOYEE_LEAVE);
      } else {
        let fetchedData = {
          id: record.id,
          employee: record.employee_id,
          leave_type: record.leave_type_id ? record.leave_type_id : "",
          from_date: record.from_date
            ? dayjs(record.from_date).format(dateFormat)
            : null,
          to_date: record.to_date
            ? dayjs(record.to_date).format(dateFormat)
            : null,
          rejoining_date: record.rejoining_date
            ? dayjs(record.rejoining_date, "DD-MM-YYYY").format(dateFormat)
            : null,
          reason: record.reason ? record.reason : "",
          is_active: false,
        };
        apiData = await deleteItemsById(EMPLOYEE_LEAVE, false, fetchedData);
      }
      if (apiData && apiData.status === "success") {
        ToastMsg("success", apiData.message);
        isFromDraft === true? handleDraft(): handleBackToHome();
      } else {
        ToastMsg("error", apiData.message);
      }
    } catch (error) {
      ToastMsg("error", error.message);
    } finally {
      handleCancelBtn();
    }
  }; //delete end

  // filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    let fromDate = data.from_date
      ? dayjs(data.from_date).format(dateFormat)
      : "";
    let toDate = data.to_date ? dayjs(data.to_date).format(dateFormat) : "";
    setPageNo(1);
    refetch(
      1,
      pageSize,
      `leave_type=${data.leave_type ? data.leave_type : ""
      }&from_date=${fromDate}&to_date=${toDate}&approval_status=${data.leave_approval_status ? data.leave_approval_status : ""
      }`,
      searchText,
      sorting
    );
    setFilterVisible(false);
  };

  const ClosePopUp = () => {
    setFilterVisible(false);
  };

  const clearFilter = (data) => {
    setFilterDatas((prevState) => ({
      ...prevState,
      ...initialData,
      leaveTypeList: data.leaveTypeList.length > 0 ? data.leaveTypeList : [],
    }));
    setPageNo(1);
    refetch(1, pageSize, query, searchText, sorting);
  };
  //filter end
  const handleSearch = () => {
    setEditVisible(false);
    setAddVisible(false);
    setSelectedDetails({});
    setPageNo(1);
    refetch(1, pageSize, query, searchText, sorting);
    setDataUpdated(!dataUpdated);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend"
      ? "desc"
      : sorter.order === "ascend"
        ? "asc"
        : ""
      }&`;
    const sortQuery = sorter.order
      ? `${sortorder}column=${sorter.columnKey}`
      : "";
    queryRef.current = isDraft ? `is_draft=True` : "";
    refetch(
      pagination.current,
      pageSize,
      queryRef.current,
      searchText,
      sortQuery
    );
    setSorting(sortQuery);
  };

  const handleFilter = () => {
    setFilterVisible(true);
  };

  const handleDraft = () => {
    setIsDraft(true);
    setFilterVisible(false);
    setSorting("");
    setPageNo(1);
    queryRef.current = `is_draft=True`;
    refetch(1, pageSize, queryRef.current, searchText, "");
  };

  const handleBackToHome = () => {
    setIsDraft(false);
    setSorting("");
    setPageNo(1);
    queryRef.current = "";
    refetch(1, pageSize, queryRef.current, searchText, "");
  };

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search);
    setReorderedColumns(reorderedList);
  };

  const queryString =
    "?page_no=" +
    pageNo +
    "&page_size=" +
    pageSize +
    "&leave_type=" +
    (filterDatas.leave_type ? filterDatas.leave_type : "") +
    "&from_date=" +
    (filterDatas.from_date
      ? dayjs(filterDatas.from_date).format(dateFormat)
      : "") +
    "&to_date=" +
    (filterDatas.to_date ? dayjs(filterDatas.to_date).format(dateFormat) : "") +
    "&approval_status=" +
    (filterDatas.leave_approval_status !== undefined
      ? filterDatas.leave_approval_status
      : "") +
    "&search=" +
    searchText +
    (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  };

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"}>
        <CommonTblHeader
          pageName={"Leave Request"}
          pageInfo={pageInfo}
          handleSearch={handleSearch}
          selectedDetails={selectedDetails}
          addClick={addClick}
          onStateChange={handleStateChanges}
          handleFilter={handleFilter}
          columns={columns}
          handleDraft={handleDraft}
          isDraft={isDraft}
          handleBackToHome={handleBackToHome}
          url={EMPLOYEE_LEAVE}
          query={queryString}
          addBtnLabel={"New Request"}
        />
      </div>

      <div className="h-5/6 flex justify-center items-center  mt-1">
        {filterVisible ? (
          <div className="w-1/4 mt-5" style={styles.fltContainer}>
            <EmployeeFilter
              pageName={"LeaveRequest"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter}
            />
          </div>
        ) : null}
        <div
          className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"}
          style={styles.fltContainer}
        >
          <Table
            columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
            dataSource={data?.length > 0 ? data : null}
            loading={loading}
            style={{ "--table-container-height": tableContainerHeight }}
            scroll={{ y: `calc(${tableContainerHeight} - 120px)` }}
            onChange={handleTableChange}
            pagination={{
              style: { marginRight: 20, padding: 0 },
              defaultPageSize: pageSize,
              showSizeChanger: false,
              showQuickJumper: true,
              total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
              pageSizeOptions: ["10", "20", "30"],
              pageSize,
              defaultCurrent: pageNo,
            }}
            key={
              isDraft
                ? dataUpdated
                  ? "draftUpdated"
                  : "draftNotUpdated"
                : dataUpdated
                  ? "updated"
                  : "not-updated"
            }
            rowClassName={(record) => {
              return record.id === highlightedId ? "highlighted-row" : "";
            }}
            onRow={(record) => ({
              onDoubleClick: () => { handleEditClick(record) },
              className: 'hover-row',
            })}
          />
        </div>
      </div>

      {addVisible ||
        (editVisible && Object.keys(selectedDetails).length > 0) ? (
        <AddEditLeaveRequest
          editDetails={selectedDetails}
          onHide={handleCancel}
          onSave={handleSave}
          fromEdit={editVisible}
          leaveTypeList={
            filterDatas?.leaveTypeList?.length > 0
              ? filterDatas?.leaveTypeList
              : []
          }
          available_leave={available_leave}
        />
      ) : null}
      {isCancelPopup ? handleCancelPopup() : null}
      {isImgOpen && formData?.uploaded_documents.length > 0 ? (
        <Modal
          centered
          open={isImgOpen}
          onCancel={handlePreviewCancel}
          footer={null}
          width={"50vw"}
        >
          <CommonCarouselPopup documents={[...formData?.uploaded_documents]} initialSlide={currentSlide}/>
        </Modal>
      ) : null}
      {isApprovalLog ? (
        <ApprovalLogPopUp
          TblData={approvalData}
          onClose={handleApprovalLogCls}
        />
      ) : null}
      {deleteItem.isDeleteClicked ? (
        <CommonDeletePopup
          handleCancelBtn={handleCancelBtn}
          handleDeleteBtn={() =>
            deleteItem.isDraft
              ? handleDeleteBtn(deleteItem.record, true)
              : handleDeleteBtn(deleteItem.record, false)
          }
        />
      ) : null}
    </Card>
  );
};

export default LeaveRequest;
