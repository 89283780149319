import React, { useEffect, useState, useRef, useCallback } from "react";
import { Form, Input, Modal, Select, Tooltip } from "antd";
import MyButton from "components/ui/Button/MyButton";
import ToastMsg from "components/common/ToastMsg";
import { PAYROLL_REIMBURSEMENT_APPROVAL } from "constants";
import { ImagePaths } from "utils/ImagePath";
import { extractImageName } from 'components/common/validation';
import IconButton from "components/ui/Button/IconButton";
import { DownloadOutlined } from "@ant-design/icons";
import { downloadPDF } from "components/common/CommonFuntion";
import { EmployeeDetails, CommonCarouselPopup } from "components/common/CommonComponent";
import { EyeOutlined } from "@ant-design/icons";

function EditReimbursement({ fromEdit, editDetails, onHide, onSave, reimbursementTypeList, isEdit }) {
    const authtoken = sessionStorage.getItem("token");
    const companyid = sessionStorage.getItem("userId");
    const prevPropsRef = useRef();
    const [formData, setFormData] = useState({
        reimbursement_type: undefined,
        description: undefined,
        requested_amount: undefined,
        approved_amount: undefined,
        approval_notes: undefined,
        uploaded_documents: [],
    });
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [isImgOpen, setImgOpen] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    useEffect(() => {
        if (editDetails !== null &&
            Object.keys(editDetails).length !== 0 &&
            JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
            JSON.stringify(formData) !== JSON.stringify(editDetails)) {
                let documentList = (editDetails.uploaded_documents && editDetails.uploaded_documents?.length > 0) ?
                editDetails.uploaded_documents.map(url => ({
                    name: extractImageName(url),
                    url: url
                })) : []
            setFormData((prevFormData) => ({
                ...prevFormData,
                id: editDetails.id,
                reimbursement_type: editDetails.reimbursements_type.id ? editDetails.reimbursements_type.id : null,
                requested_amount: editDetails.requested_amount ? editDetails.requested_amount : null,
                approved_amount: editDetails?.request_type === "cancel request" ? (editDetails.approved_amount ? editDetails.approved_amount : null) : (editDetails.requested_amount ? editDetails.requested_amount : null),
                description: editDetails.description ? editDetails.description : null,
                uploaded_documents: documentList,
            }));
            form.setFieldsValue({
                id: editDetails.id,
                reimbursement_type: editDetails.reimbursements_type.id ? editDetails.reimbursements_type.id : null,
                requested_amount: editDetails.requested_amount ? editDetails.requested_amount : null,
                approved_amount: editDetails?.request_type === "cancel request" ? (editDetails.approved_amount ? editDetails.approved_amount : null) : (editDetails.requested_amount ? editDetails.requested_amount : null),
                description: editDetails.description ? editDetails.description : null,
                uploaded_documents: documentList,
            });
            prevPropsRef.current = editDetails
        }
    }, [editDetails])

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
        onHide();
        setFormData((prevdata) => (
            {
                ...prevdata,
                reimbursement_type: undefined,
                description: undefined,
                requested_amount: undefined,
                approved_amount: undefined,
                approval_notes: undefined
            }));
    };


    const handleSubmit = useCallback((isRejectClked) => {
        const authtoken = sessionStorage.getItem("token")
        try {
            setLoading(true)
            let updatedFormData = { ...formData };
            if (isRejectClked && editDetails?.request_type === "cancel request") {
                updatedFormData = { ...updatedFormData, request_type : "reimbursement request" }
            }else{
                updatedFormData.request_type = editDetails?.request_type ? editDetails?.request_type : undefined
            }
            updatedFormData.uploaded_documents = updatedFormData?.uploaded_documents?.length > 0 ?
                updatedFormData.uploaded_documents.map(image => (image.url)) : [];
            if (isRejectClked) {
                updatedFormData = { ...updatedFormData, approval_status: editDetails?.request_type === "cancel request" ? "approved" : 'rejected' };
            } else {
                updatedFormData = { ...updatedFormData, approval_status: editDetails?.request_type === "cancel request" ? "cancelled" : 'approved' };
            }
            fetch(PAYROLL_REIMBURSEMENT_APPROVAL, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${authtoken}`
                },
                body: JSON.stringify(updatedFormData)
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        onSave();
                        handleCancel();
                        setLoading(false);
                        setFormData({
                            company_id: companyid
                        })
                    }
                    else if (data.status === "fail") {
                        setLoading(false);
                        ToastMsg("error", data.message);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    ToastMsg("error", error.message);
                });
        } catch (error) {
            ToastMsg("error", error.message);
        }
    }, [formData, companyid, handleCancel, onSave]);

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSave = useCallback(() => {
        handleSubmit(false);
    }, [handleSubmit]);

    const handleReject = useCallback(() => {
        handleSubmit(true);
    }, [handleSubmit]);

    let empDetails = {
        ...editDetails.employee,
        "designation_id": editDetails?.employee.designation ? { id: editDetails?.employee.designation.id, designation_name: editDetails?.employee.designation.name } : { id: 0, designation_name: "" },
        "first_name": editDetails?.employee.employee_name ? editDetails?.employee.employee_name : "",
        "id": editDetails?.employee.employee_id
    }

    const handlePreviewImage = (e, index) => {    
        e.preventDefault();
        if (formData?.uploaded_documents.length > 0) {
          setCurrentSlide(index); // Set the clicked image index
          setImgOpen(true);
        }
      };
    
      const handlePreviewCancel = () => {
        setImgOpen(false);
      };

    return (
        <Modal
            centered
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            width={"28vw"}>
            <div className="w-full">
                <p id="common_ApprovalTxt">{"Reimbursements"}</p>
                <div className="my-4">
                <p style={styles.empTxt}>{"Employee Details"}</p>
                    <EmployeeDetails details={empDetails} />
                </div>
                <Form
                    layout="vertical"
                    form={form}
                    name="basicform"
                    onFinish={handleSave}
                    onSubmit={(e) => e.preventDefault()}
                    colon={false}
                    requiredMark={false}
                    style={{ width: "100%" }}
                    initialValues={formData}
                >
                    <div className="grid grid-cols-1" style={{height:"55vh", overflowY:"scroll", padding:"0.5vw", scrollbarWidth:"thin", marginBottom:"0.75vw"}}>
                        <Form.Item
                            label="Reimbursements"
                            name="reimbursement_type"
                        >
                            <Select
                                showSearch
                                style={{ width: "100%" }}
                                onChange={(value) => handleChange("reimbursement_type", value)}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={formData.reimbursement_type ? formData.reimbursement_type : ""}
                                placeholder="Select Reimbursement"
                                disabled={fromEdit ? 'true' : 'false'}
                            >
                                {reimbursementTypeList.map(department => (
                                    (department.id === formData.reimbursement_type || department.is_active === true) ? 
                                    <Select.Option key={department.id} value={department.id}>
                                        {department.reimbursement_type_name}
                                    </Select.Option> : null
                                ))}
                            </Select>
                        </Form.Item>

                        <div>
                            <Form.Item
                                label="Requested Amount"
                                name="requested_amount"
                            >
                                <Input
                                    autoComplete='off'
                                    style={{ width: "100%" }}
                                    type={"text"}
                                    name={"requested_amount"}
                                    maxLength={7}
                                    value={formData.requested_amount ? formData.requested_amount : ""}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    data-testid={''}
                                    disabled={fromEdit ? 'true' : 'false'}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Approval Amount"
                                name="approved_amount"
                                rules={[
                                    {
                                        required: true,
                                        validator: (_, value) => {
                                            if (!value) {
                                                return Promise.reject(new Error('This field is required'));
                                            }
                                            if (parseFloat(value) <= 0) {
                                                return Promise.reject(new Error('Enter a valid amount'));
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                                tooltip={{
                                  title: "This field is required",
                                  icon: <span style={{ color: "red" }}>*</span>,
                                }}
                            >
                                <Input
                                    autoComplete='off'
                                    style={{ width: "100%" }}
                                    type={"text"}
                                    name={"approved_amount"}
                                    value={formData.approved_amount ? formData.approved_amount : ""}
                                    maxLength={7}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    data-testid={''}
                                    disabled= {editDetails.request_type === "cancel request"}
                                />
                            </Form.Item>
                        </div>

                        <Form.Item name="description">
                            <Input.TextArea
                                bordered="true"
                                className="mt-2 textarea"
                                rows={4}
                                autoSize={{
                                    minRows: 4,
                                    maxRows: 5,
                                }}
                                maxLength = {150}
                                value={formData.description ? formData.description : ""}
                                placeholder="Description"
                                name="description"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                data-testid={''}
                                disabled={fromEdit ? 'true' : 'false'}
                            />
                        </Form.Item>
                        {formData?.uploaded_documents?.length > 0 ?
                            <Form.Item name="uploaded_documents" label="Documents">
                                {/* {formData?.uploaded_documents?.map(item => {
                                    return (
                                        <div className="flex justify-between items-center p-2" style={styles.documentBorder}>
                                            <div className="flex items-center divide-x divide-black md:divide-x-1">
                                                <img src={ImagePaths.pdfOn.default} alt="pdf" className='admin_org_pdf' />
                                                {item?.name?.length > 20 ?
                                                    <Tooltip title={item.name}>{<span style={styles.docTxt}>{"..." + item.name?.slice(-19)}</span>}</Tooltip>
                                                    : <p style={styles.docTxt}>{item.name ? item.name : "-"}</p>}
                                            </div>
                                            <IconButton
                                                title="Download Pdf"
                                                icon={<DownloadOutlined style={styles.downloadIcon} />}
                                                onClick={(e) => {
                                                    e.preventDefault(); 
                                                    downloadPDF(item.url);
                                                }}
                                            />
                                        </div>
                                    )
                                })} */}
                                {formData.uploaded_documents?.length > 0 ?
                                    <div className="grid grid-cols-2 gap-x-3 gap-y-2 mt-2">
                                        {formData.uploaded_documents.map((file, index) => (
                                            <div key={index} className="flex justify-between items-center border-b" style={{ paddingInline: "0.3dvw" }}>
                                                {file?.name?.length > 15 ?
                                                    <Tooltip title={file?.name}>{<span style={{ fontSize: "0.8vw" }}>{"..." + file?.name?.slice(-14)}</span>}</Tooltip>
                                                    : <p style={{ fontSize: "0.8vw" }}>{file?.name ? file?.name : "-"}</p>}
                                                <div>
                                                    <IconButton
                                                        title="View"
                                                        icon={<EyeOutlined style={styles.closeIcon} />}
                                                        onClick={(e) => handlePreviewImage(e, index)}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    : null}
                            </Form.Item>: null}
                        <Form.Item label="Approval Notes" name="approval_notes">
                            <Input.TextArea
                                autoComplete='off'
                                bordered="true"
                                className="mt-2 textarea"
                                rows={4}
                                autoSize={{
                                    minRows: 4,
                                    maxRows: 5,
                                }}
                                maxLength = {150}
                                value={formData.approval_notes ? formData.approval_notes : ""}
                                placeholder="Enter reason for Approval Notes for a reimbursements"
                                name="approval_notes"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                data-testid={''}
                            />
                        </Form.Item>
                    </div>
                    {isEdit === true ? <Form.Item>
                        <div className='flex justify-end items-end'>
                            <MyButton htmlType="button" label={"Reject"} onClick={handleReject} bgColor={"#D94854"} loading={loading} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID='asgreg_btn_draft' />
                            <MyButton htmlType="submit" label={"Approve"} loading={loading} paddingX={"0 1.2vw"} bgColor={"#0E862A"} />
                        </div>
                    </Form.Item> : null}
                </Form>
            </div >
            {(isImgOpen && formData?.uploaded_documents.length > 0) ?
                <Modal
                    centered
                    open={isImgOpen}
                    onCancel={handlePreviewCancel}
                    footer={null}
                    width={"50vw"}>
                    <CommonCarouselPopup documents={[...formData?.uploaded_documents]} initialSlide={currentSlide} />
                </Modal> : null}
        </Modal >
    );
}
const styles = {
    documentBorder: {
        border: "1px solid #004B49",
        height: "6.85vh",
    },
    docTxt: {
        color: "#183433",
        fontSize: '1.1vw',
        fontFamily: "Urbanist",
        fontWeight: "700",
        padding: "0.3vw",
    },
    empTxt: {
        color: "#183433",
        fontSize: '0.8vw',
        fontFamily: "Urbanist",
        fontWeight: "500",
        marginBottom: "0.3vw"
      },
    closeIcon: {
        color: "#04B7B1",
        fontSize: "0.95vw",
        fontWeight: "bolder"
    }
}
export default EditReimbursement;