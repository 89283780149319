import { Layout, Menu, Space, Avatar, Typography, Dropdown, List, Badge, AutoComplete, message } from "antd";
import { Header, Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { logout } from "hooks/api/logoutApi";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "context/auth/authentication";
import React, { useContext, useEffect, useState, useRef } from "react";
import SwitchUserApi from "hooks/api/SwitchUserApi";
import { ImagePaths } from "utils/ImagePath";
import male from "assets/svg/Male.svg";
import appLogo from "assets/svg/appLogo.svg";
import ToastMsg from "components/common/ToastMsg";
import {checkUserPermissionFunc} from "components/common/CommonFuntion";
import { NOTIFICATION, NOTIFICATION_COUNT } from 'constants';
import moment from "moment";
import { CloseOutlined, LockOutlined } from '@ant-design/icons';
import { getClockify } from 'pages/home/apihook';
import { getMethod } from "components/common/CommonFuntion";
import { EMPLOYEE_RESIGNATION_REQUEST } from 'constants';
import { App_Version } from "constants";

const { SubMenu } = Menu;
const rootSubmenuKeys = ["admin", "master", "configuration", "employee", "payroll", "attendance", "assetmanagement", "reports"];

const menuIconStyle = {width: "1.3dvw", height: "1.3dvw" };

function MyLayout({triggerGetAPI, children }) {
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState("home");
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState([""]);
  const [searchText, setSearchText] = useState("");
  const { logoutauth, loginauth, checkAuth, isAuthenticated } = useContext(AuthContext);
  const [isNotifyClk, setIsNotifyClk] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [notifyCount, setNotifyCount] = useState(0);
  const [isShiftOver, setIsShiftOver] = useState(false);
  const shift_details_session = JSON.parse(sessionStorage.getItem("shiftDetails"));
  const [shiftDetails, setShiftDetails] = useState({
    check_in_time: shift_details_session?.check_in_time ? shift_details_session?.check_in_time : null,
    check_out_time: shift_details_session?.check_out_time ? shift_details_session?.check_out_time : null,
    shift_end_time: shift_details_session?.shift_end_time ? shift_details_session?.shift_end_time : null,
    shift_start_time: shift_details_session?.shift_start_time ? shift_details_session?.shift_start_time : null,
    attendance_id: shift_details_session?.attendance_id ? shift_details_session?.attendance_id : null,
  })

  const authtoken = sessionStorage.getItem("token")
  const profile = sessionStorage.getItem("profile")
  const employeeName = sessionStorage.getItem("employee_name")
  const designation = sessionStorage.getItem("designation")
  const modules = JSON.parse(sessionStorage.getItem("modules"));  
  const subModule = JSON.parse(sessionStorage.getItem("subModule"));
  const planName = sessionStorage.getItem("planName");
  const is_super_admin = JSON.parse(sessionStorage.getItem("is_super_admin"));
  const is_reporting_manager = JSON.parse(sessionStorage.getItem("reportingManager"));
  const is_admin = JSON.parse(sessionStorage.getItem("is_admin"));
  const [resigcount, setResigcount] = useState([]);
  const [cleanedModules, setCleanedModules] = useState([]);
  const is_password_updated = JSON.parse(sessionStorage.getItem("is_password_updated"));
  const [ResignationUrl,setResignationUrl] = useState("employee/offboarding")
  const popupRef = useRef(null);

  useEffect(() => {
    if (!shiftDetails.shift_start_time || !shiftDetails.shift_end_time) {
      return;
    }
    const startTime = new Date(shiftDetails.shift_start_time);
    const endTime = new Date(shiftDetails.shift_end_time);
    if (endTime < startTime) {
      endTime.setDate(endTime.getDate() + 1);
    }
    const calculateTimeLeft = () => {
      const now = new Date();
      const timeDiff = endTime.getTime() - now.getTime();
      return Math.max(timeDiff, 0);
    };
    const timer = setInterval(() => {
      const remainingTime = calculateTimeLeft();
      setIsShiftOver(remainingTime === 0);
      if (remainingTime === 0) {
        clearInterval(timer);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [shiftDetails.shift_start_time, shiftDetails.shift_end_time]);

   const sendClockOutReminderNotification = () => {  
      if (Notification.permission === "granted") {
          const notification = new Notification("Reminder to Clock Out", {
            body: "It’s time to clock out! Please remember to clock out.",
            icon: ImagePaths.loginLogo.default,
            requireInteraction: true,
          });
    
          notification.onclick = () => {
            window.focus();
            notification.close();
            navigate("/home");
          };  
      }
    }

     const useClockOutReminder = (isClockIn) => {
          useEffect(() => {
            let intervalId;
            let notificationSent = false; // Track if the notification has been sent
            if (isClockIn) {
              intervalId = setInterval(() => {
                const now = new Date();
                const shiftEndTime = new Date(shiftDetails?.shift_end_time);
                const timeDifference = shiftEndTime - now;
                // Check if the time is within the required range and the notification has not been sent
                if (timeDifference > 0 && timeDifference <= 6 * 60 * 1000 && !notificationSent) {
                  sendClockOutReminderNotification();
                  notificationSent = true; // Mark notification as sent
                }
              }, 60000); // Check every 1 minute
            }
        
            return () => {
              // Cleanup interval when isClockIn changes or component unmounts
              clearInterval(intervalId);
            };
          }, [isClockIn]);
      };
     useClockOutReminder(shiftDetails?.check_in_time);

  // const handleBeforeUnload = (event) => {
  //   if (isShiftOver && !shiftDetails.check_out_time) {
  //     event.preventDefault();
  //     return (event.returnValue = "");
  //   }
  // };

  // const handleUnload = async() => {
  //   if (isShiftOver && !shiftDetails.check_out_time) {
  //     await handleClockOut();
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   window.addEventListener('unload', handleUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //     window.removeEventListener('unload', handleUnload);
  //   };
  // }, [isShiftOver]);

  useEffect(() => {
    getResigDetail()
  }, [])

  useEffect(() => {
    if (triggerGetAPI) {
      getResigDetail()
    }
  }, [triggerGetAPI]);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      handleCloseNotification();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  
  const getResigDetail = async () => {
    try {
      const apiData = await getMethod(EMPLOYEE_RESIGNATION_REQUEST);
      if (apiData && apiData.status === "success" && apiData.data) {
        setResigcount(apiData.data);
      } else {
        setResigcount([]);
      }

      const shouldRedirect = (apiData?.data?.result?.length > 0) || 
                            apiData?.data?.extras?.active || 
                            is_super_admin || is_admin;
      setResignationUrl(
        shouldRedirect 
          ? "employee/offboarding" 
          : "employee/offboarding/request"
      );
    } catch (error) {
      setResigcount([]);
      setResignationUrl("employee/offboarding/request");
      }
  };
  // const getResigDetail = async () => {
  //   try {
  //     const apiData = await getMethod(EMPLOYEE_RESIGNATION_REQUEST);
  //     if (apiData && apiData.status === "success" && apiData.data) {
  //       resigcount = apiData.data;
        
  //     } else {
  //       resigcount = []
  //     }
      
  //     if ((resigcount && resigcount?.result?.length > 0) || resigcount?.extras?.active || is_super_admin || is_admin) {
  //       setResignationUrl("employee/offboarding")
  //     }
  //     else {
  //       setResignationUrl("employee/offboarding/request")
  //     }
  //   } catch (error) {
  //     resigcount = []
  //   }
  // }
  
  const handleChildData = (data) => {
    if (data) {
      // data from home;
      const { check_in, check_out, shift_end_time, shift_start_time, attendance_id } = data;
      const objSession = { check_in_time: check_in, check_out_time: check_out, shift_end_time, shift_start_time, attendance_id };
  
      setShiftDetails((prevState) => ({
        ...prevState,
        ...objSession
      }));
  
      sessionStorage.setItem("shiftDetails", JSON.stringify(objSession));
    }
  };

  // const handleClockOut = async () => {
  //   try {
  //     const apiData = await getClockify(`?status=clock_out&attendance_id=${shiftDetails?.attendance_id ? shiftDetails?.attendance_id : null}`);
  //     if (apiData && apiData.status === "success") {
  //       handleLogout("from clockout");
  //       window.removeEventListener('beforeunload', handleBeforeUnload);
  //       window.removeEventListener('unload', handleUnload);
  //       window.close();
  //     } else {
  //       ToastMsg("error", apiData.message);
  //     }
  //   } catch (error) {
  //     ToastMsg("error", error.message);
  //   }
  // }

  const getNotificationList = async () => {
    try {
      const authtoken = sessionStorage.getItem("token");
      const url = NOTIFICATION;
      const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
      };

      const response = await fetch(url, requestOptions);
      const apiData = await response.json();

      if (apiData && apiData.status === "success" && apiData.data) {
        setNotificationData([...apiData.data])
        setNotifyCount(0);
      } else {
        setNotificationData([])
      }
    } catch (error) {
      setNotificationData([])
    }
  }

  const getNotificationCount = async () => {
    try {
      const authtoken = sessionStorage.getItem("token");
      const url = NOTIFICATION_COUNT;
      const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
      };

      const response = await fetch(url, requestOptions);
      const apiData = await response.json();
      if (App_Version != apiData?.version) {
        sessionStorage.clear();
        localStorage.clear();
        window.location.reload(true);
      }
      if (apiData && apiData.status === "success" && apiData.data) {
        setNotifyCount(apiData.data);
      } else {
        setNotifyCount(0);
      }
    } catch (error) {
      setNotifyCount(0);
    }
  }

  useEffect(() => {
    getNotificationCount();
  }, [selectedMenuItem]);

  const handleLogout = async ({fromTabCls = ""}) => {
    if (isShiftOver && shiftDetails.check_in_time && !shiftDetails.check_out_time && fromTabCls === "") {
      ToastMsg("warning", "Kindly ensure you clock out before logout")
    } else{
      setShiftDetails((prevState) => ({
        ...prevState,
        check_in_time : null,
        check_out_time : null,
        shift_end_time : null,
        shift_start_time : null,
      }))
    try {
      const data = await logout();
      if (data) {
        if (data.status === "success") {
          logoutauth();
          navigate("/");
        } else if (data.status === "fail") {
          ToastMsg("warning", data.message);
        }
      }
    } catch (error) {
      console.error(error);
    }}
  };

  function modifyUrl(url) {
    // Check for the presence of "/add" and replace it with an empty string
    url = url.replace(/\/add$/, "");

    // Check for the presence of "/edit" and replace it with an empty string
    url = url.replace(/\/edit$/, "");

    // Check for the presence of "/edit" and replace it with an empty string
    url = url.replace(/\/request$/, "");

    // Check for the presence of "/edit" and replace it with an empty string
    url = url.replace(/\/Submission$/, "");

    // Check for the presence of "/updateplan" and replace it with an empty string
    url = url.replace(/\/updateplan$/, "");

     // Check for the presence of "/employeedetails" and replace it with an empty string
     url = url.replace(/\/employeedetails$/, "");

    // Check for the presence of "attendancelog" and remove the back part of the URL
    url = url.replace(/\/attendancelog\/.*$/, "/attendancelog");

    // Check for the presence of "runpayroll" and remove the back part of the URL
    url = url.replace(/\/runpayroll\/.*$/, "/runpayroll");

    // Check for the presence of "approvalinbox" and remove the back part of the URL
    url = url.replace(/\/approvalinbox\/.*$/, "/approvalinbox");

    return url;
  }

  useEffect(() => {
    if(authtoken 
      // && (shiftDetails.check_in_time || shiftDetails.check_out_time)
    ){
    // Extract the current route or URL path and set the selected menu item accordingly
    const currentPath = location.pathname.replace(/^\/+/g, "");
    const activeSubMenu = currentPath.split("/")[0];
    // Helps in selecting the parent side menu 
    let selectedPath = modifyUrl(currentPath);
    setSelectedMenuItem(selectedPath);
    setOpenKeys([activeSubMenu.toString()]);
  } 
  }, [location]);

  // Check if the current time is within shift start and end time
  const isWithinShift = () => {
    const shiftStartTime = new Date(shiftDetails.shift_start_time);
    const shiftEndTime = new Date(shiftDetails.shift_end_time);
    const currentTime = new Date();
    return currentTime >= shiftStartTime && currentTime <= shiftEndTime;
  };

  const handleMenuSelect = ({ key }) => {
    if (authtoken &&
      ((!shiftDetails.shift_start_time && !shiftDetails.shift_end_time)
        || (shiftDetails.check_in_time || shiftDetails.check_out_time) || !isWithinShift()
      )) {
      setSelectedMenuItem(key);
      navigate(key);
    } else {
      ToastMsg("error", "You must clock in to access any forms. Please clock in through the Home");
    }
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
    setSearchText("");
  };

  const items = [{
    key: "1",
    label: (<div className="empApproveTxt" style={{ margin: '-0.5dvw', padding: '0.5dvw' }} onClick={handleLogout}> Log Out</div>),
  }];

  const employerMenuItems = [{ key: 'home', label: 'Home', icon: <img src={ImagePaths.home.default} style={menuIconStyle} alt= "home"/> }];
  // if (checkUserPermissionFunc("dashboard", null, "Menu").permission) {
  //   employerMenuItems.push({ key: 'dashboard', label: 'Dashboard', icon: <img src={ImagePaths.dashboard.default} style={menuIconStyle} alt="dashboard" /> })
  // } on hold
  if (checkUserPermissionFunc("administrator", null, "Menu").permission) {
    let objArr = {
      key: 'admin',
      label: 'Admin',
      icon: <img src={ImagePaths.admin.default} style={menuIconStyle} alt="admin" />,
      subMenu: [],
    }
    if (checkUserPermissionFunc("administrator", "Organization", "SubMenu").permission) {
      objArr.subMenu.push({ key: "admin/companyprofile", label: 'Organization' })
    }
    if (checkUserPermissionFunc("administrator", "User Group", "SubMenu").permission) {
      objArr.subMenu.push({ key: "admin/usergroup", label: 'User Group' })
    }
    if (checkUserPermissionFunc("administrator", "User Permission", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'admin/userpermission', label: 'User Permission' })
    }
    if (checkUserPermissionFunc("administrator", "Notice Board", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'admin/noticeboard', label: 'Notice Board' })
    }
    if (checkUserPermissionFunc("administrator", "Password Policy", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'admin/passwordpolicy', label: 'Password Policy' })
    }
    if (checkUserPermissionFunc("administrator", "Organization Documents", "SubMenu").permission) {
    objArr.subMenu.push({ key: 'admin/organizationdocuments', label: 'Organization Documents' })
    }
    employerMenuItems.push(objArr);
  }
  if (checkUserPermissionFunc("master", null, "Menu").permission) {
    let objArr = {
      key: 'master',
      label: 'Master',
      icon: <img src={ImagePaths.master.default} style={menuIconStyle} alt="master" />,
      subMenu: [],
    }
    if (checkUserPermissionFunc("master", "Employee Type", "SubMenu").permission) {
      objArr.subMenu.push({ key: "master/employeetype", label: 'Employee Type' })
    }
    if (checkUserPermissionFunc("master", "Department", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'master/department', label: 'Department' })
    }
    if (checkUserPermissionFunc("master", "Designation", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'master/designation', label: 'Designation' })
    }
    if (checkUserPermissionFunc("master", "Leave Type", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'master/leavetype', label: 'Leave Type' })
    }
    if (checkUserPermissionFunc("master", "Employee Group", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'master/employeegroup', label: 'Employee Group' })
    }
    if (checkUserPermissionFunc("master", "Reimbursements Type", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'master/reimbursementstype', label: 'Reimbursement Type' })
    }
    if (checkUserPermissionFunc("master", "Asset Type", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'master/assettype', label: 'Asset Type' })
    }
    if (checkUserPermissionFunc("master", "Asset Category", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'master/assetcategory', label: 'Asset Category' })
    }
    if (checkUserPermissionFunc("master", "Asset Sub Category", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'master/assetsubcategory', label: 'Asset Sub Category' })
    }
    if (checkUserPermissionFunc("master", "Letter Type", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'master/lettertype', label: 'Letter Type' })
    }
    // if(checkUserPermissionFunc("master", "Band", "SubMenu").permission){
    //   objArr.subMenu.push({ key: 'master/employeeband', label: 'Band'})
    // }
    employerMenuItems.push(objArr);
  }
  if (checkUserPermissionFunc("configuration", null, "Menu").permission) {
    let objArr = {
      key: 'configuration',
      label: 'Configuration',
      icon: <img src={ImagePaths.configuration.default} style={menuIconStyle} alt="configuration" />,
      subMenu: [],
    }
    if (checkUserPermissionFunc("configuration", "Shift Configuration", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'configuration/shiftconfiguration', label: 'Shift Configuration' })
    }
    // if (checkUserPermissionFunc("configuration", "Salary Deduction", "SubMenu").permission) {
    //   objArr.subMenu.push({ key: 'configuration/salarydeductions', label: 'Salary Deduction' })
    // }
    if (checkUserPermissionFunc("configuration", "Holiday", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'configuration/holiday', label: 'Holiday Setup' })
    }
    if (checkUserPermissionFunc("configuration", "Week off", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'configuration/weekoff', label: 'Weekoff' })
    }
    // if (checkUserPermissionFunc("configuration", "Certificate", "SubMenu").permission) {
    //   objArr.subMenu.push({ key: 'configuration/employeedocument', label: 'Certificates' })
    // } on hold
    // if (checkUserPermissionFunc("configuration", "Approval Hierarchy", "SubMenu").permission) {
    //   objArr.subMenu.push({ key: 'configuration/approvalhierarchy', label: 'Approval Hierarchy' })
    // } on hold
    if (checkUserPermissionFunc("configuration", "Payroll Period", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'configuration/payrollperiod', label: 'Payroll Period' })
    }
    if (checkUserPermissionFunc("configuration", "Subordinate", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'configuration/subordinate', label: 'Subordinate Setup' })
    }
    if (checkUserPermissionFunc("configuration", "Custom Configuration", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'configuration/customconfiguration', label: 'Custom Configuration' });
    }
    if (checkUserPermissionFunc("configuration", "Salary Component", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'configuration/salarycomponent', label: 'Salary Component' });
    }
    if (checkUserPermissionFunc("configuration", "Salary Template", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'configuration/salarytemplate', label: 'Salary Template' });
    }

    employerMenuItems.push(objArr);
  }
  // if (checkUserPermissionFunc("employee", null, "Menu").permission) {
  //   let objArr = {
  //     key: 'employee',
  //     label: 'Employee',
  //     icon: <img src={ImagePaths.employee.default} style={menuIconStyle} alt="employee" />,
  //     subMenu: [],
  //   }
  //   if (checkUserPermissionFunc("employee", "Employees", "SubMenu").permission) {
  //     objArr.subMenu.push({ key: "employee/employees", label: 'Employees' })
  //   }
  //   if (checkUserPermissionFunc("employee", "Leave Request", "SubMenu").permission) {
  //     objArr.subMenu.push({ key: "employee/leaverequest", label: 'Leave Request' })
  //   }
  //   if (checkUserPermissionFunc("employee", "Reimbursements Request", "SubMenu").permission) {
  //     objArr.subMenu.push({ key: "employee/reimbursementsrequest", label: 'Reimbursement Request' })
  //   }
  //   if (checkUserPermissionFunc("employee", "Advance Salary Request", "SubMenu").permission) {
  //     objArr.subMenu.push({ key: "employee/advancesalaryrequest", label: 'Advance Salary Request' })
  //   }
  //   employerMenuItems.push(objArr);
  // }

  
  let objArr = {
    key: 'employee',
    label: 'Employee',
    icon: <img src={ImagePaths.employee.default} style={menuIconStyle} alt="employee" />,
    subMenu: [
      { key: "employee/employees", label: 'Employee' },
      { key: "employee/leaverequest", label: 'Leave Request' },
      { key: "employee/reimbursementsrequest", label: 'Reimbursement Request' },
      { key: "employee/advancesalaryrequest", label: 'Advance Salary Request' },
      { key: "employee/subordinates", label: 'Subordinates' },
      { key: ResignationUrl, label: 'Resignation' },
    ],
  }
  if (checkUserPermissionFunc("employee", null, "Menu").permission) {
    if (checkUserPermissionFunc("employee", "Approval Inbox", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'employee/approvalinbox', label: 'Approval Inbox' })
    }
    if (checkUserPermissionFunc("employee", "TDS Declaration", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'employee/tdsdeclaration', label: 'TDS Declaration Form' })
    }
    if (checkUserPermissionFunc("employee", "Employee Onboarding", "SubMenu").permission) {
      objArr.subMenu.push({ key: "employee/employeeonboarding", label: 'Employee Onboarding' })
    }
  }
  if (is_reporting_manager) {
    objArr.subMenu.push({ key: "employee/transferreportingmanager", label: 'Transfer Reporting Manager' })
  }
  
  if (checkUserPermissionFunc("employee", null, "Menu").permission) {
    if (checkUserPermissionFunc("employee", "Transfer Inbox", "SubMenu").permission) {
      objArr.subMenu.push({ key: "employee/transferinbox", label: 'Transfer Inbox' })
    }
  }
  employerMenuItems.push(objArr);
  if (checkUserPermissionFunc("payroll", null, "Menu").permission) {
    let objArr = {
      key: 'payroll',
      label: 'Payroll',
      icon: <img src={ImagePaths.payroll.default} style={menuIconStyle} alt="payroll" />,
      subMenu: [],
    }
    if (checkUserPermissionFunc("payroll", "Run Payroll", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'payroll/runpayroll', label: 'Run Payroll' })
    }
    if (checkUserPermissionFunc("payroll", "Payroll", "SubMenu").permission) {
      objArr.subMenu.push({ key: "payroll/payroll", label: 'Payroll' })
    }
    //as per discussion
    /*if (checkUserPermissionFunc("payroll", "Approval Inbox", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'payroll/approvalinbox', label: 'Approval Inbox' })
    }*/
    if (checkUserPermissionFunc("payroll", "payslips", "SubMenu").permission) {
      objArr.subMenu.push({ key: 'payroll/payslips', label: 'PaySlip' })
    }
    // common to use no need of permission for this
    objArr.subMenu.push({ key: 'payroll/salarycalculator', label: 'Salary Calculator' })
    employerMenuItems.push(objArr);
  }
  // if (checkUserPermissionFunc("attendance", null, "Menu").permission) {
  //   let objArr = {
  //     key: 'attendance',
  //     label: 'Attendance',
  //     icon: <img src={ImagePaths.attendance.default} style={menuIconStyle} alt="attendance" />,
  //     subMenu: [],
  //   }
  //   if (checkUserPermissionFunc("attendance", "Day wise Attendance", "SubMenu").permission) {
  //     objArr.subMenu.push({ key: 'attendance/dayattendance', label: 'Day-wise Attendance' })
  //   }
  //   if (checkUserPermissionFunc("attendance", "Attendance Log", "SubMenu").permission) {
  //     objArr.subMenu.push({ key: 'attendance/attendancelog', label: 'Attendance Log' })
  //   }
  //   // if (checkUserPermissionFunc("attendance", "Timesheet", "SubMenu").permission) {
  //   //   objArr.subMenu.push({ key: 'attendance/timesheet', label: 'Timesheet' })
  //   // } on hold
  //   employerMenuItems.push(objArr);
  // }
  let objAttendance = {
    key: 'attendance',
    label: 'Attendance',
    icon: <img src={ImagePaths.attendance.default} style={menuIconStyle} alt="attendance" />,
    subMenu: [
      { key: 'attendance/dayattendance', label: 'Day-wise Attendance' },
      { key: 'attendance/attendancelog', label: 'Attendance Log' },
      // { key: 'attendance/timesheet', label: 'Timesheet' }
    ],
  }
  employerMenuItems.push(objAttendance);
  if (checkUserPermissionFunc("Asset Management", null, "Menu").permission) {
    let objArr = {
      key: 'assetmanagement',
      label: 'Asset Management',
      icon: <img src={ImagePaths.assetManagement.default} style={menuIconStyle} alt="assetManagement" />,
      subMenu: [],
    }
    if (checkUserPermissionFunc("Asset Management", "Asset Registry", "SubMenu").permission) {
      objArr.subMenu.push({ key: "assetmanagement/assetregistry", label: 'Asset Registry' })
    }
    if (checkUserPermissionFunc("Asset Management", "Asset Employee Audit", "SubMenu").permission) {
      objArr.subMenu.push({ key: "assetmanagement/assetemployeeaudit", label: 'Asset Employee Audit' })
    }
    employerMenuItems.push(objArr);
  }

  if (checkUserPermissionFunc("Roster", null, "Menu").permission) {
    employerMenuItems.push(
      { key: 'roster', label: 'Roster', icon: <img src={ImagePaths.rosterIcon.default} style={menuIconStyle} alt="roster" /> });
  } 

  if (checkUserPermissionFunc("reports", null, "Menu").permission) {
    let objReports = {
      key: 'reports',
      label: 'Reports',
      icon: <img src={ImagePaths.reports.default} style={menuIconStyle} alt="reports" />,
      subMenu: [],
    }
    if (checkUserPermissionFunc("reports", "Day wise Attendance", "SubMenu").permission) {
      objReports.subMenu.push({ key: 'reports/daywiseattendance', label: 'Day-wise Attendance' })
    }
    if (checkUserPermissionFunc("reports", "Month Wise Attendance", "SubMenu").permission) {
      objReports.subMenu.push({ key: 'reports/monthwiseattendance', label: 'Month Wise Attendance' })
    }
    if (checkUserPermissionFunc("reports", "Salary Process Bank", "SubMenu").permission) {
      objReports.subMenu.push({ key: 'reports/salaryprocessbank', label: 'Salary Process Bank' })
    }
    if (checkUserPermissionFunc("reports", "Employee Salary Summary", "SubMenu").permission) {
      objReports.subMenu.push({ key: 'reports/employeesalarysummary', label: 'Employee Salary Summary' })
    }
    if (checkUserPermissionFunc("reports", "Payroll Detailed Summary", "SubMenu").permission) {
      objReports.subMenu.push({ key: 'reports/payrolldetailedsummary', label: 'Payroll Detailed Summary' })
    }
    if (checkUserPermissionFunc("reports", "Employee ESI Statement", "SubMenu").permission) {
      objReports.subMenu.push({ key: 'reports/employeeesistatement', label: 'Employee ESI Statement' })
    }
    if (checkUserPermissionFunc("reports", "Employee PF Statement", "SubMenu").permission) {
      objReports.subMenu.push({ key: 'reports/employeepfstatement', label: 'Employee PF Statement' })
    }
    if (checkUserPermissionFunc("reports", "Muster Roll", "SubMenu").permission) {
      objReports.subMenu.push({ key: 'reports/musterroll', label: 'Muster Roll' })
    }
    if (checkUserPermissionFunc("reports", "Employee Earning Deduction", "SubMenu").permission) {
      objReports.subMenu.push({ key: 'reports/employeeearningdeduction', label: 'Employee Credits & Debits' })
    }
    if (checkUserPermissionFunc("reports", "Attendance Correction", "SubMenu").permission) {
      objReports.subMenu.push({ key: 'reports/attendancecorrection', label: 'Attendance Correction' })
    }
    if (checkUserPermissionFunc("reports", "Subordinates", "SubMenu").permission) {
      objReports.subMenu.push({ key: 'reports/subordinates', label: 'Subordinates Report' })
    }
    if (checkUserPermissionFunc("reports", "Roster Report", "SubMenu").permission) {
      objReports.subMenu.push({ key: 'reports/rosterreport', label: 'Roster Report' })
    }
    if (checkUserPermissionFunc("reports", "Leave Permission Report", "SubMenu").permission) {
      objReports.subMenu.push({ key: 'reports/leavepermissionreport', label: 'Leave Permission Report' })
    }
    employerMenuItems.push(objReports);
  }

  if (is_super_admin) {
    let objArr = {
      key: 'datamigration',
      label: 'Data Migration',
      icon: <img src={ImagePaths.assetManagement.default} style={menuIconStyle} alt="DataMigration" />,
      subMenu: [],
    }
    if (is_super_admin) {
      objArr.subMenu.push({ key: "datamigration/employeemigration", label: 'Employee Migration' })
    }
    employerMenuItems.push(objArr);
  }
  // if(is_super_admin){
  //   employerMenuItems.push(
  //     { key: 'myplans', label: 'My Plans', icon: <img src={ImagePaths.myplan.default} style={menuIconStyle} alt="roster" /> });
  // }
  

  const flattenMenuItems = () => {
    let flattenedItems = [];
    employerMenuItems.forEach(item => {
      if (item.subMenu) {
        flattenedItems.push(...item.subMenu.map(subItem => ({ key: subItem.key, label: subItem.label })));
      } else {
        flattenedItems.push({ key: item.key, label: item.label });
      }
    });
    return flattenedItems?.length > 0 ? flattenedItems : [];
  };

  const flattenedList = flattenMenuItems();

  const handleOnSelect = (value) => {
    if (value && authtoken &&
      ((!shiftDetails.shift_start_time && !shiftDetails.shift_end_time) ||
        (shiftDetails.check_in_time || shiftDetails.check_out_time) || !isWithinShift())) {
      if (rootSubmenuKeys.indexOf(value) === -1) {
        setSelectedMenuItem(value);
        navigate(value);
      } else {
        setOpenKeys(value ? [value] : []);
      }
    } else {
      ToastMsg("error", "You must clock in to access any forms. Please clock in through the Home");
    }
  }

  const handleSwitchUser = () => {
    SwitchUserApi(
      authtoken,
      (data) => {
        //onSuccess
        logoutauth()
        loginauth(data.data, false)
        checkAuth()
        if (isAuthenticated) {
          navigate("/home");
        }
        ToastMsg("success", data.message);
      },
      (error) => {
        //onError
        ToastMsg("error", error);
      }
    );
  }
  

  useEffect(() => {
    // Recalculate cleanedModules whenever resigcount changes
    const updatedModules = subModule?.map((module) => {
      // Clean the main module name
      let cleanedModuleName = module?.module_name?.replace(/_/g, "");
      if (cleanedModuleName === "administrator") {
        cleanedModuleName = "admin";
      }

      // Clean the submodule names
      const cleanedSubModules = {};
      if (module?.sub_module_list && Object.keys(module?.sub_module_list)?.length > 0) {
        Object.keys(module?.sub_module_list)?.forEach((subModule) => {
          // Clean submodule name and add it as module/submodule
          let cleanedSubModule = `${cleanedModuleName}/${subModule?.replace(/_/g, "")}`;
          if (subModule === "offboarding") {
            cleanedSubModule = (resigcount?.result?.length > 0 || resigcount?.extras?.active || is_super_admin || is_admin)
              ? `${cleanedModuleName}/${subModule?.replace(/_/g, "")}`
              : `${cleanedModuleName}/${subModule?.replace(/_/g, "")}/request`;
          }
          cleanedSubModules[cleanedSubModule] = module?.sub_module_list[subModule];
        });
      }

      // Return the cleaned module and its cleaned submodules
      return {
        ...module,
        module_name: cleanedModuleName,
        sub_module_list: cleanedSubModules,
      };
    });

    setCleanedModules(updatedModules);
  }, [resigcount, subModule]); 

  // const cleanedModules = subModule?.map(module => {
  //   // Clean the main module name
  //   let cleanedModuleName = module?.module_name?.replace(/_/g, "");
  //   if (cleanedModuleName === "administrator") {
  //     cleanedModuleName = "admin";
  //   }
  
  //   // Clean the submodule names
  //   const cleanedSubModules = {};
  //   if (module?.sub_module_list && Object.keys(module?.sub_module_list)?.length > 0) {
  //     Object.keys(module?.sub_module_list)?.forEach(subModule => {
  //       // Clean submodule name and add it as module/submodule
  //       let cleanedSubModule = `${cleanedModuleName}/${subModule?.replace(/_/g, "")}`;
  //       if(subModule === "offboarding"){
  //         if((resigcount && resigcount?.result?.length > 0) || resigcount?.extras?.active || is_super_admin || is_admin){
  //           cleanedSubModule = `${cleanedModuleName}/${subModule?.replace(/_/g, "") }`;
  //         }
  //         else{
  //           cleanedSubModule = `${cleanedModuleName}/${subModule?.replace(/_/g, "") }/request`;
  //         }
  //       }
  //       cleanedSubModules[cleanedSubModule] = module?.sub_module_list[subModule];
  //     });
  //   }
  
  //   // Return the cleaned module and its cleaned submodules
  //   return {
  //     ...module,
  //     module_name: cleanedModuleName,
  //     sub_module_list: cleanedSubModules
  //   };
  // });
  
  const handleCloseNotification = () => {
    setIsNotifyClk(false);
  };
  return (
    <div>
      <Layout hasSider={true} className="h-screen">
        <Sider
          width={"17dvw"}
          className=" h-screen fixed overflow-y-hidden overflow-x-hidden"
          style={{ background: "#04B7B1", }}
        >
          <img src={appLogo} style={styles.sliderAppLogo} alt={"App Logo"} />
          <hr style={{ background: "#ffffff", margin: "6px 0px" }} />
          <Menu
            onSelect={handleMenuSelect}
            rootClassName="overflow-y-auto overflow-x-hidden"
            selectedKeys={[selectedMenuItem]}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            style={styles.menuDiv}
            className="text-white w-full" 
            theme="dark"
            mode="inline"
            disabled={!is_password_updated}
            // items={isAuthenticated ? employerMenuItems.map((menuItem) => ({
            //   key: menuItem.key,
            //   label: menuItem.label,
            //   icon: menuItem.icon,
            //   children: menuItem.subMenu ? menuItem.subMenu.map((subMenuItem) => ({
            //     key: subMenuItem.key,
            //     icon: subMenuItem.icon,
            //     label: subMenuItem.label,
            //   })) : null,
            // })) : []}
          >
           {isAuthenticated && (
            <>
              {employerMenuItems.map((menuItem) => {
                // Find the corresponding module in cleanedModules by module_name
                const matchedModule = cleanedModules?.find(
                  (module) => module?.module_name === menuItem.key
                );
                
                // console.log(employerMenuItems)
                // console.log(matchedModule);
                const isModuleSubscribed = matchedModule?.is_subscribed;
                const subModuleList = matchedModule?.sub_module_list || {};

                return menuItem.subMenu ? (
                  <SubMenu
                    key={menuItem.key}
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "9vw",
                        }}
                      >
                        <label>{menuItem.label}</label>
                        {!isModuleSubscribed && (
                          <div style={{ fontSize: "0.75vw", color: "white" }}>
                            <LockOutlined />
                          </div>
                        )}
                      </div>
                    }
                    icon={menuItem.icon}
                  >
                    {menuItem.subMenu.map((subMenuItem) => {
                      const isSubModuleSubscribed = subModuleList[subMenuItem.key];
                      return (
                        <Menu.Item
                          style={{
                            paddingLeft: !isSubModuleSubscribed
                              ? "25px"
                              : "48px",
                          }}
                          key={
                            isSubModuleSubscribed
                              ? subMenuItem.key
                              : `${subMenuItem.key}/subscribenow`
                          }
                          icon={subMenuItem.icon}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              width: "9.6vw",
                            }}
                          >
                            {!isSubModuleSubscribed && (
                              <div style={{ fontSize: "0.75vw",paddingRight: "8px", color: "white" }}>
                                <LockOutlined />
                              </div>
                            )}
                            <label>{subMenuItem.label}</label>
                          </div>
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                ) : (
                  <Menu.Item
                    key={
                      isModuleSubscribed ? menuItem.key : `${menuItem.key}/subscribenow`
                    }
                    icon={menuItem.icon}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "9vw",
                      }}
                    >
                      <label>{menuItem.label}</label>
                      {!isModuleSubscribed && (
                        <div style={{ fontSize: "0.75vw", color: "white" }}>
                          <LockOutlined />
                        </div>
                      )}
                    </div>
                  </Menu.Item>
                );
              })}
            </>
          )}
          </Menu>
        </Sider>
        <Layout
          className="w-full h-full"
          style={{ background: "#EFEFEF" }}
        >
          <Header
            className="flex justify-end	items-center mr-[10px]"
            style={styles.headerDiv}
          >
            {/* quick search input */}
            <div className="custom-auto-complete-container p-1">
              <img
                src={ImagePaths.search.default}
                alt="search"
                style={{ ...menuIconStyle, margin: "0.1875dvw" }}
              />
              <AutoComplete
                className="custom-auto-complete"
                style={{ width: "100%" }}
                allowClear={true}
                value={searchText}
                disabled ={!is_password_updated}
                options={flattenedList.map((item) => ({
                  value: item.key,
                  label: item.label,
                }))}
                onSelect={(value) => handleOnSelect(value)}
                onChange={(value, option) =>
                  setSearchText(option?.label ? option?.label : value)
                }
                filterOption={(inputValue, option) =>
                  option.label
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                notFoundContent={
                  <div style={{ textAlign: "center", fontSize: "0.95dvw" }}>
                    No data found
                  </div>
                }
                placeholder="Quick Search"
                onKeyDown={(e) => {
                  if (!/[a-zA-Z\s]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            {/* Divider */}
            <div className="hidden sm:block w-px h-8 bg-gray-300"></div>
            {/* Vertical Divider for larger screens */}
            <div className="block sm:hidden w-full h-px bg-gray-300 my-4"></div>
            {/* Horizontal Divider for smaller screens */}

            <Space>
              <div className="flex items-center justify-between gap-3">
                {/* user name */}
                <div className="ml-4 flex flex-col items-end" style={styles.userDp} onClick={() => navigate("/employeeprofile")}>
                  <Typography style={styles.companyTxt}>
                    {employeeName ? employeeName : ""}
                  </Typography>
                  <Typography style={styles.AddressTxt}>{(designation && designation !== "null" && designation !== "undefined")  ? designation : ""}</Typography>
                </div>

                {/* user profile pic */}
                <Avatar
                  src={profile ? profile : male}
                  size="large"
                  style={styles.userDp}
                  onClick={() => navigate("/employeeprofile")}
                >
                </Avatar>
                <div className=" relative" disabled ={!is_password_updated}>
                  <Badge
                    className="mx-3 absolute -top-2"
                    count={notifyCount || ""}
                  ></Badge>
                  <img
                    src={ImagePaths.bellIcon.default}
                    className="layoutIcon cursor-pointer"
                    alt="notification"
                    onClick={() => {
                      setIsNotifyClk(true);
                      getNotificationList();
                    }}
                  />
                </div>
                <div ref={popupRef} className={`list-container ${isNotifyClk ? "visible" : ""}`}>
                  <div className="list-header">
                    Notification
                    <CloseOutlined
                      style={styles.closeIcon}
                      onClick={handleCloseNotification}
                    />
                  </div>
                  <div className="scrollable-container">
                    <List
                      itemLayout="horizontal"
                      dataSource={notificationData}
                      renderItem={(item, index) => (
                        <List.Item key={index}>
                          <List.Item.Meta
                            className={`custom-list-item-meta cursor-pointer p-2 rounded-md ${!item.is_opened ? 'bg-[#04b7b151]': 'bg-white'}`}
                            onClick={() => {
                              const { notification_action, notification_type, type_id } = item;
                            
                              if (notification_action === 'request') {
                                navigate('employee/approvalinbox', { state: { notification_type, type_id } });
                              } else if (['approved', 'rejected', 'cancelled'].includes(notification_action)) {
                                const routes = {
                                  Leave: 'employee/leaverequest',
                                  Advance: 'employee/advancesalaryrequest',
                                  Reimbursement: 'employee/reimbursementsrequest',
                                  Resignation: '/employee/offboarding',
                                };
                            
                                if (routes[notification_type]) {
                                  navigate(routes[notification_type], { state: { notification_type, type_id } });
                                }
                              } else if (notification_action === null) {
                                return message.warning("Something went wrong!");
                              }
                            
                              setIsNotifyClk(false);
                            }}
                            avatar={
                              <img
                                src={
                                  item?.status === "approved"
                                    ? ImagePaths.notifyApprove.default
                                    : ImagePaths.notifyReject.default
                                }
                                className="notifyIcon"
                                alt="Approved"
                              />
                            }
                            title={
                              <p className="notifyTxt cursor-pointer hover:text-[#04b7b1]">
                                {item?.title ? item?.title : "-"}
                              </p>
                            }
                            description={
                              <div>
                                <p className="userPermission-checkbox-subTlt notifyTxt">
                                  {item?.message ? item?.message : "-"}
                                </p>
                                <p className="userPermission-checkbox-subTlt text-right notifyTxt">
                                  {item?.time
                                    ? moment(
                                      item?.time,
                                      "YYYY-MM-DD HH:mm:ss"
                                    ).format("DD-MM-YYYY hh:mm A")
                                    : ""}
                                </p>
                              </div>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </div>
                </div>
                <Dropdown
                  menu={{ items }}
                  trigger={["click"]}
                  placement="bottomLeft"
                  overlayStyle={styles.dropOverLay}
                >
                  <img
                    src={ImagePaths.logoutIcon.default}
                    className="layoutIcon"
                    alt="Logout"
                  />
                </Dropdown>
              </div>
            </Space>
          </Header>
          <Content style={styles.contentDiv}> 
            {children}
            <Outlet context={{ handleChildData }}/>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

const styles = {
  sliderAppLogo: {
    width: "13dvw",
    height: "2.5dvw",
    margin: "0.625dvw auto",
    marginBottom: "0dvw !important",
    display: "flex",
    justifyContent: "start",
    alignItems: "center"
  },
  menuDiv: {
    background: "#04B7B1",
    fontSize: "0.90vw",
    fontFamily: "Urbanist",
    height: "91dvh"
  },
  dropOverLay: {
    width: "9dvw",
    maxHeight: 300,
    overflowY: "auto",
    boxShadow: " 0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
  },
  headerDiv: {
    padding: "0.625dvw",
    height: "8%",
    background: "#EFEFEF",
  },
  companyTxt: {
    color: "#183433",
    fontSize: "0.95dvw",
    fontWeight: "bold",
    fontFamily: "Urbanist"
  },
  AddressTxt: {
    color: "#616161",
    fontSize: "0.8dvw",
    fontFamily: "Urbanist",
  },
  contentDiv: {
    height: "93%",
    margin: "8px 12px",
    marginTop: 0,
    overflowY: "hidden"
  },
  closeIcon: {
    color: "#616161 !important",
    fontSize: "0.95dvw",
    fontWeight: "bold !important"
  },
  userDp: {
    cursor: "pointer",
  }
}
export default MyLayout;
