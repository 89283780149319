import React, { useState, useEffect, useCallback, useRef } from "react";
import { holidaytype } from "../../../components/common/enum";
import { useNavigate, useLocation } from 'react-router-dom';
import MyButton from "components/ui/Button/MyButton";
import { Card, Form, Input, Select, DatePicker, Checkbox, Tooltip } from "antd";
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { CommonCancelPopup } from "components/common/CommonComponent";
import { EMPLOYEE_RESIGNATION_REQUEST } from "constants";
import { getEmployeeApprover} from "hooks/api/employeeApi/getEmployeeResignation";
import dayjs from "dayjs";
import locale from 'antd/es/date-picker/locale/en_US';
import { noBlankSpacesValidator } from 'components/common/validation';

export const ResignationRequest = ({onFormSubmit}) => {
  const dateFormat = "YYYY-MM-DD";
  const [formData, setFormData] = useState({
    approved_by: "",
    final_approved_by: "",
    request_date: dayjs(),
    reason: "",
  });   
  const [loading, setLoading] = useState(false);
  const [isCancelClk, setIsCancelClk] = useState(false);
  const prevPropsRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { fromEdit, editDetails, isActive } = location.state || {};
  const [form] = Form.useForm();
  const [Approver, setApprover] = useState([]);
  const [FinalApprover, setFinalApprover] = useState([]);

  const  getapproverlist = async () => {
    setApprover([])
    setFinalApprover([])
    try {
      const apiData = await getEmployeeApprover(`drop_down=True`);
      if (apiData.data && apiData.status === "success") {
        setApprover(apiData.data?.approver?.length > 0 ? [...apiData.data.approver] : [])
        setFinalApprover(apiData.data?.final_approver?.length > 0 ? [...apiData.data?.final_approver] : [])
      } else {
        setApprover([])
        setFinalApprover([])
      }
    } catch (error) {
        setApprover([])
        setFinalApprover([])
    }
  };
  useEffect(() => {
    getapproverlist();
    if (editDetails && editDetails !== null &&
      Object.keys(editDetails).length !== 0 &&
      JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
      JSON.stringify(formData) !== JSON.stringify(editDetails)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: editDetails?.id,
        approved_by: editDetails?.approved_by ? editDetails?.approved_by : "",
        final_approved_by: editDetails?.final_approved_by ? editDetails?.final_approved_by : "",
        request_date: editDetails?.request_date ? dayjs(editDetails?.request_date, dateFormat) : formData.request_date,
        reason: editDetails?.reason ? editDetails?.reason : "",
      }));
      form.setFieldsValue({
        approved_by: editDetails?.approved_by ? editDetails?.approved_by : "",
        final_approved_by: editDetails?.final_approved_by ? editDetails?.final_approved_by : "",
        request_date: editDetails?.request_date ? dayjs(editDetails?.request_date, dateFormat) : formData.request_date,
        reason: editDetails?.reason ? editDetails?.reason : "",
      });
      prevPropsRef.current = editDetails;
    }
    
    
  }, [editDetails, form])

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    form.setFieldsValue({
      ...formData,
      [name]: value,
    });
  };

  const onChange = (date, dateString) => {
    setFormData({
      ...formData,
      request_date: date,
    });
    form.setFieldsValue({
      ...formData,
      request_date: date,
    });
  };

  const renderInput = (label, statekey, testID = '', maxLength = 150, required = false) => {
    return (
      <>
      <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
      <Input
        autoComplete='off'
        style={styles.textField}
        disabled = {statekey === "code"}
        type={"text"}
        name={statekey}
        value={formData[statekey]}
        maxLength={maxLength}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        data-testID={testID}
      />
      </>
    )
  }

  const handleBackCancel = useCallback(() => {
    form.resetFields();
    navigate('/employee/offboarding');
  }, [navigate, form])

  const handleSubmit = useCallback(() => {
    let methods = fromEdit ? "PUT" : "POST";
    const authtoken = sessionStorage.getItem("token")
    try {
      let updatedFormData = { ...formData };
      updatedFormData.request_date = updatedFormData.request_date ? dayjs(updatedFormData.request_date, "DD-MM-YYYY").format("YYYY-MM-DD") : null;
      fetch(EMPLOYEE_RESIGNATION_REQUEST, {
        method: methods,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${authtoken}`
        },
        body: JSON.stringify(updatedFormData)
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            handleBackCancel();
            setLoading(false);
            setFormData({
                approved_by: "",
                final_approved_by: "",
                request_date: dayjs(),
                reason: "",
            })
            if (onFormSubmit) {
              onFormSubmit();
            }
          }
          else if (data.status === "fail") {
            setLoading(false);
            ToastMsg("error", data.message);
          }
        })
        .catch(error => {
          setLoading(false);
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    } finally {
      setIsCancelClk(false);
    }
  }, [formData, fromEdit, handleBackCancel]);

  const handleSave = useCallback(() => {
    setLoading(true)
    handleSubmit(false);
  }, [handleSubmit]);
  return (
    <Card className='h-full'>
      <div className='h-1/6 flex justify-between items-center p-1 ' style={{ borderBottom: "1px solid #D6D6D6" }}>
        <div className='flex items-center'>
          <Tooltip title={"Back"} placement="bottom">
            <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
          </Tooltip>
          <p className='common_addTltTxt'>{fromEdit ? "Edit Resignation Request" : "Add Resignation Request"}</p>
        </div>
      </div>
      <div className='h-5/6' style={{ margin: "16px 0px" }}>
        <Form
          layout="inline"
          form={form}
          colon={false}
          requiredMark={false}
          className='w-full'
          name="basicform"
          initialValues={formData}
          onFinish={handleSave}
          onSubmit={(e) => e.preventDefault()}
          data-testid='hs_form'
        >
            <Form.Item
            style={styles.formItem}
            name="date"
            >
            <div className='commonInputTlt'>Requested Date <span className='requiredTxt'>*</span></div>
            <DatePicker
            style={styles.textField}
            name="request_date"
            locale={locale}
            value={formData.request_date ? formData.request_date : dayjs()}
            onChange={onChange}
            format={"DD-MM-YYYY"}
            allowClear={true}
            inputReadOnly={true}
            data-testid={'rg_from'}
            disabled={true}
            placeholder = ""
            />
            </Form.Item>
            <Form.Item
              name="approved_by"
              style={styles.formItem}
              rules={[{ required: true, message: "This field is required" }]}
            >
              <div className='commonInputTlt'>Approver <span className='requiredTxt'>*</span></div>
              <Select
                style={styles.textField}
                value={formData.approved_by}
                name={"approved_by"}
                onChange={(value) => handleChange("approved_by", value)}
                data-testid={'hs_approver'}
                disabled={fromEdit}
              >
                {Approver.map((option) => (
                  <Select.Option key={option.id} value={option.id}>{option.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="final_approved_by"
              style={styles.formItem}
              rules={[{ required: true, message: "This field is required" }]}
            >
              <div className='commonInputTlt'>Final Approver<span className='requiredTxt'>*</span></div>
              <Select
                style={styles.textField}
                value={formData.final_approved_by}
                name={"final_approved_by"}
                onChange={(value) => handleChange("final_approved_by", value)}
                data-testid={'hs_final_approver'}
                disabled={fromEdit}
              >
                {FinalApprover.map((option) => (
                  <Select.Option key={option.id} value={option.id}>{option.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          <div className='w-full grid grid-rows-2 gap-5 m-1'>
          <Form.Item
              name="reason"
              style={styles.formItem}
              rules={[{ required: true, message: "This field is required" , validator:noBlankSpacesValidator }]}
            >
          <div className='commonInputTlt'>Reason For Resignation<span className='requiredTxt'>*</span></div>
            <Input.TextArea
                bordered="true"
                className="mt-2 textarea"
                rows={4}
                autoSize={{
                  minRows: 4, 
                  maxRows: 5,
                }}
                maxLength = {150}
                value={formData.reason ? formData.reason : ""}
                placeholder="Reason For Resignation"
                name="reason"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                data-testid={'rg_reason'}
              />
              </Form.Item>
            {/* <Form.Item name="reason" style={styles.formItem}>
              {renderInput('Reason For Resignation', "reason", 'rg_reason', 150)}
            </Form.Item> */}
            <Form.Item style={styles.emptyFormItem} />
          </div>
          <div className='w-full relative inset-y-10'>
            <Form.Item >
              <div className='flex justify-end items-end mr-2'>
                <MyButton htmlType="button" label="Cancel" onClick={handleBackCancel} outlined = {true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="hs_cancel" />                
                <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loading} paddingX={"0 1.2vw"} testID="hs_save" />
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Card>
  );
};

const styles = {
  textField: {
    width: "23vw"
  },
  formItem: {
    margin: "1vw"
  },
  emptyFormItem: {
    width: "23vw",
    margin: "1vw"
  }
}

export default ResignationRequest;
