import React, { useEffect, useState, useRef, useCallback } from "react";
import { Form, Input, Modal, Select, Button, Upload, Tooltip } from "antd";
import MyButton from "components/ui/Button/MyButton";
import ToastMsg from "components/common/ToastMsg";
import { areValuesNotEmptyExceptKeys, extractImageName } from "components/common/validation";
import { EMPLOYEE_GET_REIMBURSEMENTS } from "constants";
import { ImagePaths } from "utils/ImagePath";
import IconButton from "components/ui/Button/IconButton";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { imgUploadSize } from 'components/common/enum';
import { CommonCarouselPopup } from "components/common/CommonComponent";

function AddEditReimbursementRequest({ fromEdit, editDetails, onHide, onSave, reimbursementTypeList }) {
  const authtoken = sessionStorage.getItem("token");
  const companyid = sessionStorage.getItem("userId");
  const prevPropsRef = useRef();

  const [formData, setFormData] = useState({
    reimbursements_type: "",
    description: "",
    amount: "",
    uploaded_documents: [],
    company_id: companyid,
    is_active: true,
  });
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const hideCursorRef = useRef(null);
  const [isImgOpen, setImgOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  useEffect(() => {
    if (editDetails !== null &&
      Object.keys(editDetails).length !== 0 &&
      JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
      JSON.stringify(formData) !== JSON.stringify(editDetails)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: editDetails.id,
        employee: editDetails.employee_id,
        reimbursements_type: editDetails.reimbursements_type_id ? editDetails.reimbursements_type_id : "",
        description: editDetails.description ? editDetails.description : "",
        amount: editDetails.requested_amount ? editDetails.requested_amount : "",
        uploaded_documents: editDetails.uploaded_documents?.length > 0 ?
          editDetails.uploaded_documents.map(url => ({
            name: extractImageName(url),
            url: url
          })) : [],
        is_active: editDetails.is_active,
        company: parseInt(editDetails.company_id),
      }));
      form.setFieldsValue({
        reimbursements_type: editDetails.reimbursements_type_id ? editDetails.reimbursements_type_id : "",
        description: editDetails.description ? editDetails.description : "",
        amount: editDetails.requested_amount ? editDetails.requested_amount : "",
        uploaded_documents: editDetails.uploaded_documents?.length > 0 ?
          editDetails.uploaded_documents.map(url => ({
            name: extractImageName(url),
            url: url
          })) : [],
        is_active: editDetails.is_active,
      });
      prevPropsRef.current = editDetails;
    }
  }, [editDetails])

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    onHide();
    setFormData({
      reimbursements_type: "",
      description: "",
      amount: 0,
      uploaded_documents: [],
      company_id: companyid,
      is_active: true,
    });
  };

  const handleSubmit = useCallback((isDraftClked) => {
    let methods = fromEdit ? "PUT" : "POST";
    const authtoken = sessionStorage.getItem("token")
    try {
      //setLoading(true)
      const dataForm = new FormData();
      dataForm.append("reimbursements_type", formData.reimbursements_type ? formData.reimbursements_type : "")
      dataForm.append("description", formData.description ? formData.description : "")
      dataForm.append("requested_amount", formData.amount ? +formData.amount : 0)
      formData.uploaded_documents?.length > 0 ?
        formData.uploaded_documents.forEach((image, index) => {
          if(image?.url)
          {
            dataForm.append(`uploaded_documents_${index + 1}`, image.url)
          } else {
            dataForm.append(`uploaded_documents_${index + 1}`, image.originFileObj)
          }
        }) : dataForm.append("uploaded_documents", []);
      dataForm.append("is_active", formData.is_active ? formData.is_active : "")
      dataForm.append("company_id", formData.company_id ? formData.company_id : "")
      dataForm.append("request_type", "reimbursement request")

      if (isDraftClked) {
        dataForm.append("is_draft", true);
      } else {
        dataForm.append("is_draft", false);
      }
      if(editDetails?.is_draft){
        dataForm.append("approval_status", "pending")
      }
      if (fromEdit) {
        dataForm.append("id", formData.id);
      }
      fetch(EMPLOYEE_GET_REIMBURSEMENTS, {
        method: methods,
        headers: {
          'Authorization': `token ${authtoken}`
        },
        body: dataForm
      })
        .then((response) => {
          if (response.status === 413) {
            setLoading(false);
            ToastMsg("error", "Request payload is too large");
          }
          return response.json()
        })
        .then(async data => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            onSave();
            handleCancel();
            setLoading(false);
            setIsDisabled(false)
            setFormData({
              reimbursements_type: "",
              description: "",
              amount: 0,
              uploaded_documents: [],
              company_id: companyid,
              is_active: true,
            })
          }
          else if (data.status === "fail") {
            setLoading(false);
            setIsDisabled(false)
            ToastMsg("error", data.message);
          }
        })
        .catch(error => {
          setLoading(false);
          setIsDisabled(false)
          ToastMsg("error", error.message);
        });
    } catch (error) {
      setLoading(false);
      setIsDisabled(false)
      ToastMsg("error", error.message);
    }
  }, [formData, fromEdit, companyid, handleCancel, onSave]);

  const handleChange = (name, value) => {
    switch (name) {
      case "amount":
        setFormData({
          ...formData,
          amount: value,
        });
        form.setFieldsValue({
          ...formData,
          amount: value,
        });
        break;
      default:
        setFormData({
          ...formData,
          [name]: value,
        });
        form.setFieldsValue({
          ...formData,
          [name]: value,
        });
    }
  };

  const handleUploadChange = ({ fileList }) => {
    const filteredFileList = fileList.filter((file) => {
      const fileSizeMB = file.size ? file.size / (1024 * 1024) : true;
      const isImageOrPdf = file.type ? file.type.startsWith('image/') || file.type === 'application/pdf' : true;

      if (!isImageOrPdf) {
        ToastMsg("error", "Unsupported file type");
        return false;
      } else if (fileSizeMB > imgUploadSize) {
        ToastMsg("error", `File size should be less than ${imgUploadSize}MB`);
        return false;
      } else {
        return true;
      }
    });
    setFormData({
      ...formData,
      uploaded_documents: filteredFileList,
    });
  };

  const beforeUpload = (file) => {
    const fileSizeMB = file.size / (1024 * 1024);
    const isImageOrPdf = file.type.startsWith('image/') || file.type === 'application/pdf';
    if (!isImageOrPdf) {
      return false;
    } else if (fileSizeMB > imgUploadSize) {
      return false;
    }
    return true;
  };

  const handleRemoveImage = (index) => {
    const newUploadedImages = [...formData.uploaded_documents];
    newUploadedImages.splice(index, 1);
    setFormData({
      ...formData,
      uploaded_documents: newUploadedImages,
    });
  };

  const handlePreviewImage = (e, index) => {
    e.preventDefault()    
    if (formData?.uploaded_documents.length > 0) {
      setCurrentSlide(index); // Set the clicked image index
      setImgOpen(true);
    }
  };

  const handlePreviewCancel = () => {
    setImgOpen(false);
  };

  const ImageUploader = () => {
    return (
      <div>
        <div className="flex justify-center items-center" style={{ width: '100%', height: "15vw", backgroundColor: "#cbcbcb" }}>
          <div
            className="flex justify-center items-center"
            style={{
              width: '70%',
              height: '70%',
              border: '2px dashed gray',
              borderRadius: "0.3vw",
              position: 'relative'
            }}>
            {
              formData.uploaded_documents?.length === 6 ?
                <p id="common_reimburse_supportTxt">You have reached the maximum limit of 6 file attachments.</p> :
              <Upload
              accept="image/*, application/pdf"
              beforeUpload={beforeUpload}
              onChange={handleUploadChange}
              fileList={formData.uploaded_documents}
              multiple
              maxCount={6}
              showUploadList={false}
            >
              <div className="flex flex-col justify-center items-center">
                <img src={ImagePaths.imageUpload.default} alt="upload image" style={{ width: "2.5vw", height: "2.5vw", marginBottom: "0.8vw" }} />
                <p id="common_cancelTxt" style={{marginBottom: "0.8vw" }}>Drag And Drop Or</p>
                <Button type="text" id="common_cancelTxt" size="small" style={{ borderRadius: 0, marginBottom: "0.8vw" }}>Click Here To Upload</Button>
                <p id="common_reimburse_supportTxt">Supported Format Pdf And All Image Formats</p>
              </div>
            </Upload>
            }
          </div>
        </div>
        {formData.uploaded_documents?.length > 0 ?
          <div className="grid grid-cols-2 gap-x-3 gap-y-2 mt-2">
            {formData.uploaded_documents.map((file, index) => (
              <div key={index} className="flex justify-between items-center border-b" style={{ paddingInline: "0.3dvw" }}>
                {file?.name?.length > 15 ?
                  <Tooltip title={file?.name}>{<span style={{ fontSize: "0.8vw" }}>{"..." + file?.name?.slice(-14)}</span>}</Tooltip>
                  : <p style={{ fontSize: "0.8vw" }}>{file?.name ? file?.name : "-"}</p>}
                <IconButton
                  title="View"
                  icon={<EyeOutlined style={styles.closeIcon} />}
                  onClick={(e) => handlePreviewImage(e, index)}
                />
                <IconButton
                  title="Delete"
                  icon={<DeleteOutlined style={styles.closeIcon} />}
                  onClick={() => handleRemoveImage(index)}
                />
              </div>
            ))}
          </div>
          : null}
      </div>
    );
  }

  const checkAllMandatory = useCallback((isDraftClked) => {
    if (!isDraftClked) {
      form
        .validateFields()
        .then(() => {
          handleSubmit(false);
        })
        .catch(error => {
          console.error("Validation Error:", error);
        });
    } else {
      handleSubmit(true);
    }
  }, [form, handleSubmit]);

  const handleFinish = () => {
    setIsDisabled(true)
    handleSave()
  }

  const handleSave = useCallback(() => {
    setLoading(true);
    checkAllMandatory(false);
  }, [checkAllMandatory]);

  const handleSaveAsDraft = useCallback(() => {
    setLoading(true);
    checkAllMandatory(true);
  }, [checkAllMandatory]);

  const keysToExclude = ['is_active','company_id'];
  const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);

  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={"60vw"}
    >
      <div className="w-full">
        <p id="common_ApprovalTxt">{fromEdit ? "Edit Reimbursement Request" : "Apply For Reimbursement"}</p>
        <Form
          layout="vertical"
          form={form}
          name="basicform"
          onFinish={handleFinish}
          onSubmit={(e) => e.preventDefault()}
          colon={false}
          requiredMark={false}
          style={{ width: "100%" }}
          initialValues={formData}
        >  <div className="grid grid-cols-2 gap-5">
            <div className="grid grid-cols-1">
              <Form.Item
                label="Select Reimbursements Type "
                name="reimbursements_type"
                rules={[{ required: true, message: "This field is required " }]}
                tooltip={{
                  title: "This field is required",
                  icon: <span style={{ color: "red" }}>*</span>,
                }}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  value={formData.reimbursements_type ? formData.reimbursements_type : ""}
                  name={"reimbursements_type"}
                  onChange={(value) => {
                    setFormData({
                      ...formData,
                      reimbursements_type: value ? Number(value) : "",
                    });
                    form.setFieldValue("reimbursements_type", value ? Number(value) : "");
                  }}
                  filterOption={filterOption}
                  ref={hideCursorRef}
                  onSelect={() => {
                    hideCursorRef.current.blur();
                  }}
                >
                  {reimbursementTypeList.map((option) => (
                    (option.id === formData.reimbursements_type || option.is_active) ?
                      <Select.Option key={option.id} value={option.id}>{option.reimbursement_type_name}</Select.Option> : null
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Enter Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject(new Error('This field is required'));
                      }
                      if (parseFloat(value) <= 0) {
                        return Promise.reject(new Error('Enter a valid amount'));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                tooltip={{
                  title: "This field is required",
                  icon: <span style={{ color: "red" }}>*</span>,
                }}
              >
                <Input
                  autoComplete='off'
                  style={{ width: "100%" }}
                  size='large'
                  name={"amount"}
                  value={formData.amount ? formData.amount : ""}
                  maxLength={7}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  onKeyDown={(e) => {
                    // Allow only numbers, backspace, and delete keys
                    if (!/^[\d]$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Home" && e.key !== "End") {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item name="description">
                <Input.TextArea
                  bordered="true"
                  className="mt-2 textarea"
                  rows={3}
                  autoSize={{
                    minRows: 3,
                    maxRows: 4,
                  }}
                  maxLength={150}
                  placeholder="Enter Description"
                  name="description"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </Form.Item>
            </div>
            <Form.Item name="uploaded_documents">
              <ImageUploader />
            </Form.Item>
          </div>
          <Form.Item>
            <div className='flex justify-end items-end'>
              <MyButton htmlType="button" label="Cancel" onClick={handleCancel} outlined = {true} paddingX={"0 0.7vw"} marginRight={"0.625vw"} />
              {fromEdit ? null
                : <MyButton htmlType="button" label={"Draft"} onClick={handleSaveAsDraft} disabled={!isDataAvail} bgColor={isDataAvail ? "#334B49" : "#cbcbcb"} loading={loading} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />}
              <MyButton htmlType="submit" label={fromEdit ? "Update Request" : "Send Request"} disabled={isDisabled} loading={loading} paddingX={"0 1.2vw"} />
            </div>
          </Form.Item>
        </Form>
      </div>
      {(isImgOpen && formData?.uploaded_documents.length > 0) ?
        <Modal
          centered
          open={isImgOpen}
          onCancel={handlePreviewCancel}
          footer={null}
          width={"50vw"}
        >
          <CommonCarouselPopup documents={[...formData?.uploaded_documents]} initialSlide={currentSlide}/>
        </Modal> : null}
    </Modal>
  );
}

const styles = {
  closeIcon: {
    color: "#04B7B1",
    fontSize: "0.95vw",
    fontWeight: "bolder"
  }
}
export default AddEditReimbursementRequest;


