import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import MyButton from "components/ui/Button/MyButton";
import { Card, Form, Input, Select, DatePicker, Checkbox, Tooltip, Spin } from "antd";
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { CommonCancelPopup } from "components/common/CommonComponent";
import { TRANSFER_REPORTING_MANGER } from "constants";
import { noBlankSpacesValidator, areValuesNotEmptyExceptKeys } from "components/common/validation";
import dayjs from "dayjs";
import { disabledPastDate } from "components/common/validation";
import { getEmployeeDesignation } from "hooks/api/masterApi/employeeDesignationApi";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import { getEmployee } from "hooks/api/employeeApi/employeeInformationApi";
import { getUserpermissionById } from "../Transfer Inbox/TransReportingManagerApi";
import { transformUserPermissionArr } from "components/common/CommonFuntion";

export const AddTransferReportingManger = () => {
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [loadings, setLoadings] = useState(false);
    const [formData, setFormData] = useState({
        code: "",
        department: "",
        designation: "",
        employee: "",
        from_date: undefined,
        to_date: undefined,
        comments: "",
        is_active: true,
        permissionList: []
    });
    const [dropdownList, setDropDownList] = useState({
        designationList: [],
        departmentlist: [],
        employeeDetailsList: [],
        filteredEmployeeDetailsList: []
    });
    const [checkedList, setCheckedList] = useState({
        leave: false,
        advance_salary: false,
        reimbursement: false,
        resignation: false,
    });
    const [isCancelClk, setIsCancelClk] = useState(false);
    const prevPropsRef = useRef();

    const navigate = useNavigate();
    const location = useLocation();
    const { fromEdit, editDetails } = location.state || {};
    const [form] = Form.useForm();
    const hideCursorRef = useRef({});

    useEffect(() => {
        if (editDetails !== null && Object.keys(editDetails).length !== 0 &&
            JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
            JSON.stringify(formData) !== JSON.stringify(editDetails)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                id: editDetails.id,
                department: editDetails?.employee_details?.department?.id,
                designation: editDetails?.employee_details?.designation?.id,
                employee: editDetails?.employee_details?.id,
                from_date: editDetails?.from_date ? dayjs(editDetails?.from_date, "YYYY-MM-DD") : undefined,
                to_date: editDetails?.to_date ? dayjs(editDetails?.to_date, "YYYY-MM-DD") : undefined,
                comments: editDetails?.comments,
                is_active: editDetails?.is_active
            }));
            setCheckedList(() => ({
                leave: editDetails?.approval_inbox_permissions?.leave,
                advance_salary: editDetails?.approval_inbox_permissions?.advance_salary,
                reimbursement: editDetails?.approval_inbox_permissions?.reimbursement,
                resignation: editDetails?.approval_inbox_permissions?.resignation,
            }));
            form.setFieldsValue({
                department: editDetails?.employee_details?.department?.id,
                designation: editDetails?.employee_details?.designation?.id,
                employee: editDetails?.employee_details?.id,
                from_date: editDetails?.from_date ? dayjs(editDetails?.from_date, "YYYY-MM-DD") : undefined,
                to_date: editDetails?.to_date ? dayjs(editDetails?.to_date, "YYYY-MM-DD") : undefined,
                comments: editDetails?.comments,
                is_active: editDetails.is_active,
                leave: editDetails?.approval_inbox_permissions?.leave,
                advance_salary: editDetails?.approval_inbox_permissions?.advance_salary,
                reimbursement: editDetails?.approval_inbox_permissions?.reimbursement,
                resignation: editDetails?.approval_inbox_permissions?.resignation,
            });
            prevPropsRef.current = editDetails;
        }
    }, [editDetails, form, formData, dropdownList?.employeeDetailsList])

    useEffect(() => {
        // let userPermissionList = JSON.parse(sessionStorage.getItem("loginUserPermission"));
        // let accessData = userPermissionList?.length > 0 ? userPermissionList : [];
        getDepartmentList();
        getEmployeeDesignationList();
        getEmployeeNameList();
        // setFormData({
        //     ...formData,
        //     permissionList: accessData
        // })
        if (fromEdit) {
            setIsInitialRender(false)
        }
    }, [])

    useEffect(() => {
        if (formData.designation && !formData.department) {
            let filteredData = dropdownList?.employeeDetailsList.filter((data) =>
                data?.designation_id[0]?.id == formData.designation
            );
            setDropDownList((prevState) => ({
                ...prevState,
                filteredEmployeeDetailsList: filteredData
            }));
        }
        else if (!formData.designation && formData.department) {
            let filteredData = dropdownList?.employeeDetailsList.filter((data) =>
                data?.department_id[0]?.id == formData.department
            );
            setDropDownList((prevState) => ({
                ...prevState,
                filteredEmployeeDetailsList: filteredData
            }));
        }
    }, [!formData?.department, !formData?.designation])

    const handleChange = (name, value) => {
        let filteredData = dropdownList?.employeeDetailsList;

        if (name === "employee") {
            const selectedEmployee = dropdownList?.employeeDetailsList.find((emp) => emp.id === value);
            if (selectedEmployee) {
                setFormData({
                    ...formData,
                    employee: value,
                    department: selectedEmployee?.department_id[0]?.id || null,
                    designation: selectedEmployee?.designation_id[0]?.id || null,
                });

                form.setFieldsValue({
                    ...formData,
                    employee: value,
                    department: selectedEmployee?.department_id[0]?.id || null,
                    designation: selectedEmployee?.designation_id[0]?.id || null,
                });
                setDropDownList((prevState) => ({
                    ...prevState,
                    filteredEmployeeDetailsList: dropdownList?.employeeDetailsList
                }));
            }
        } else if (name === "department" || name === "designation") {
            setFormData({
                ...formData,
                employee: '',
                [name]: value
            });

            form.setFieldsValue({
                ...formData,
                employee: '',
                [name]: value
            });

            if (name === "department") {
                if (formData?.designation) {
                    filteredData = dropdownList?.employeeDetailsList.filter((data) =>
                        data?.department_id[0]?.id == value && data?.designation_id[0]?.id == formData?.designation
                    );
                } else {
                    filteredData = dropdownList?.employeeDetailsList.filter((data) =>
                        data?.department_id[0]?.id == value
                    );
                }
            }

            if (name === "designation") {
                if (formData?.department) {
                    filteredData = dropdownList?.employeeDetailsList.filter((data) =>
                        data?.department_id[0]?.id == formData?.department && data?.designation_id[0]?.id == value
                    );
                } else {
                    filteredData = dropdownList?.employeeDetailsList.filter((data) =>
                        data?.designation_id[0]?.id == value
                    );
                }
            }
            setIsInitialRender(true)
            setDropDownList((prevState) => ({
                ...prevState,
                filteredEmployeeDetailsList: filteredData
            }));
        }
    };

    const onChange = (name, value) => {
        const updatedFormData = {
            ...formData,
            [name]: value,
        };
        setFormData(updatedFormData);
        form.setFieldsValue({ [name]: value });
    };

    const getEmployeeDesignationList = async () => {
        try {
            const apiData = await getEmployeeDesignation(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    designationList: [...apiData.data]
                }))
            } else {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    designationList: []
                }))
            }
        } catch (error) {
            setDropDownList((prevFilterData) => ({
                ...prevFilterData,
                designationList: []
            }))
        }
    }

    const getDepartmentList = async () => {
        try {
            const apiData = await getEmployeeAllDepartment(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    departmentlist: [...apiData.data]
                }))
            } else {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    departmentlist: []
                }))
            }
        } catch (error) {
            setDropDownList((prevFilterData) => ({
                ...prevFilterData,
                departmentlist: []
            }))
        }
    }

    const getEmployeeNameList = async () => {
        try {
            setLoadings(true)
            const apiData = await getEmployee(`drop_down=True`);
            if (apiData && apiData?.status === "success" && apiData?.data) {
                const activeEmployees = apiData?.data?.filter(employee => employee?.is_active === true);
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    employeeDetailsList: [...activeEmployees]
                }))
                setLoadings(false);
            } else {
                setLoadings(false);
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    employeeDetailsList: []
                }))
            }
        } catch (error) {
            setLoadings(false);
            setDropDownList((prevFilterData) => ({
                ...prevFilterData,
                employeeDetailsList: []
            }))
        }
    };

    const getEmployeePermission = async (id) => {
        try {
            const apiData = await getUserpermissionById(id);
            if (apiData && apiData?.status === "success" && apiData?.data) {
                const activeEmployees = apiData?.data
                const dataList = (activeEmployees?.permissions && activeEmployees?.permissions?.length) ? transformUserPermissionArr([...activeEmployees?.permissions]) : [];
                setFormData((prevData) => ({
                    ...prevData,
                    permissionList: [...dataList]
                }))
            } else {
                setFormData((prevData) => ({
                    ...prevData,
                    permissionList: []
                }))
            }
        } catch (error) {
            setFormData((prevData) => ({
                ...prevData,
                permissionList: []
            }))
        }
    };

    const handleCheckBox = (key) => (e) => {
        const checked = e.target.checked;
        setCheckedList((prevState) => {
            const updatedState = { ...prevState, [key]: checked };
            return updatedState;
        });
    };

    const handleBackCancel = useCallback(() => {
        form.resetFields();
        navigate('/employee/transferreportingmanager');
    }, [navigate, form])

    const handleCancelBtn = () => {
        setIsCancelClk(false);
        handleBackCancel();
    }

    const handleSubmit = useCallback((isDraftClked) => {
        let methods = fromEdit ? "PUT" : "POST";
        const authtoken = sessionStorage.getItem("token")
        try {
            setLoadings(true)
            let updatedFormData = {
                "employee": formData?.employee,
                "from_date": formData?.from_date ? dayjs(formData?.from_date, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
                "to_date": formData?.to_date ? dayjs(formData?.to_date, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
                "comments": formData.comments,
                "approval_inbox_permissions": JSON.stringify(checkedList),
                "id": formData?.id ? formData?.id : null,
                "revert_status": false
            };
            if (isDraftClked) {
                updatedFormData = { ...updatedFormData, is_draft: true };
            } else {
                updatedFormData = { ...updatedFormData, is_draft: false };
            }
            fetch(TRANSFER_REPORTING_MANGER, {
                method: methods,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${authtoken}`
                },
                body: JSON.stringify(updatedFormData)
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        handleBackCancel();
                        setLoadings(false);
                        setFormData({
                            code: "",
                            department: "",
                            designation: "",
                            employee: "",
                            from_date: undefined,
                            to_date: undefined,
                            comments: "",
                            is_active: true
                        })
                    }
                    else if (data.status === "fail") {
                        setLoadings(false);
                        ToastMsg("error", data.message);
                    }
                })
                .catch(error => {
                    setLoadings(false);
                    ToastMsg("error", error.message);
                });
        } catch (error) {
            ToastMsg("error", error.message);
        } finally {
            setIsCancelClk(false);
        }
    }, [formData, fromEdit, handleBackCancel]);

    const checkAllMandatory = useCallback((isDraftClked) => {
        if (!isDraftClked) {
            form
                .validateFields()
                .then(() => {
                    handleSubmit(false);
                })
                .catch(error => {
                    console.error("Validation Error:", error);
                });
        } else {
            handleSubmit(true);
        }
    }, [form, handleSubmit]);

    const handleSave = useCallback(() => {
        checkAllMandatory(false);
    }, [checkAllMandatory]);

    const handleSaveAsDraft = useCallback(() => {
        checkAllMandatory(true);
    }, [checkAllMandatory]);

    const keysToExclude = ['is_active', 'company_id'];
    const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);

    return (
        <Card className='h-full'>
            {loadings && (
                <div className="loaderOverlay">
                    <Spin />
                </div>
            )}
            <div className='h-1/6 flex justify-between items-center p-1 ' style={{ borderBottom: "1px solid #D6D6D6" }}>
                <div className='flex items-center'>
                    <Tooltip title={"Back"} placement="bottom">
                        <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
                    </Tooltip>
                    <p className='common_addTltTxt'>{fromEdit ? "Edit Transfer Reporting Manager" : "Add Transfer Reporting Manager"}</p>
                </div>
            </div>
            <div className='h-5/6' style={{ margin: "16px 0px" }}>
                <Form
                    layout="inline"
                    form={form}
                    colon={false}
                    requiredMark={false}
                    className='w-full'
                    name="basicform"
                    initialValues={formData}
                    onFinish={handleSave}
                    onSubmit={(e) => e.preventDefault()}
                    data-testid='hs_form'
                >
                    <div className='w-full' style={{ overflowY: "scroll", height: "70vh" }}>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: "1vw", marginBottom: "0.5vw" }}>
                            <Form.Item
                                name="department"
                                style={styles.formItem}
                                rules={[{ required: true, message: "This field is required" }]}
                            >
                                <div className="commonInputTlt">Select Department <span className="requiredTxt">*</span></div>
                                <Select
                                    style={styles.textField}
                                    value={formData.department}
                                    name="department"
                                    onChange={(value) => handleChange("department", value)}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={hideCursorRef?.current["department"]}
                                    onSelect={() => hideCursorRef?.current["department"]?.current?.blur()}
                                    data-testid="hs_type"
                                    allowClear
                                >
                                    {dropdownList?.departmentlist.map((option) => (
                                        (option?.is_active === true || option.id === formData?.department) ?
                                            <Select.Option key={option.id} value={option.id}>{option?.department_name}</Select.Option>
                                            : null
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="designation"
                                style={styles.formItem}
                                rules={[{ required: true, message: "This field is required" }]}
                            >
                                <div className="commonInputTlt">Select Designation <span className="requiredTxt">*</span></div>
                                <Select
                                    style={styles.textField}
                                    value={formData.designation}
                                    name="designation"
                                    onChange={(value) => handleChange("designation", value)}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={hideCursorRef?.current["designation"]}
                                    onSelect={() => hideCursorRef?.current["designation"]?.current.blur()}
                                    data-testid="hs_type"
                                    allowClear
                                >
                                    {dropdownList.designationList.map((option) => (
                                        (option?.is_active === true || option.id === formData?.designation) ?
                                            <Select.Option key={option.id} value={option.id}>{option?.designation_name}</Select.Option>
                                            : null
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="employee"
                                style={styles.formItem}
                                rules={[{ required: true, message: "This field is required" }]}
                            >
                                <div className="commonInputTlt">Select Employee <span className="requiredTxt">*</span></div>
                                <Select
                                    style={styles.textField}
                                    value={formData.employee ? formData.employee : undefined}
                                    name="employee"
                                    onChange={(value) => handleChange("employee", value)}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    ref={hideCursorRef?.current["employee"]}
                                    onSelect={(value) => {
                                        hideCursorRef?.current["employee"]?.current?.blur()
                                        // getEmployeePermission(value)
                                    }}
                                    data-testid="hs_type"
                                >
                                    {(!isInitialRender ? dropdownList?.employeeDetailsList : formData.department || formData.designation ? dropdownList?.filteredEmployeeDetailsList : dropdownList?.employeeDetailsList).map((option) => (
                                        ((option.is_active === true && !option.is_draft) || option.id === formData?.employee) ?
                                            <Select.Option key={option.id} value={option.id}>
                                                {`${option.first_name ? option.first_name : ""} ${option.last_name ? option.last_name : ""} ${option.employee_id ? " - " + option.employee_id : ""}`}
                                            </Select.Option> : null
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <p id="common_weekoffTxt" style={{ marginLeft: "0.75vw" }} className="text-left">Assigning Details</p>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Form.Item
                                    style={styles.formItem}
                                    name="from_date"
                                    rules={[{ required: true, message: "This field is required" }]}
                                >
                                    <div className='commonInputTlt'>From Date <span className='requiredTxt'>*</span></div>
                                    <DatePicker
                                        style={styles.textField}
                                        value={formData.from_date || undefined}
                                        onChange={(date) => onChange("from_date", date)}
                                        format={"DD-MM-YYYY"}
                                        allowClear={true}
                                        inputReadOnly={true}
                                        data-testid={'hs_from'}
                                        disabledDate={disabledPastDate}
                                        placeholder=""
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={styles.formItem}
                                    name="to_date"
                                    rules={[{ required: true, message: "This field is required" }]}
                                >
                                    <div className='commonInputTlt'>To Date <span className='requiredTxt'>*</span></div>
                                    <DatePicker
                                        style={styles.textField}
                                        value={formData.to_date || undefined}
                                        onChange={(date) => onChange("to_date", date)}
                                        format={"DD-MM-YYYY"}
                                        allowClear={true}
                                        inputReadOnly={true}
                                        data-testid={'hs_to'}
                                        disabledDate={disabledPastDate}
                                        placeholder=""
                                    />
                                </Form.Item>
                            </div>
                            <div>
                                <div>
                                    <p id="common_weekoffTxt" style={{ marginLeft: "0.75vw", marginTop: "1vw" }} className="pb-3 text-left">
                                        Permissions
                                    </p>
                                    <div style={{ marginLeft: "1.5vw", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <Checkbox checked={checkedList.leave} onChange={handleCheckBox("leave")}>
                                            Leave Approval
                                        </Checkbox>
                                        <Checkbox checked={checkedList.advance_salary} onChange={handleCheckBox("advance_salary")}>
                                            Advance Salary
                                        </Checkbox>
                                        <Checkbox checked={checkedList.reimbursement} onChange={handleCheckBox("reimbursement")}>
                                            Reimbursement
                                        </Checkbox>
                                        <Checkbox checked={checkedList.resignation} onChange={handleCheckBox("resignation")}>
                                            Resignation Approval
                                        </Checkbox>
                                    </div>
                                </div>
                            </div>
                            <p id="common_weekoffTxt" style={{ marginLeft: "0.75vw", marginTop: "1vw" }} className="pb-3 text-left">Comments <span className="requiredTxt">*</span></p>
                            <Form.Item name="comments" style={{ marginLeft: "1vw", width: "98%" }}
                                rules={[{ required: true, message: "This field is required" },
                                { validator: noBlankSpacesValidator }
                                ]}>
                                <Input.TextArea
                                    bordered={true}
                                    className="textarea"
                                    rows={3}
                                    autoSize={{
                                        minRows: 3,
                                        maxRows: 3,
                                    }}
                                    maxLength={250}
                                    style={{ border: "1px solid gray", width: "100%" }}
                                    placeholder="Enter Comments"
                                    name="Comments"
                                    value={formData.comments ? formData.comments : ""}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            comments: e.target.value,
                                        });
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <Form.Item style={styles.emptyFormItem} />
                    </div>
                    <div className='w-full relative inset-y-10'>
                        <Form.Item >
                            <div className='flex justify-end items-end mr-2'>
                                <MyButton htmlType="button" label="Cancel" onClick={(isDataAvail && !fromEdit) ? () => { setIsCancelClk(true) } : handleBackCancel} outlined={true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="hs_cancel" />
                                {fromEdit ? null
                                    : <MyButton htmlType="button" label={"Draft"} onClick={handleSaveAsDraft} disabled={!isDataAvail || formData?.employee == ""} bgColor={isDataAvail && formData?.employee != "" ? "#334B49" : "#cbcbcb"} loading={loadings} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="hs_draft" />}
                                <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loadings} paddingX={"0 1.2vw"} testID="hs_save" />
                            </div>
                        </Form.Item>
                    </div>
                </Form>
            </div>
            {isCancelClk ? <CommonCancelPopup handleCancelBtn={handleCancelBtn} handleDraftBtn={handleSaveAsDraft} testID='hs' /> : null}
        </Card>
    );
};

const styles = {
    textField: {
        width: "23vw"
    },
    formItem: {
        margin: "1vw"
    },
    emptyFormItem: {
        width: "23vw",
        margin: "1vw"
    }
}

export default AddTransferReportingManger;
