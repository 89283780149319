
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Table, Tooltip, Space, Dropdown, } from "antd";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getEmployeeResignation } from "hooks/api/employeeApi/getEmployeeResignation";
import { EMPLOYEE_RESIGNATION_REQUEST } from "constants";
import { EmployeeDetails } from 'components/common/CommonComponent';
import PayrollFilter from 'pages/payroll/PayRollFilters';
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import dayjs from 'dayjs';

const initialData = {
    requested_date: "",
    department_name: "",
    employee: "",
    departmentList:[],
}

function Resignation() {
  let query = "";
  let queryRef = useRef("");
  const pageSize = 10;
  const authtoken = sessionStorage.getItem("token");

  const [editVisible, setEditVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState();
  const [searchText, setSearchText] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sorting, setSorting] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const tableContainerHeight = "72vh";
  const employeeID = sessionStorage.getItem("employee_id")
  const navigate = useNavigate(); 
  let activebtn =false;
  const isSuperAdmin = JSON.parse(sessionStorage.getItem("is_super_admin"));
  const isAdmin = JSON.parse(sessionStorage.getItem("is_admin"));
  const isEditAccess = (isSuperAdmin || isAdmin);

  const [highlightedId, setHighlightedId] = useState(null);
  const location = useLocation();
  
  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  useEffect(() => {
    if (location.state?.type_id) {
      console.log("Setting highlightedId:", location.state.type_id);
      setHighlightedId(location.state.type_id);
      // const timer = setTimeout(() => {
      //   setHighlightedId(null);
      // }, 5000);
      // return () => clearTimeout(timer);
    }
  }, [location]);
  
  
  const queryString =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&requested_date=" + (filterDatas.requested_date ? filterDatas.requested_date : "") +
        "&department=" + (filterDatas.department_name ? filterDatas.department_name : "") +
        "&employee=" + (filterDatas.employee ? filterDatas.employee : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const [data, pageInfo, loading, _message, refetch, response] = useApiData(getEmployeeResignation, {
    pageNo,
    pageSize,
    queryString,
    searchText,
    sorting
  });

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message, _message.errorMsgText]);

  activebtn = response?.data?.extras?.active;
  
  const addClick = () => {
    setEditVisible(false);
    setSelectedDetails({});
    navigate('/employee/offboarding/request')
  };

  const handleEditClick = (details) => {
    if (editVisible && Object.keys(selectedDetails).length > 0) {
      ToastMsg("warning", "Please cancel the existing edit operation!")
    }
    else if (!editVisible) {
      setEditVisible(true);
      navigate('/employee/offboarding/request', { state: { fromEdit: true, editDetails: details } });
      setSelectedDetails(details);
    }
  };

  const handleSearch = () => {
    setEditVisible(false)
    setSelectedDetails({});
    setPageNo(1);
    refetch(1,
      pageSize,
      queryString,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  }
  
  useEffect(() => {
    getDepartmentList();
  }, []);

  /*call departments*/
  const getDepartmentList = async () => {
      try {
          const apiData = await getEmployeeAllDepartment(`drop_down=True`);
          if (apiData && apiData.status === "success" && apiData.data) {
              setFilterDatas((prevFilterData) => ({
                  ...prevFilterData,
                  departmentList: [...apiData.data]
              }))
          } else {
              setFilterDatas((prevFilterData) => ({
                  ...prevFilterData,
                  leaveTypeList: []
              }))
          }
      } catch (error) {
          setFilterDatas((prevFilterData) => ({
                  ...prevFilterData,
                  leaveTypeList: []
              }))
      }
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
    refetch(pagination.current,
      pageSize,
      queryRef.current,
      queryString,
      searchText,
      sortQuery);
    setSorting(sortQuery)
  }

  // filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    refetch(1,
        pageSize,
        `requested_date=${data.requested_date ? data.requested_date : ""}&department=${data.department_name ? data.department_name : ""}&employee=${data.employee ? data.employee : ""}`,
        searchText,
        sorting);
    setFilterVisible(false);
}

  const ClosePopUp = () => {
    setFilterVisible(false);
  }

  const clearFilter = () => {
    setFilterDatas((prevState) => ({
        ...prevState,
        requested_date: "",
        department_name: "",
        employee: "",
      }));
      setPageNo(1);
    refetch(1,
        pageSize,
        queryString,
        searchText,
        sorting);
}
  //filter end

  const columns = [
   
    {
        title: "Employee Details",
        dataIndex: "employee_details",
        key: "employee_details",
        sorter: true,
        fixed: "left",
        width: "15dvw",
        render: (_, record) => {
          let { employee_details } = record;
          let empDetails = {
            ...record,
            title: employee_details.title ? employee_details.title : "",
            first_name: employee_details.employee_name ? employee_details.employee_name : "",
            last_name: "",
            designation_id: {
              id: employee_details?.designation?.id ? employee_details?.designation?.id : "",
              designation_name: employee_details?.designation?.name ? employee_details?.designation?.name : ""
            },
            id: employee_details.employee_id,
            profile: employee_details.profile,
          }
          return (<span onClick={() => handleRowClick(record)} style={{ cursor: "pointer" }}>
            <EmployeeDetails details={empDetails} />
          </span>)
        }
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      sorter: true,
      width: "12dvw",      
      render: (_, record) => {
        return (record.employee_details?.department?.name?.length > 20 ?
          <Tooltip title={record.employee_details?.department?.name}>{<span>{record.employee_details?.department?.name?.slice(0, 20) + "..."}</span>}</Tooltip>
          : <p>{record.employee_details?.department?.name ? record.employee_details?.department?.name : "-"}</p>)
      }
    },
    {
      title: "Request Date",
      dataIndex: "request_date",
      key: "request_date",
      sorter: true,
      width: "10dvw",
      render: (_, record) =>
          <p>
              {record?.request_date ? dayjs(record?.request_date).format('DD/MM/YYYY') : "-"}
          </p>,
      align: "center",      
    },
    {
      title: "Reason For Resignation ",
      dataIndex: "reason",
      key: "reason",
      sorter: true,
      width: "13dvw",      
      render: (_, record) => {
        return (record.reason?.length > 20 ?
          <Tooltip title={record.reason}>{<span>{record.reason?.slice(0, 20) + "..."}</span>}</Tooltip>
          : <p>{record.reason ? record.reason : "-"}</p>)
      }
    },
    {
      title: "Notice Period",
      key: "notice_period",
      dataIndex: "notice_period",
      sorter: true,
      width: "9dvw",
      render: (_, record) =>
      (
        <p>{record?.notice_period !== null && record?.notice_period !== undefined ? record?.notice_period : "-"}</p>
      ),
      align: "center",      
    },
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
      sorter: true,
      width: "10dvw",      
      render: (_, record) =>
      (record?.stage?.length > 28 ?
        <Tooltip title={record?.stage}>{<span>{record?.stage?.slice(0, 25) + "..."}</span>}</Tooltip>
        : <p className="capitalize">{record?.stage ? record?.stage : "-"}</p>),
    },
    {
      title: "Resignation Status",
      dataIndex: "resignation_status",
      key: "resignation_status",
      sorter: true,
      width: "12.28dvw",
      fixed: "right",
      render: (_, record) =>
       (<p className='capitalize'>{record?.resignation_status ? record?.resignation_status : "-"}</p>),
      align: 'center',      
    },
  ];
    columns.push({
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "8dvw",
      align: 'center',
      fixed: "right",
      render: (_, record) => {
        const items = [
          {
            key: "1",
            label: (<div className="empApproveTxt">Assets Handover</div>)
          },
          {
            key: "2",
            label: (<div className="empApproveTxt">Final Step</div>)
          },
        ];
        return (
          <div className="flex justify-center items-center">
            <Space size={"middle"}>
              {employeeID == record.employee_details.id ? <IconButton
                title="Edit" marginTop={true}
                icon={<img src={ImagePaths.edit.default} alt="Edit" />}
                onClick={() => (loading ? "" : handleEditClick(record))}
                testID={'rg_btn_edit'}
                disabled={record?.approval_log[0]?.approval_status === "approved" ? true : false}
              /> : null}
              {record.approval_status === 'approved' && isEditAccess ?
                <Dropdown menu={{
                  items,
                  onClick: ({ key }) => {
                    handleRowClick(record, key)
                  }
                }} trigger={['click']} placement="bottomLeft">
                  <IconButton title="" icon={<img src={ImagePaths.moreIcon.default} alt="more" />} />
                </Dropdown>
                : null}
            </Space>
          </div>
        )
      },
    })
  
  const handleFilter = () => {
    setFilterVisible(true)
  }

  const handleBackToHome = () => {
    setSorting("");
    setPageNo(1);
    queryRef.current = "";
    refetch(1,
      pageSize,
      queryRef.current,
      queryString,
      searchText,
      "");
  }

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search)
    setReorderedColumns(reorderedList)
  }

  const printData =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&requested_date=" + (filterDatas.requested_date ? filterDatas.requested_date : "") +
        "&department=" + (filterDatas.department_name ? filterDatas.department_name : "") +
        "&employee=" + (filterDatas.employee ? filterDatas.employee : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

  const handleRowClick = (record, key) => {
    if (record.approval_status === 'approved' && isEditAccess) {
      navigate('/employee/offboarding/Submission', { state: { editDetails: record, stepkey: key ? parseInt(key) : 0  } });
    }
  };

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"} >
        <CommonTblHeader pageName={"Resignation"}
          pageInfo={pageInfo}
          handleSearch={handleSearch} selectedDetails={selectedDetails} addClick={addClick}
          onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
          isDraftRequired={false} isDraft={false} handleBackToHome={handleBackToHome}
          url={EMPLOYEE_RESIGNATION_REQUEST} query={printData}
          isAddRequired = {!activebtn}
          addBtnLabel = {"Resignation Request"}
          testID = {'rg_add'}
        />
      </div>
      <div className="h-5/6 flex justify-center items-center  mt-1">
        {filterVisible ?
          <div className="w-1/4 mt-5" style={styles.fltContainer}>
            <PayrollFilter
              pageName={"ResignationApproval"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter} />
          </div>
          : null}
        <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
        <Table
          columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
          dataSource={data?.length > 0 ? data : null}
          loading={loading}
          style = {{"--table-container-height": tableContainerHeight}}
          scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
          // onRow={(record) => ({
          //     onClick: () => handleRowClick(record)
          // })}
          onChange={handleTableChange}
          pagination={{
            style: { marginRight: 20, padding: 0 },
            defaultPageSize: pageSize,
            showSizeChanger: false,
            showQuickJumper: true,
            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
            pageSizeOptions: ["10", "20", "30"],
            pageSize,
            defaultCurrent: pageNo,
          }}
          rowClassName={(record) => {
            console.log("Record:", record, "Row ID:", record.id, "Highlighted ID:", highlightedId);
            return record.id === highlightedId ? "highlighted-row" : "";
          }}          
          onRow={(record) => ({
            onDoubleClick: () => { handleRowClick(record) },
            className: 'hover-row',
          })}
        />
        </div>
      </div>
    </Card>
  );
}

export default Resignation;
