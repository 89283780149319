import React, { createContext, useState } from "react";
import {transformUserPermissionArr} from "components/common/CommonFuntion";
export const AuthContext = createContext("");

export const SessionProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const loginauth = async(loginData, keepSignedIn) => {
    let dataList = (loginData?.permissions && loginData?.permissions?.permissions?.length) ? transformUserPermissionArr([...loginData?.permissions?.permissions]) : [];
    let moduleList = (loginData?.modules ) ? ([...loginData?.modules]) : [];
    let subModuleList = (loginData?.sub_modules ) ? ([...loginData?.sub_modules]) : [];   
    try{
    sessionStorage.setItem("token", loginData.token);
    sessionStorage.setItem("is_super_admin", loginData.is_super_admin);
    sessionStorage.setItem("is_admin", loginData.is_admin);
    sessionStorage.setItem("userId", loginData.user_id);
    sessionStorage.setItem("user_group_id", loginData.user_group_id);
    sessionStorage.setItem("address", loginData.address);
    sessionStorage.setItem("companyName", loginData.company_name);
    sessionStorage.setItem("profile", loginData.profile);
    sessionStorage.setItem("employee_id", loginData.employee_id);
    sessionStorage.setItem("employee_name", loginData.employee_name);
    sessionStorage.setItem("designation", loginData.designation);
    sessionStorage.setItem("department", loginData.department);
    sessionStorage.setItem("loginUserPermission", JSON.stringify(dataList));
    sessionStorage.setItem("modules", JSON.stringify(moduleList));
    sessionStorage.setItem("subModule", JSON.stringify(subModuleList));
    sessionStorage.setItem("planName", loginData.plan_name);
    sessionStorage.setItem("is_password_updated", loginData.is_password_updated);
    sessionStorage.setItem("reportingManager", loginData.is_reproting_manager);

    if(keepSignedIn){
      const now = new Date();
      localStorage.setItem("token", loginData.token);
      localStorage.setItem("is_super_admin", loginData.is_super_admin);
      localStorage.setItem("is_admin", loginData.is_admin);
      localStorage.setItem("userId", loginData.user_id);
      localStorage.setItem("user_group_id", loginData.user_group_id);
      localStorage.setItem("address", loginData.address);
      localStorage.setItem("companyName", loginData.company_name);
      localStorage.setItem("profile", loginData.profile);
      localStorage.setItem("employee_id", loginData.employee_id);
      localStorage.setItem("employee_name", loginData.employee_name);
      localStorage.setItem("designation", loginData.designation);
      localStorage.setItem("department", loginData.department);
      localStorage.setItem("loginUserPermission", JSON.stringify(dataList));
      localStorage.setItem("keepSignedIn", true);
      localStorage.setItem("keepSignedInExpiry", now.getTime() + 720 * 60 * 1000);
      localStorage.setItem("modules", JSON.stringify(moduleList));
      localStorage.setItem("subModule", JSON.stringify(subModuleList));
      localStorage.setItem("planName", loginData.plan_name);
      localStorage.setItem("reportingManager", loginData.is_reproting_manager);
      }      
    setIsAuthenticated(true);
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const logoutauth = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("is_super_admin");
    sessionStorage.removeItem("is_admin");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("user_group_id");
    sessionStorage.removeItem("address");
    sessionStorage.removeItem("companyName");
    sessionStorage.removeItem("profile");
    sessionStorage.removeItem("employee_id");
    sessionStorage.removeItem("employee_name");
    sessionStorage.removeItem("designation");
    sessionStorage.removeItem("department");
    sessionStorage.removeItem("loginUserPermission");
    sessionStorage.removeItem("passwordPolicy");
    sessionStorage.removeItem("shiftDetails");
    sessionStorage.removeItem("customConfig");
    sessionStorage.removeItem("salary_calculation");
    sessionStorage.removeItem("is_password_updated");
    sessionStorage.removeItem("modules");
    sessionStorage.removeItem("planName");
    sessionStorage.removeItem("subModule")
    localStorage.removeItem("token");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("userId");
    localStorage.removeItem("user_group_id");
    localStorage.removeItem("address");
    localStorage.removeItem("companyName");
    localStorage.removeItem("profile");
    localStorage.removeItem("employee_id");
    localStorage.removeItem("employee_name");
    localStorage.removeItem("designation");
    localStorage.removeItem("department");
    localStorage.removeItem("loginUserPermission");
    localStorage.removeItem("passwordPolicy");
    localStorage.removeItem("keepSignedIn");
    localStorage.removeItem("keepSignedInExpiry");
    localStorage.removeItem("shiftDetails");
    localStorage.removeItem("customConfig");
    localStorage.removeItem("salary_calculation");
    localStorage.removeItem("modules");
    localStorage.removeItem("planName");
    localStorage.removeItem("subModule")
    localStorage.removeItem("reportingManager")
    setIsAuthenticated(false);
  };

  const setSessionStorage = () => {
    sessionStorage.setItem("token", localStorage.getItem("token"));
    sessionStorage.setItem("is_super_admin", localStorage.getItem("is_admin"));
    sessionStorage.setItem("is_admin", localStorage.getItem("is_admin"));
    sessionStorage.setItem("userId", localStorage.getItem("userId"));
    sessionStorage.setItem("user_group_id", localStorage.getItem("user_group_id"));
    sessionStorage.setItem("address", localStorage.getItem("address"));
    sessionStorage.setItem("companyName", localStorage.getItem("companyName"));
    sessionStorage.setItem("profile", localStorage.getItem("profile"));
    sessionStorage.setItem("employee_id", localStorage.getItem("employee_id"));
    sessionStorage.setItem("employee_name", localStorage.getItem("employee_name"));
    sessionStorage.setItem("designation", localStorage.getItem("designation"));
    sessionStorage.setItem("department", localStorage.getItem("department"));
    sessionStorage.setItem("loginUserPermission", localStorage.getItem("loginUserPermission"));
    sessionStorage.setItem("passwordPolicy", localStorage.getItem("passwordPolicy"));
    sessionStorage.setItem("modules", localStorage.getItem("modules"));
    sessionStorage.setItem("planName", localStorage.getItem("planName"));
    sessionStorage.setItem("subModule", localStorage.getItem("subModule"));
    sessionStorage.setItem("reportingManager", localStorage.getItem("reportingManager"));
  }

  function getWithExpiry() {
    const itemExpiry = localStorage.getItem("keepSignedInExpiry");
    if (!itemExpiry) {
      return false; // Item doesn't exist in local storage
    }
    
    const now = new Date();
    if (now.getTime() > itemExpiry) {
      return false;
    }
    return true;
  }

  const checkAuth = () => {
    const sessionExpiry = getWithExpiry()
    if(sessionExpiry){
      if(localStorage.getItem("keepSignedIn")){
        setSessionStorage()
        const token = sessionStorage.getItem("token");
        if (token) {
          setIsAuthenticated(true);
        }
      }
    }
    else if (!sessionExpiry && localStorage.getItem("keepSignedIn"))
    {
      logoutauth()
    }
    else{
      const token = sessionStorage.getItem("token");
      if (token) {
        setIsAuthenticated(true);
      }
    }
  };

  const loginServiceType = () => {
    return sessionStorage.getItem("is_admin");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        loginauth,
        logoutauth,
        checkAuth,
        loginServiceType,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
