import React, { useEffect, useState } from "react";
import { Card, Table, Tooltip } from "antd";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import AssetManagementFilter from "pages/assetManagement/AssetManagementFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getAssetEmpAuditPagination } from "hooks/api/assetManagementApi/assetEmployeeAuditApi";
import { ASSET_EMPLOYEE_AUDIT } from "constants";
import { useNavigate } from 'react-router-dom';
import { getEmployee } from 'hooks/api/employeeApi/employeeInformationApi';
import dayjs from 'dayjs';
import { getAssetRegistry } from 'hooks/api/assetManagementApi/assetRegistryApi';
import { EmployeeDetails } from "components/common/CommonComponent";
import { checkUserPermissionFunc } from "components/common/CommonFuntion";

const initialData = {
    issued_date: "",
    return_date: "",
    hand_over_to: "",
    approved_by: "",
    assetRegistryList: [],
    employeeList: [],
}

function AssetEmployeeAudit() {
    const authtoken = sessionStorage.getItem("token");
    const query = "";
    const pageSize = 10;
    const [addVisible, setAddVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState({});
    const [searchText, setSearchText] = useState("");
    const [dataUpdated, setDataUpdated] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [sorting, setSorting] = useState("");
    const [filterVisible, setFilterVisible] = useState(false);
    const [filterDatas, setFilterDatas] = useState({ initialData });
    const [reorderedColumns, setReorderedColumns] = useState([]);
    const navigate = useNavigate();
    const tableContainerHeight = "72vh";

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    const userPermission = checkUserPermissionFunc("Asset Management", "Asset Employee Audit", "SubMenu");

    const [data, pageInfo, loading, _message, refetch] = useApiData(getAssetEmpAuditPagination, {
        pageNo,
        pageSize,
        query,
        searchText,
        sorting,
    });

    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
    }, [_message.errorMsgText]);



    const getAssetRegistryList = async () => {
        try {
            const apiData = await getAssetRegistry(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    assetRegistryList: [...apiData.data]
                }))
            } else {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    assetRegistryList: []
                }))
            }
        } catch (error) {
            setFilterDatas((prevFilterData) => ({
                ...prevFilterData,
                assetRegistryList: []
            }))
        }
    }

    const getAssetEmployeeList = async () => {
        try {
            const apiData = await getEmployee(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    employeeList: [...apiData.data]
                }))
            } else {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    employeeList: []
                }))
            }
        } catch (error) {
            setFilterDatas((prevFilterData) => ({
                ...prevFilterData,
                employeeList: []
            }))
        }
    }

    useEffect(() => {
        getAssetRegistryList();
        getAssetEmployeeList();
    }, []);

    const addClick = () => {
        setEditVisible(false);
        setSelectedDetails({});
        navigate('/assetmanagement/assetemployeeaudit/add', { state: { fromEdit: false, editDetails: {}, filterDatas, isActive: userPermission?.active !== undefined ? userPermission?.active : true } });
    };

    const handleEditClick = (details) => {
        if (editVisible && Object.keys(selectedDetails).length > 0) {
            ToastMsg("warning", "Please cancel the existing edit operation!")
        }
        else if (!editVisible) {
            setEditVisible(true);
            navigate('/assetmanagement/assetemployeeaudit/add', { state: { fromEdit: true, editDetails: details, filterDatas, isActive: userPermission?.active !== undefined ? userPermission?.active : true } });
            setSelectedDetails(details);
        }
    };

    // filter start
    const renderFilter = (data) => {
        setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
        setPageNo(1);
        refetch(1,
            pageSize,
            `issued_date=${data.issued_date ? data.issued_date : ""}&return_date=${data.return_date ? data.return_date : ""}&active_status=${data.is_active !== undefined ? data.is_active : ""}&handing_over_to=${data.hand_over_to ? data.hand_over_to : ""}&approved_by=${data.approved_by ? data.approved_by : ""}`,
            searchText,
            sorting);
        setFilterVisible(false);
    }

    const ClosePopUp = () => {
        setFilterVisible(false);
    }

    const clearFilter = () => {
        setFilterDatas((prevState) => ({
            ...prevState,
            issued_date: "",
            return_date: "",
            hand_over_to: "",
            approved_by: "",
        }));
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
    }
    //filter end

    const handleSearch = () => {
        setEditVisible(false)
        setAddVisible(false)
        setSelectedDetails({});
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
        setDataUpdated(!dataUpdated);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
        const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
        refetch(pagination.current,
            pageSize,
            query,
            searchText,
            sortQuery);
        setSorting(sortQuery)
    }

    const columns = [
        {
            title: "Employee Details",
            dataIndex: "employee_code",
            key: "employee__employee_details__employee_name",
            width: '18%',
            sorter: true,
            render: (_, record) => {
                let { employee_details } = record.employee;
                let empDetails = {
                    ...record,
                    title: employee_details.title ? employee_details.title : "",
                    first_name: employee_details.employee_name ? employee_details.employee_name : "",
                    last_name: "",
                    designation_id: {
                        id: employee_details?.designation?.id ? employee_details?.designation?.id : "",
                        designation_name: employee_details?.designation?.name ? employee_details?.designation?.name : ""
                    },
                    id: employee_details.employee_id,
                    profile: employee_details.profile,
                }
                return (<EmployeeDetails details={empDetails} />)
            }
        },
        {
            title: "Asset Details",
            dataIndex: "asset_code",
            key: "asset_registry_id__asset_code",
            width: "12%",
            sorter: true,
            render: (_, record) =>
            (<div>
                {record?.asset_registry_id?.asset_code?.length > 20 ?
                    <Tooltip title={record?.asset_registry_id?.asset_code}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.asset_registry_id?.asset_code?.slice(0, 20) + "..."}</span>}</Tooltip>
                    : <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.asset_registry_id?.asset_code ? record?.asset_registry_id?.asset_code : "-"}</p>}
                {record?.asset_registry_id?.asset_name?.length > 20 ?
                    <Tooltip title={record?.asset_registry_id?.asset_name}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.asset_registry_id?.asset_name?.slice(0, 20) + "..."}</span>}</Tooltip>
                    : <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.asset_registry_id?.asset_name ? record?.asset_registry_id?.asset_name : "-"}</p>}
            </div>
            )
        },
        {
            title: "Issued Date",
            dataIndex: "issued_date",
            key: "issued_date",
            sorter: true,
            render: (_, record) =>
                <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>
                    {record?.issued_date ? dayjs(record?.issued_date).format('DD/MM/YYYY') : "-"}
                </p>,
            fixed: "left",
        },
        {
            title: "Handover By",
            dataIndex: "id",
            key: "hand_over_by__first_name",
            width:"18%",
            sorter: true,
            render: (_, record) => {
                let empDetails = {
                    ...record.hand_over_by,
                    id: record.hand_over_by.employee_code ? record.hand_over_by.employee_code : "",
                    designation_id: record.hand_over_by
                }
                return (
                    <span style={{ color: record.is_active ? "black" : "#CACACA", cursor: "pointer" }}>
                        {(record.hand_over_by.first_name && record.hand_over_by.employee_id) ?
                            <EmployeeDetails details={empDetails} /> : "-"}
                    </span>
                )
            }
        },
        {
            title: "Return Date",
            dataIndex: "return_date",
            key: "return_date",
            sorter: true,
            render: (_, record) =>
                <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>
                    {record?.return_date ? dayjs(record?.return_date).format('DD/MM/YYYY') : "-"}
                </p>,
        },
        {
            title: "Handover To",
            dataIndex: "id",
            key: "handing_over_to_id__first_name",
            width: "18%",
            sorter: true,
            render: (_, record) => {
                let empDetails = {
                    ...record.handing_over_to_id,
                    id: record.handing_over_to_id.employee_code ? record.handing_over_to_id.employee_code : "",
                    designation_id: record.handing_over_to_id
                }
                return (
                    <span style={{ color: record.is_active ? "black" : "#CACACA", cursor: "pointer" }}>
                        {(record.handing_over_to_id.first_name && record.handing_over_to_id.employee_id) ?
                            <EmployeeDetails details={empDetails} /> : "-"}
                    </span>
                )
            }
        },
    ];

    if (userPermission?.edit) {
        columns.push({
            title: "Action",
            key: "action",
            width: "10%",
            render: (_, record) => (
                <IconButton
                    title="Edit"
                    icon={<img src={ImagePaths.edit.default} alt="Edit" />}
                    onClick={() => (loading ? "" : handleEditClick(record))}
                    testID="astadt_btn_edit"
                />
            ),
        })
    }
    const handleFilter = () => {
        setFilterVisible(true)
    }

    const handleBackToHome = () => {
        refetch(pageNo,
            pageSize,
            query,
            searchText,
            sorting);
    }

    const handleStateChanges = (search = "", reorderedList = []) => {
        setSearchText(search)
        setReorderedColumns(reorderedList)
    }

    const queryString =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&issued_date=" + (filterDatas.issused_date ? filterDatas.issused_date : "") +
        "&return_date=" + (filterDatas.return_date ? filterDatas.return_date : "") +
        "&handing_over_to=" + (filterDatas.hand_over_to ? filterDatas.hand_over_to : "") +
        "&approved_by=" + (filterDatas.approved_by ? filterDatas.approved_by : "") +
        "&active_status=" + (filterDatas.is_active !== undefined ? filterDatas.is_active : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");
    
    const styles = {
        fltContainer: {
            border: "1px solid #cbcbcb",
            height: tableContainerHeight,
        },
    }

    return (
        <Card className="h-full overflow-hidden">
            <div className={"h-1/6"} >
                <CommonTblHeader pageName={"Asset-Employee Audit"}
                    addVisible={addVisible} editVisible={editVisible} addComponent={addClick}
                    pageInfo={pageInfo} handleSearch={handleSearch} selectedDetails={selectedDetails}
                    addClick={addClick} onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
                    handleBackToHome={handleBackToHome} url={ASSET_EMPLOYEE_AUDIT} query={queryString} 
                    isDraftRequired={false} testID={'astadt_btn_add'} isAddRequired={userPermission?.add ? true : false}
                />
            </div>
            <div className="h-5/6 flex justify-center items-center mt-1">
                {filterVisible ?
                    <div className="w-1/4 mt-5" style={styles.fltContainer}>
                        <AssetManagementFilter
                            pageName={"AssetEmployeeAudit"}
                            filterDatas={filterDatas}
                            closeFilter={ClosePopUp}
                            filteredData={renderFilter}
                            clearData={clearFilter} />
                    </div>
                    : null}
                <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
                <Table 
                    columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
                    dataSource={data?.length > 0 ? data : null}
                    loading={loading}
                    style = {{"--table-container-height": tableContainerHeight}}
                    scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
                    onChange={handleTableChange}
                    pagination={{
                        style: { marginRight: 20, padding: 0 },
                        defaultPageSize: pageSize,
                        showSizeChanger: false,
                        showQuickJumper: true,
                        total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                        pageSizeOptions: ["10", "20", "30"],
                        pageSize,
                        defaultCurrent: pageNo,
                    }}
                    key={dataUpdated ? "updated" : "not-updated"}
                />
                </div>
            </div>
        </Card>
    );
}

export default AssetEmployeeAudit;