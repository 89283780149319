import { useEffect, useState } from "react";
import { Card, Dropdown, Space, Table, Tooltip } from "antd";
import { useNavigate } from 'react-router-dom';
import EmployeeFilter from "../EmployeeFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getEmployeePagination, getEmployee } from "hooks/api/employeeApi/employeeInformationApi";
import { EMPLOYEE_PERSONAL_INFO } from "constants";
import { getEmployeeDesignation } from "hooks/api/masterApi/employeeDesignationApi";
import dayjs from "dayjs";
import { EmployeeDetails } from "components/common/CommonComponent";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import { getEmployeeGroup } from "hooks/api/masterApi/employeeGroupApi";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import moment from "moment";
import { getTransferInboxPagination } from "./TransReportingManagerApi";
import { TRANSFER_INBOX } from "constants";

const initialData = {
    designation_name: "",
    department_name: "",
    reporting_manager: "",
    emplyee_group: "",
    is_active: "",
    designationList: [],
    departmentlist: [],
    reportingManagerList: [],
    employeeGroupList: []
}

function TransferInbox() {
    const query = "";
    const pageSize = 10;
    const authtoken = sessionStorage.getItem("token");
    const is_super_admin = JSON.parse(sessionStorage.getItem("is_super_admin"));
    const is_admin = JSON.parse(sessionStorage.getItem("is_admin"));
    const isEditAccess = (is_super_admin || is_admin);

    const [pageNo, setPageNo] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [sorting, setSorting] = useState("");
    const [dataUpdated, setDataUpdated] = useState(false);
    const [filterVisible, setFilterVisible] = useState(false);
    const [filterDatas, setFilterDatas] = useState({ initialData });
    const [reorderedColumns, setReorderedColumns] = useState([]);
    const [loader, setLoader] = useState(false);
    const [isCancelClk, setIsCancelClk] = useState(false);
    const tableContainerHeight = "72vh";
    const dateFormat = "YYYY-MM-DD";

    const navigate = useNavigate();

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    const queryString =
        "from_date=" + (filterDatas.designation_name ? filterDatas.designation_name : "") +
        "&to_date=" + (filterDatas.department_name ? filterDatas.department_name : "") +
        "&reporting_manager=" + (filterDatas.reporting_manager ? filterDatas.reporting_manager : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "") 

    const [data, pageInfo, loading, _message, refetch] = useApiData(getTransferInboxPagination, {
        pageNo,
        pageSize,
        queryString,
        searchText,
        sorting
    });

    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
    }, [_message.errorMsgText]);

    const handleSearch = () => {
        setPageNo(1);
        refetch(1,
            pageSize,
            queryString,
            searchText,
            sorting);
        setDataUpdated(!dataUpdated);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
        const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
        refetch(pagination.current,
            pageSize,
            queryString,
            searchText,
            sortQuery);
        setSorting(sortQuery)
    }

    // filter start
    const renderFilter = (data) => {
        setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
        setPageNo(1);
        refetch(1,
            pageSize,
            `designation=${data.designation_name !== undefined ? data.designation_name : ""}&status=${data.is_active ? data.is_active : ""}&department=${data.department_name !== undefined ? data.department_name : ""}&reporting_manager=${data.reporting_manager !== undefined ? data.reporting_manager : ""}&employee_group=${data.emplyee_group !== undefined ? data.emplyee_group : ""}`,
            searchText,
            sorting);
        setFilterVisible(false);
    }

    const ClosePopUp = () => {
        setFilterVisible(false);
    }

    const clearFilter = (data) => {
        setFilterDatas((prevState) => ({
            ...prevState,
            ...initialData,
            designationList: data?.designationList?.length > 0 ? data?.designationList : [],
            departmentlist: data?.departmentlist?.length > 0 ? data?.departmentlist : [],
            reportingManagerList: data?.reportingManagerList?.length > 0 ? data?.reportingManagerList : [],
            employeeGroupList: data?.employeeGroupList?.length > 0 ? data?.employeeGroupList : []
        }));
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
    }
    //filter end

    const handleAction = (status, id) => {
        const authtoken = sessionStorage.getItem("token")
        try {
            setLoader(true)
            let updatedFormData = {
                "status": status,
                "transfer_id": id
            };
            fetch(TRANSFER_INBOX, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${authtoken}`
                },
                body: JSON.stringify(updatedFormData)
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        setLoader(false);
                        refetch(1,
                            pageSize,
                            queryString,
                            searchText,
                            sorting);
                    }
                    else if (data.status === "fail") {
                        setLoader(false);
                        ToastMsg("error", data.message);
                    }
                })
                .catch(error => {
                    setLoader(false);
                    ToastMsg("error", error.message);
                });
        } catch (error) {
            ToastMsg("error", error.message);
        } finally {
            setIsCancelClk(false);
        }
    }

    const columns = [
        {
            title: "From Date",
            dataIndex: "from_date",
            key: "from_date",
            sorter: true,
            width: "12%",
            render: (_, record) => (
                <span style={{ color: "black" }}>
                    {record.from_date ? moment(record.from_date, dateFormat).format('DD-MM-YYYY') : "-"}
                </span>
            ),
            align: 'center',
            fixed: "left",
        },
        {
            title: "To Date",
            dataIndex: "to_date",
            key: "to_date",
            sorter: true,
            width: "12%",
            render: (_, record) => (
                <span style={{ color: "black" }}>
                    {record.to_date ? moment(record.to_date, dateFormat).format('DD-MM-YYYY') : "-"}
                </span>
            ),
            align: 'center',
            fixed: "left",
        },
        {
            title: "Sent From",
            dataIndex: "first_name",
            key: "first_name",
            width: "20%",
            sorter: true,
            fixed: "left",
            render: (_, record) => {
                let empDetails = {
                    ...record.sent_from_employee,
                    "designation_id": record?.sent_from_employee.designation ? { id: record?.sent_from_employee.designation.id, designation_name: record?.sent_from_employee.designation.name } : { id: 0, designation_name: "" },
                    "first_name": record?.sent_from_employee.employee_name ? record?.sent_from_employee.employee_name : "",
                    "id": record?.sent_from_employee.employee_id
                }
                return (<EmployeeDetails details={empDetails} />)
            }
        },
        {
            title: "Comments",
            dataIndex: "employee_group_id",
            key: "employee_group_id__employee_group_name",
            width: "36%",
            sorter: true,
            fixed: "left",
            render: (_, record) => {
                let colors = record?.is_active ? "black" : "#CACACA";
                return (record?.comments?.length > 50 ?
                    <Tooltip title={record?.comments}>{<span style={{ color: colors }}>{record?.comments?.slice(0, 50) + "..."}</span>}</Tooltip>
                    : <p style={{ color: colors }}>{record?.comments ? record?.comments : "-"}</p>)
            }
        },
    ];

    columns.push({
        title: "Action",
        key: "action",
        align: 'center',
        width: "10%",
        fixed: "left",
        render: (_, record) => {
            return (
                <div className="flex justify-center items-center">
                      <Space size={"middle"} style={{ display: "flex", alignItems: "center" }}>
                        <IconButton title="Approved"
                            icon={<img style={{ backgroundColor: "black", borderRadius: "0.2vw", padding: "0.25vw" }} src={ImagePaths.check.default} alt="Edit" />}
                            onClick={() => loading ? "" : handleAction("approved", record?.id)} />
                        <IconButton title="Cancel"
                            icon={<img style={{ backgroundColor: "red", borderRadius: "0.2vw", padding: "0.25vw" }} src={ImagePaths.close.default} alt="Edit" />}
                            onClick={() => loading ? "" : handleAction("rejected", record?.id)} />
                    </Space>
                </div>
            )
        }
    })

    const handleFilter = () => {
        setFilterVisible(true)
    }

    const handleStateChanges = (search = "", reorderedList = []) => {
        setSearchText(search)
        setReorderedColumns(reorderedList)
    }


    const printdata =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&designation=" + (filterDatas.designation_name ? filterDatas.designation_name : "") +
        "&department=" + (filterDatas.department_name ? filterDatas.department_name : "") +
        "&reporting_manager=" + (filterDatas.reporting_manager ? filterDatas.reporting_manager : "") +
        "&employee_group=" + (filterDatas.emplyee_group ? filterDatas.emplyee_group : "") +
        "&status=" + (filterDatas.is_active !== undefined ? filterDatas.is_active : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");

    const styles = {
        fltContainer: {
            border: "1px solid #cbcbcb",
            height: tableContainerHeight,
        },
    }

    return (
        <Card className="h-full overflow-hidden">
            <div className={"h-1/6"} >
                <CommonTblHeader pageName={"Transfer Inbox"}
                    pageInfo={pageInfo} onStateChange={handleStateChanges}
                    handleSearch={handleSearch} handleFilter={handleFilter} columns={columns}
                    url={EMPLOYEE_PERSONAL_INFO} query={printdata}
                    isAddRequired={false}
                    isDraftRequired={false}
                />
            </div>
            <div className="h-5/6 flex justify-center items-center  mt-1">
                {filterVisible ?
                    <div className="w-1/4 mt-5" style={styles.fltContainer}>
                        <EmployeeFilter
                            pageName={"Employees"}
                            filterDatas={filterDatas}
                            closeFilter={ClosePopUp}
                            filteredData={renderFilter}
                            clearData={clearFilter} />
                    </div>
                    : null}
                <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
                    <Table
                        columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
                        dataSource={data?.length > 0 ? data : null}
                        loading={loading}
                        style={{ "--table-container-height": tableContainerHeight }}
                        scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
                        onChange={handleTableChange}
                        pagination={{
                            style: { marginRight: 20, padding: 0 },
                            defaultPageSize: pageSize,
                            showSizeChanger: false,
                            showQuickJumper: true,
                            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                            pageSizeOptions: ["10", "20", "30"],
                            pageSize,
                            defaultCurrent: pageNo,
                        }}
                        key={dataUpdated ? "updated" : "not-updated"}
                    />
                </div>
            </div>
        </Card>
    );
}
export default TransferInbox;