import React, { useState, useEffect, useContext } from 'react';
import { Card, Progress, Button, Spin, Tooltip, notification } from 'antd';
import ellipse from "assets/Ellipse.png";
import { monthEnum } from "components/common/enum";
import { EmployeeDetails } from "components/common/CommonComponent";
import { ImagePaths } from 'utils/ImagePath';
import click from "assets/FInger.png";
import useGetData from "components/common/useGetData";
import { getHomeDetails, getHappyMeter, getClockify } from './apihook';
import { formatTime12Hour, dateConvertor, extractFileExtension, formatTimeToAMPM } from 'components/common/validation';
import ToastMsg from 'components/common/ToastMsg'
import happyEmoji from "assets/happyEmoji.png";
import averageEmoji from "assets/averageEmoji.png";
import sadEmoji from "assets/sadEmoji.png";
import { useNavigate, useOutletContext } from 'react-router-dom'
import dayjs from 'dayjs';
import { EMPLOYEE_TRACKER } from 'constants';
import { AuthContext } from "context/auth/authentication";

const Home = () => {
    const navigate = useNavigate();
    const employeeName = sessionStorage.getItem("employee_name")
    const authtoken = sessionStorage.getItem("token");
    const { logoutauth, loginauth, checkAuth, isAuthenticated } = useContext(AuthContext);
    // Current month and year:
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const currentMonthName = monthEnum[currentMonthIndex + 1];
    const currentYear = currentDate.getFullYear();

    // States of clock in , clock out and play paused:
    const [seconds, setSeconds] = useState(null);
    const [isClockIn, setIsClockIn] = useState(false);
    const [isPlayPause, setIsPlayPause] = useState(false);
    const [isClockOut, setIsClockOut] = useState(false);
    const [progressPercent, setProgressPercent] = useState(0);
    const { handleChildData } = useOutletContext();
    const is_password_updated = JSON.parse(sessionStorage.getItem("is_password_updated"));

    const [location, setLocation] = useState({
        latitude: null,
        longitude: null,
        city: null,
        street: null,
        address: null,
    });

    const [data, _message, refetch, loading] = useGetData(getHomeDetails);
    let { notice_board, upcoming_events, my_leaves, password_policy, payroll_custom_configuration, salary_calculation} = data || {};
    let dataList = (password_policy && password_policy?.length > 0) ? { ...password_policy[0] } : {};
    let customData = (payroll_custom_configuration && payroll_custom_configuration?.length > 0) ? { ...payroll_custom_configuration[0] } : {};
    sessionStorage.setItem("passwordPolicy", JSON.stringify(dataList));
    sessionStorage.setItem("customConfig", JSON.stringify(customData));
    sessionStorage.setItem("salary_calculation", salary_calculation);

    useEffect(() => {
        getLocation();
    }, []);
    
    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    useEffect(() => {
        if(!is_password_updated && dataList?.id){
          navigate("/employeeprofile");
        }
    },[dataList?.id, is_password_updated])
    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
          ToastMsg('error', _message.errorMsgText);
        }
      }, [_message.errorMsgText]);

    const [shiftTimeRemaining, setShiftTimeRemaining] = useState(null);

    useEffect(() => {
        const currentDate = dayjs();
        let intervalId = null;

        if (data) {
            const givenDate = dayjs(data.date);

            if (givenDate.isSame(currentDate, 'day') && !data.check_in && !data.check_out) {
                setIsClockOut(false);
            } else if (data.check_out) {
                setIsClockOut(true);
                setIsClockIn(false);
                setIsPlayPause(false);
                setShiftTimeRemaining(null);
                setSeconds(null);
            } else if (data.working_hrs && data.clock_status === "pause") {
                setSeconds(parseFloat(data.working_hrs))
                setIsPlayPause(true);
                setIsClockIn(true);
            } else if (data.clock_status === "resume" && data.resume) {
                setIsClockIn(true);
                const calculateResumetime = () => {
                    const resumeInTime = new Date(data.resume).getTime();
                    const currentTime = Date.now();
                    const timeDifferenceMs = currentTime - resumeInTime;
                    // Convert milliseconds to seconds
                    const resumTimeInSeconds = Math.floor(timeDifferenceMs / 1000);
                    return parseFloat(data.working_hrs) + resumTimeInSeconds;
                };
                setSeconds(calculateResumetime());
                intervalId = setInterval(() => {
                    const calcSeconds = calculateResumetime();
                    setSeconds(calcSeconds);
                }, 1000);
            } else if (data.check_in) {
                setIsClockIn(true);
                const calculateCheckInTime = () => {
                    const checkInDate = new Date(data.check_in).getTime();
                    const currentTime = Date.now();
                    const timeDifferenceMs = currentTime - checkInDate;
                    // Convert milliseconds to seconds
                    const workingHoursInSeconds = Math.floor(timeDifferenceMs / 1000);
                    return Math.max(workingHoursInSeconds, 0);
                };
                setSeconds(calculateCheckInTime());
                intervalId = setInterval(() => {
                    const calcSeconds = calculateCheckInTime();
                    setSeconds(calcSeconds);
                }, 1000);
            }
            handleChildData({...data});
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [data])

    const getLocation = () => {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const { latitude, longitude } = position.coords;
                const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

                try {
                    const response = await fetch(url);
                    const data = await response.json();

                    const { address } = data;

                    setLocation(() => ({
                        latitude: latitude,
                        longitude: longitude,
                        city: address?.city || address?.town || address?.village,
                        street: address?.suburb,
                        address: address,
                    }))
                } catch (error) {
                    console.error("Error fetching location details:", error);
                }
            },
            (error) => {
                console.error(`Error: ${error.message}`);
            },
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            }
        );
    };

    // CountDown timer 
    // useEffect(() => {
    //     let interval;
    //     if (isClockIn && !isPlayPause && data?.check_in) {
    //         interval = setInterval(() => {
    //             setSeconds(prevState => prevState + 1);
    //         }, 1000);
    //     } else {
    //         clearInterval(interval);
    //         if (!data?.check_in) {
    //             setSeconds(null);
    //         }
    //     }

    //     return () => clearInterval(interval);
    // }, [isClockIn, isPlayPause, data?.check_in]);

    useEffect(() => {
        if (Notification.permission === "default" || Notification.permission === "denied") {
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                alert("Notification permission granted!");
              } else {
                alert("Notification permission denied.");
              }
            });
        } 
    }, [isClockIn])

   
    useEffect(() => {
        if (!data?.shift_start_time || !data?.shift_end_time) {
            console.error('Shift start or end time is missing.');
            return;
        }

        const startTime = new Date(data.shift_start_time);
        const endTime = new Date(data.shift_end_time);

        if (endTime < startTime) {
            endTime.setDate(endTime.getDate() + 1);
        }

        const shiftDuration = endTime.getTime() - startTime.getTime();

        const calculateTimeLeft = () => {
            const now = new Date();
            const timeDiff = endTime.getTime() - now.getTime();
            return Math.max(timeDiff, 0);
        };

        const timer = setInterval(() => {
            const remainingTime = calculateTimeLeft();
            setShiftTimeRemaining(remainingTime);

            const elapsedDuration = shiftDuration - remainingTime;
            const progressPercent = (elapsedDuration / shiftDuration) * 100;
            setProgressPercent(progressPercent);

            if (remainingTime === 0) {
                clearInterval(timer);
            }
        }, 1000);
        return () => clearInterval(timer);
    }, [data?.shift_start_time, data?.shift_end_time]);

    const formatshiftTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const formatTime = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = timeInSeconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleClockify = async (clockifyStatus) => {
        if (clockifyStatus) {
            try {
                const apiData = await getClockify(`?status=${clockifyStatus}&attendance_id=${data?.attendance_id ? data?.attendance_id : null}`);
                if (apiData.detail && apiData.detail === "You are not authorized to perform this action") {
                    // setMessage({ isErrorMsg: true, errorMsgText: apiData.detail });
                    navigate("/");
                    logoutauth();
                  }
                if (apiData && apiData.status === "success") {
                    if(clockifyStatus === "clock_out"){
                        setIsClockOut(true);
                        setIsClockIn(false);
                        setIsPlayPause(false);
                        setSeconds(null);
                        setShiftTimeRemaining(null);
                    } else if (clockifyStatus === "pause"){
                        setIsPlayPause(true);
                    } else if (clockifyStatus === "resume"){
                        setIsPlayPause(false);
                    } else if (clockifyStatus === "clock_in") {
                        setSeconds(0);
                        setIsClockIn(true);
                    }
                    refetch();
                    ToastMsg("success", apiData.message);
                } else {
                    ToastMsg("error", apiData.message);
                }
            } catch (error) {
                ToastMsg("error", error.message);
            }
        }
    }

    const handleGeoLocation = (type) => {
        const authtoken = sessionStorage.getItem("token")
        try {
            let data = {
                "latitude" : JSON.stringify(location?.latitude),
                "longitude" : JSON.stringify(location?.longitude),
                "location" : location?.address?.suburb,
                "city": location?.address?.state_district,
                "location_type" : type
            }
            fetch(EMPLOYEE_TRACKER, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${authtoken}`
                },
                body: JSON.stringify(data)
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        console.log("success", data?.message)
                    }
                    else if (data.status === "fail") {
                        ToastMsg("error", data.message);
                    }
                })
                .catch(error => {
                    ToastMsg("error", error.message);
                });
        } catch (error) {
            ToastMsg("error", error.message);
        }
    };

    const handleClick = () => {
        handleClockify("clock_in");
        handleGeoLocation("clockin")
        // setIsClockIn(true);
    }

    const handlePlayPause = () => {
        handleClockify(isPlayPause ? "resume" : "pause");
        // setIsPlayPause((prevState) => {
        //     const newState = !prevState;
        //     handleClockify(newState ? "pause" : "resume");
        //     return newState;
        // });
    }

    const handleClockOutClick = () => {
        handleClockify("clock_out");
        handleGeoLocation("clockout");
    }

    const handleHappyMeterClk = async (status) => {
        if (status) {
            try {
                const apiData = await getHappyMeter(`?status=${status}`);
                if (apiData && apiData.status === "success" && apiData.data && apiData.data.result) {
                    ToastMsg("success", apiData.message);
                }
            } catch (error) {
                console.error(error.message)
            }
        }
    }

    return (
        <Card className='h-dvh'>
            <div className="h-dvh grid grid-cols-12 relative">
                {loading && (
                    <div className="loaderOverlay">
                        <Spin />
                    </div>
                )}
                <div className='col-span-4 border-r' style ={{height: "86dvh"}}>
                    <p id="common_salary_subTxt" className='pb-2 border-b'>Attendance</p>
                    <div className="border-b flex items-center justify-center" style={{ height: "28dvh"}}>
                        {(isClockIn || isPlayPause) ?
                            <div className="round-image-container round-image">
                                <Progress
                                    type="circle"
                                    percent={progressPercent}
                                    width={"12dvw"}
                                    showInfo={false}
                                    strokeWidth={5}
                                    strokeColor={{
                                        "0%": "#04B7B1",
                                        "10%": "#04B5AF",
                                        "20%": "#04B4AE",
                                        "30%": "#04B2AC",
                                        "40%": "#10BBB5",
                                        "50%": "#1BC3BE",
                                        "60%": "#1BC8C2",
                                        "70%": "#16C3BD",
                                        "80%": "#11BDB8",
                                        "90%": "#0CB8B3",
                                        "100%": "#00736F",
                                      }}
                                />
                                <div className='flex flex-col justify-center items-center clockContainer'>
                                    <div className='clockTimerTxt'>{formatTime(seconds)}</div>
                                    <div className='shiftTimerTxt' style={{ textAlign: shiftTimeRemaining === 0 ? "center" : null }}>
                                        {shiftTimeRemaining === 0 ? "Total Work Hrs" : shiftTimeRemaining !== null ? `Shift Ends In ${formatshiftTime(shiftTimeRemaining)}` : ""}
                                    </div>
                                    <img src={isPlayPause ? ImagePaths.playIcon.default : ImagePaths.pauseIcon.default} alt="playPause" className="playPauseIcon" onClick={handlePlayPause} />
                                    <Button style={styles.buttonStyle} onClick={handleClockOutClick} >Clock Out</Button>
                                </div>
                            </div>
                            : isClockOut ?
                                <div className="round-image-container" style={{ cursor: "pointer" }}>
                                    <img className="round-image" src={ellipse} alt="Round" />
                                    <div className="hand-image-overlay">
                                        <img className="hand-image" src={click} alt="Hand" />
                                    </div>
                                    <div className="text-overlay">Clock Out</div>
                                </div>
                                :
                                <div className="round-image-container" style={{ cursor: "pointer" }} onClick={handleClick}>
                                    <img className="round-image" src={ellipse} alt="Round" />
                                    <div className="hand-image-overlay">
                                        <img className="hand-image" src={click} alt="Hand" />
                                    </div>
                                    <div className="text-overlay">Clock In</div>
                                    <div className="text-overlay-shift">
                                        Shift Starts at
                                        <span>{data?.shift_start_time ? ` ${formatTime12Hour(data.shift_start_time)}` : ""}</span>
                                    </div>
                                </div>}
                    </div>
                    <div className="box-border" style={{ height: "25dvh"}}>
                        <p id="common_salary_subTxt" className='py-2 border-b'>My Leaves</p>
                        <p id="common_salary_subTxt" className='py-2'>{`Leaves Status For ${currentYear}`}</p>
                        <div className="w-dvw grid grid-cols-3" style = {{height : "13dvh"}}>
                            <div className="h-dvh border flex flex-col justify-center items-center mr-2" >
                                <p id="common_home_leaveTxt">{my_leaves?.total_leaves ? my_leaves?.total_leaves : 0}</p>
                                <p id="common_salary_subTxt">Total Leaves</p>
                            </div>
                            <div className="h-dvh border flex flex-col justify-center items-center mr-2">
                                <p id="common_home_leaveTxt">{my_leaves?.leaves_taken ? my_leaves?.leaves_taken : 0}</p>
                                <p id="common_salary_subTxt">Leaves Taken</p>
                            </div>
                            <div className="h-dvh border flex flex-col justify-center items-center mr-2">
                                <p id="common_home_leaveTxt">{my_leaves?.available_leaves ? my_leaves?.available_leaves : 0}</p>
                                <p id="common_salary_subTxt">Available Leaves</p>
                            </div>
                        </div>
                    </div>
                    <div className="box-border" style={{ height: "25dvh" }}>
                        <p id="common_salary_subTxt" className='py-2 border-b'>Happy Meter</p>
                        <p id="common_salary_subTxt" className='py-4'>{`Hi ${employeeName}! How's Your Day Going? How's Your Mood Holding Up Today?`}</p>
                        <div className="w-dvw flex justify-evenly items-center">
                            <div className = "cursor-pointer" onClick={() => handleHappyMeterClk("happy")}>
                            <img className = 'happyMeter_icons' src={happyEmoji} alt = "happy"/>
                            <p className ="happyMeter_txt">Happy</p>
                            </div>
                            <div className = "cursor-pointer" onClick={() => handleHappyMeterClk("average")}>
                            <img className = 'happyMeter_icons' src={averageEmoji} alt = "average"/>
                            <p className ="happyMeter_txt">Average</p>
                            </div>
                            <div className = "cursor-pointer" onClick={() => handleHappyMeterClk("sad")}>
                            <img className = 'happyMeter_icons' src={sadEmoji} alt = "sad"/>
                            <p className ="happyMeter_txt">Sad</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-span-4 border-r' style={{ height:"86dvh" }}>
                    <p id="common_salary_subTxt" className='pl-4 pb-2 border-b'>Upcoming Events</p>
                    <div className="overflow-y-auto" style ={{height: "95%"}}>
                        {
                            upcoming_events?.length > 0 ? upcoming_events?.map(item => {
                                let dateArr = item?.date ? item?.date?.split("-") : [];
                                let monthIndex = parseInt(dateArr[1]);
                                let monthName = dateArr.length > 0 && monthIndex ? monthEnum[monthIndex].text : "";
                                return (<div className="border rounded-md flex justify-between items-center m-3 p-3" key={item.employee_id}>
                                    <EmployeeDetails
                                        details={{
                                            title: item.title ? item.title : "",
                                            first_name: item.employee_name ? item.employee_name : "",
                                            last_name: "",
                                            designation_id: { ...item.designation },
                                            //id: item.employee_id
                                        }}
                                        icon={<Tooltip title={item.event === "birthday" ? "Birthday" : "Work Anniversary"} >
                                            <img src={item.event === "birthday" ? ImagePaths.birthDayIcon.default : ImagePaths.anniversaryIcon.default}
                                            alt="event icon" className='event_icons' />
                                        </Tooltip>} />
                                    <div>
                                        <p id="common_employee_desTxt" style ={{fontWeight: 600}}>{monthName}</p>
                                        <p id="common_home_upcomingTxt">{dateArr[2] ? dateArr[2] : ""}</p>
                                    </div>
                                </div>
                                )
                            }) : <img className="noticeImg homeNotFoundIcon" src={ImagePaths.noDataFound.default} alt="No Data" />
                        }
                    </div>
                </div>
                <div className='col-span-4' style={{ height:"86dvh" }}>
                    <p id="common_salary_subTxt" className='pl-4 pb-2 border-b'>Notice Board</p>
                    <div className="overflow-y-auto" style ={{height: "95%"}}>
                        {
                            (notice_board?.length > 0) ?
                                notice_board?.map(item => {
                                    let fileExtension = item?.attachment ? extractFileExtension(item?.attachment) : null;
                                    return(
                                    <div className="border-l-4 border-red-400 m-3 p-4" style={{ backgroundColor: "#FAFAFA" }} key={item.id}>
                                        <p id="common_salary_subTxt" className='pl-4 mb-3'>{item?.notice_title ? item.notice_title : "Public Notice"}</p>
                                        <p className='ml-4 common_home_noticedescTxt userPermission-checkbox-subTlt'>{item?.description ? item.description : ""}</p>
                                            {fileExtension === 'pdf' ?
                                                <img className="my-5 ml-4 noticeImg" src={ImagePaths.documentPreview.default} alt="notice" />
                                                : fileExtension === null ? null
                                                    : <img className="my-5 ml-4 noticeImg" src={item?.attachment} alt="notice" />}
                                        <div className='text-right'>
                                            <p className="common_home_noticeTxt">{item?.notice_by ? item?.notice_by : "-"}</p>
                                            <p className='common_home_noticedescTxt userPermission-checkbox-subTlt'>{item?.published_date ? `${(item?.published_date).split("-").reverse().join("-")} | ${item?.published_time ? formatTimeToAMPM(item?.published_time) : "-"}` : ""}</p>
                                        </div>
                                    </div>
                                )})
                                : <img className="noticeImg homeNotFoundIcon" src={ImagePaths.noDataFound.default} alt="No Data" />
                        }
                    </div>
                </div>
            </div>
        </Card>
    );
}

const styles = {
    buttonStyle: {
        width: "6dvw",
        fontSize: "0.95dvw",
        backgroundColor: "#D94854",
        color: "white",
        border: "none",
        borderRadius: "2px",
        height: "2dvw",
    }
};

export default Home
