import { Card, Dropdown, Input, Modal, Space, Table, Tooltip, message as alertToastMsg } from "antd";
import {
  CommonClickableApprovalCards,
  CommonClickableCards,
  CommonDeletePopup,
  EmployeeDetails,
} from "components/common/CommonComponent";
import CommonTblHeader from "components/common/CommonTblHeader";
import { useGetApprovalInbox } from "hooks/api/payrollApi/approvalInboxApi";
import { useCallback, useEffect, useState } from "react";
import PayrollFilter from "../PayRollFilters";
import MyButton from "components/ui/Button/MyButton";
import { ImagePaths } from "utils/ImagePath";
import IconButton from "components/ui/Button/IconButton";
import { MoreOutlined } from "@ant-design/icons";
import {
  getLeaveApprovalPagination,
  handleApprovedStatusById,
} from "hooks/api/payrollApi/leaveApprovalApi";
import useApiData from "components/common/useGetApiData";
import { getAdvanceSalaryApprovalPagination } from "hooks/api/payrollApi/advanceSalaryApprovalApi";
import { getReimbursementApprovalPagination } from "hooks/api/payrollApi/reimbursementApprovalApi";
import { getResignationApproval } from "hooks/api/payrollApi/resignationApprovalApi";
import ToastMsg from "components/common/ToastMsg";
import dayjs, { Dayjs } from "dayjs";
import { toIndianCurrency } from "utils/StringUtils";
import { checkUserPermissionFunc } from "components/common/CommonFuntion";
import ApprovalLogPopUp from "pages/employee/ApprovalLogPopUp";
import { PAYROLL_LEAVE_APPROVAL } from "constants";
import { PAYROLL_REIMBURSEMENT_APPROVAL } from "constants";
import { PAYROLL_ADVANCE_SALARY_APPROVAL } from "constants";
import AddEditLeaveApproval from "./EditLeaveApproval";
import EditPayrollAdvanceSalary from "./EditPayrollAdvanceSalary";
import EditReimbursement from "./EditReimbursements";
import { useLocation, useNavigate } from "react-router-dom";
import { EMPLOYEE_RESIGNATION_APPROVAL } from "constants";
import { render } from "@testing-library/react";

const NewApprovalInbox = () => {
  // Token and pagination variable
  const authtoken = sessionStorage.getItem("token");
  const companyid = sessionStorage.getItem("userId");
  const tableContainerHeight = "62vh";
  const dateFormat = "YYYY-MM-DD";
  const pageSize = 10;
  let query = ""

  // Styles
  const styles = {
    dropOverLay: {
      maxHeight: 250,
      overflowY: "auto",
    },

    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  };

  // States
  const selectedInbox = [
    "Leave Approval",
    "Advance Salary",
    "Reimbursement",
    "Resignation",
  ];
  const [approvalInboxData, setApprovalInboxData] = useState(() => {
    const savedApprovalType = sessionStorage.getItem('selectedApprovalType'); // Change to sessionStorage
    return savedApprovalType && selectedInbox.includes(savedApprovalType)
      ? savedApprovalType
      : selectedInbox[0];
  });

  // Use effect to save the approval type to sessionStorage
  useEffect(() => {
    sessionStorage.setItem('selectedApprovalType', approvalInboxData); // Change to sessionStorage
  }, [approvalInboxData]);

  // // Optional: Clear the session storage on unmount
  // useEffect(() => {
  //   return () => {
  //     sessionStorage.removeItem('selectedApprovalType');
  //   };
  // }, []);

  const selectedFilter = [
    "",
    "pending",
    "approved",
    "rejected",
    "cancelled",
  ];
  const [selectedFilterVal, setSelectedFilterVal] = useState(selectedFilter[0]);

  // Filter
  const initialData = {
    from_date: "",
    to_date: "",
    leave_type: "",
    employee: "",
    leaveTypeList: [],
  };
  const [filterDatas, setFilterDatas] = useState({ initialData });
  const [filterVisible, setFilterVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [searchText, setSearchText] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const [highlightedId, setHighlightedId] = useState(null);

  // Approval Log
  const [isApprovalLog, setIsApprovalLog] = useState(false);
  const [approvalData, setApprovalData] = useState([]);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [cancelData, setCancelData] = useState([]);

  // Edit Log
  const [isReasonPopup, setReasonPopup] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState(null);

  // Pagination
  const [pageNo, setPageNo] = useState(1);
  const [sorting, setSorting] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  // Effect for navigating based on location state
  useEffect(() => {
    if (location.state && location.state.selectedInboxVal) {
      setApprovalInboxData("Resignation");
      navigate('/employee/approvalinbox', { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (location.state && location.state.notification_type) {
      if (location.state.notification_type == 'Leave') {
        setApprovalInboxData("Leave Approval");
      } else if (location.state.notification_type == 'Reimbursement') {
        setApprovalInboxData("Reimbursement");
      } else if (location.state.notification_type == 'Advance') {
        setApprovalInboxData("Advance Salary");
      } else {
        setApprovalInboxData("Resignation");
      }
    }
  }, [location])

  useEffect(() => {
    if (location.state?.type_id) {
      setHighlightedId(location.state?.type_id);
      const timer = setTimeout(() => {
        setHighlightedId(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [location]);

  // Reset filter value when approval type changes
  useEffect(() => {
    setSelectedFilterVal("");
  }, [approvalInboxData]);

  // Redirect if no auth token
  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  // API mapping
  const approvalApiMap = {
    "Leave Approval": getLeaveApprovalPagination,
    "Reimbursement": getReimbursementApprovalPagination,
    "Advance Salary": getAdvanceSalaryApprovalPagination,
    "Resignation": getResignationApproval,
  };

  const [data, setData] = useState({
    "Leave Approval": null,
    "Reimbursement": null,
    "Advance Salary": null,
    "Resignation": null,
  });

  const [loading, setLoading] = useState(null);
  const [pageInfo, setPageInfo] = useState(null);
  const [message, setMessage] = useState(null);
  const [extras, setExtras] = useState(null);

  // Build query strings for filtering
  const searchFilterQuery =
    selectedFilterVal.toLowerCase() === ""
      ? "" // No filter applied
      : `approval_status=${selectedFilterVal.toLowerCase()}`;

  const combinedQuery = [searchFilterQuery]
    .filter(Boolean)
    .join("&");

  const [responseData, responsePageInfo, responseLoading, _message, refetch, extrasResponse] = useApiData(
    approvalApiMap[approvalInboxData],
    {
      pageNo,
      pageSize,
      query: combinedQuery,
      searchText,
      sorting,
    }
  );

  // Use an effect to store the fetched data in the state
  useEffect(() => {
    if (responseData) {
      setData((prevData) => ({
        ...prevData,
        [approvalInboxData]: responseData,
      }));
      setPageInfo(responsePageInfo);
      setLoading(responseLoading);
      setMessage(_message);
      if (selectedFilterVal === '') {
        setExtras(extrasResponse.data.extras);
      }
    }
  }, [responseData, approvalInboxData, selectedFilterVal]);

  const userPermission = checkUserPermissionFunc(
    "employee",
    "Approval Inbox",
    "SubMenu"
  );

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg("error", _message.errorMsgText);
      // alertToastMsg.error(_message.errorMsgText)
      setLoading(false);
    }
  }, [_message.errorMsgText]);

  // Trigger API call when approvalInboxData, pageNo, or sorting changes
  useEffect(() => {
    if (location.state && location.state.selectedInboxVal) {
      return
    } else {
      setPageInfo(null);
      refetch(pageNo, pageSize, sorting);
    }
    setData({
      "Leave Approval": null,
      "Reimbursement": null,
      "Advance Salary": null,
      "Resignation": null,
    });
    setLoading(true);
  }, [approvalInboxData, pageNo, sorting]);

  // Handle loading state when selectedFilterVal changes
  useEffect(() => {
    setData({
      "Leave Approval": null,
      "Reimbursement": null,
      "Advance Salary": null,
      "Resignation": null,
    });
    setLoading(true);
  }, [selectedFilterVal]);

  const handleIndexPageName = (item) => {
    setApprovalInboxData(item);
    setPageNo(1);
  };

  // approval log and cancel log functions
  const handleApprovalLog = (record) => {
    // let data = record?.approval_log?.length > 0 ? record?.approval_log : [];

    setApprovalData(record);
    setIsApprovalLog(true);
  };

  const handleApprovalLogCls = () => {
    setIsApprovalLog(false);
    setApprovalData([]);
  };

  const handleCancelPopup = (record) => {
    setConfirmPopup(true);
    setCancelData(record);
  };

  const handlePopClose = () => {
    setConfirmPopup(false);
  };

  const handleCancelPost = useCallback(() => {
    const authtoken = sessionStorage.getItem("token");
    var API = null;
    var data = cancelData ? cancelData : null;
    try {
      let updatedFormData = {
        approval_notes: "",
        approval_status: "cancelled",
        id: data?.id,
        request_type: "cancel request",
      };
      if (data?.approval_type === "Leave") {
        API = PAYROLL_LEAVE_APPROVAL;
        updatedFormData = {
          ...updatedFormData,
          company_id: companyid,
          from_date: dayjs(data.from_date).format(dateFormat),
          to_date: dayjs(data.to_date).format(dateFormat),
          leave_id: data?.id,
          leave_type: data?.leave_type_id,
          reason: data?.reason,
          uploaded_documents: [],
        };
      } else if (data?.approval_type === "Reimbursements") {
        API = PAYROLL_REIMBURSEMENT_APPROVAL;
        updatedFormData = {
          ...updatedFormData,
          approved_amount: data?.approval_amount,
          reimbursement_type: data?.reimbursement_type_id,
          requested_amount: data?.requested_amount,
        };
      } else {
        API = PAYROLL_ADVANCE_SALARY_APPROVAL;
        updatedFormData = {
          ...updatedFormData,
          advance_requested: data?.requested_amount,
          approved_amount: data?.approval_amount,
          requested_emi_period: data?.emi_period,
          debit_from: data?.debit_from,
        };
      }
      fetch(API, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${authtoken}`,
        },
        body: JSON.stringify(updatedFormData),
      })
        .then((response) => response.json())
        .then(async (data) => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            setPageNo(1);
            refetch(1, pageSize, query, searchText, sorting);
            setDataUpdated(!dataUpdated);
            handlePopClose();
          } else if (data.status === "fail") {
            ToastMsg("error", data.message);
          }
        })
        .catch((error) => {
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    }
  });

  // aprove && reject functions
  const handleSave = () => {
    setEditVisible(false);
    setSelectedDetails({});
    refetch(pageNo, pageSize, query, searchText, sorting);
    setDataUpdated(!dataUpdated);
    setReasonPopup(false);
  };

  const handleCancel = () => {
    setEditVisible(false);
    setSelectedDetails({});
    setReasonPopup(false);
  };

  const handleDescriptionPopup = () => {
    return (
      <Modal
        centered
        open={isReasonPopup}
        onCancel={handleCancel}
        footer={null}
        width={"30vw"}
      >
        <Input.TextArea
          autoComplete="off"
          bordered="true"
          className="mt-7 textarea"
          rows={4}
          autoSize={{
            minRows: 3,
            maxRows: 4,
          }}
          maxLength={150}
          placeholder="Enter reason for Approval Notes for a leave Approval"
          name="approval_notes"
          onChange={(e) => {
            setUpdatedStatus({
              ...updatedStatus,
              approval_notes: e.target.value,
            });
          }}
        />
        <div className="flex justify-end items-end mt-3">
          <MyButton
            htmlType="button"
            label={"Cancel"}
            onClick={handleCancel}
            loading={loading}
            paddingX={"0 1.2vw"}
            marginRight={"0.625vw"}
            testID="asgreg_btn_draft"
          />
          <MyButton
            htmlType="button"
            label={
              updatedStatus?.approval_status === "rejected"
                ? "Reject"
                : "Approve"
            }
            onClick={handleSubmit}
            loading={loading}
            paddingX={"0 1.2vw"}
            bgColor={
              updatedStatus?.approval_status === "rejected"
                ? "#D94854"
                : "#0E862A"
            }
          />
        </div>
      </Modal>
    );
  };

  const handleSubmit = () => {
    handleApprovedStatusById(updatedStatus).then((data) => {
      if (data.status === "success") {
        ToastMsg("success", data.message);
        handleSave();
      } else if (data.status === "fail") {
        ToastMsg("error", data.message);
        handleSave();
      }
    });
  };

  // Conditionally set columns based on the inbox type
  const columns =
    approvalInboxData === "Leave Approval"
      ? [
        {
          title: "Approval Sent",
          dataIndex: "approvalSent",
          key: "approvalSent",
          width: 160,
          ellipsis: {
            showTitle: false,
          },
          render: (_, record) => {
            let empDetails = {
              ...record.employee,
              designation_id: record?.employee?.designation
                ? {
                  id: record?.employee?.designation.id,
                  designation_name: record?.employee?.designation?.name,
                }
                : { id: 0, designation_name: "" },
              first_name: record?.employee?.employee_name
                ? record?.employee?.employee_name
                : "",
              id: record?.employee?.employee_id,
            };
            return (
              <span
                style={{
                  color: record.is_active ? "black" : "#CACACA",
                  cursor: "pointer",
                }}
              >
                <EmployeeDetails details={empDetails} isFromInbox={true} className=" w-48" profileWrap={true} />
              </span>
            );
          },
        },
        {
          title: "From Date",
          dataIndex: "from_date",
          key: "from_date",
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          render: (_, record) => {
            return (
              <span>{dayjs(record.from_date).format("DD-MM-YYYY")}</span>
            );
          },
        },
        {
          title: "To Date",
          dataIndex: "to_date",
          key: "to_date",
          sorter: true,
          ellipsis: {
            showTitle: false,
          },
          render: (_, record) => {
            return <span>{dayjs(record.to_date).format("DD-MM-YYYY")}</span>;
          },
        },
        // {
        //   title: "Leave Type",
        //   dataIndex: "leave_type",
        //   key: "leave_type",
        //   ellipsis: {
        //     showTitle: false,
        //   },
        //   render: (_, record) => {
        //     return <span className=" capitalize">{record.leave_type ? record.leave_type : "-"}</span>;
        //   },
        // },
        {
          title: "Status",
          dataIndex: "approval_status",
          key: "approval_status",
          render: (record) => {
            return (
              <span className=" capitalize">{record}</span>
            );
          }
        },
        {
          title: "Approved By",
          render: (_, record) => {
            return (
              <span>
                {record.approval_status === "approved" ? (
                  <span className=" capitalize">
                    {record?.approved_by?.employee_name}
                  </span>
                ) : (
                  "-"
                )}
              </span>
            );
          },
        },
        {
          title: "Reason",
          dataIndex: "reason",
          key: "reason",
          ellipsis: {
            showTitle: false,
          },
          render: (record) => {
            const reason = record || "-";
            const displayedReason = reason.length > 18 ? `${reason.substring(0, 18)}...` : reason;

            return (
              <Tooltip title={reason.length > 18 ? reason : ""}>
                <span>{displayedReason}</span>
              </Tooltip>
            );
          }
        }
      ]
      : approvalInboxData === "Advance Salary"
        ? [
          {
            title: "Request Date",
            dataIndex: "request_date",
            key: "date",
            sorter: true,
            render: (_, record) => (
              <p>
                {record?.date ? dayjs(record?.date).format("DD/MM/YYYY") : "-"}
              </p>
            ),
          },
          {
            title: "Approval Sent from",
            dataIndex: "sent_from",
            key: "employee__employee_name",
            sorter: true,
            width: 180,
            render: (_, record) => {
              let empDetails = {
                ...record.employee,
                designation_id: record?.employee?.designation
                  ? {
                    id: record?.employee?.designation.id,
                    designation_name: record?.employee?.designation.name,
                  }
                  : { id: 0, designation_name: "" },
                first_name: record?.employee?.employee_name
                  ? record?.employee?.employee_name
                  : "",
                id: record?.employee?.employee_id,
              };
              return (
                <span
                  style={{
                    color: record.is_active ? "black" : "#CACACA",
                    cursor: "pointer",
                  }}
                >
                  <EmployeeDetails details={empDetails} className=" w-48" profileWrap={true} />
                </span>
              );
            },
          },
          {
            title: "Request Notes",
            dataIndex: "request_notes",
            key: "reason",
            sorter: true,
            ellipsis: {
              showTitle: false,
            },
            render: (_, record) => {
              const reason = record?.reason || "-";
              const displayedReason = reason.length > 18 ? `${reason.substring(0, 18)}...` : reason;

              return (
                <Tooltip title={reason.length > 18 ? reason : ""}>
                  <span>{displayedReason}</span>
                </Tooltip>
              );

            }
          },
          {
            title: "Request Amount",
            dataIndex: "request_amount",
            key: "advance_requested",
            sorter: true,
            render: (_, record) =>
              record?.advance_requested?.length > 7 ? (
                <Tooltip title={record?.advance_requested}>
                  {
                    <span>
                      {record?.advance_requested?.slice(0, 20) + "..."}
                    </span>
                  }
                </Tooltip>
              ) : (
                <p>
                  {record?.advance_requested
                    ? toIndianCurrency(record?.advance_requested)
                    : "-"}
                </p>
              ),
          },
          {
            title: "Status",
            dataIndex: "approval_status",
            key: "approval_status",
            render: (record) => {
              return (
                <span className=" capitalize">{record}</span>
              );
            }
          },
        ]
        : approvalInboxData === "Reimbursement"
          ? [
            {
              title: "Approval Request Date",
              dataIndex: "date",
              key: "date",
              render: (_, record) => (
                <p>
                  {record?.date ? dayjs(record?.date).format("DD/MM/YYYY") : "-"}
                </p>
              ),
            },
            {
              title: "Approval Sent from",
              dataIndex: "send_from",
              key: "employee__employee_name",
              width: 180,
              render: (_, record) => {
                let empDetails = {
                  ...record.employee,
                  designation_id: record?.employee?.designation
                    ? {
                      id: record?.employee?.designation.id,
                      designation_name: record?.employee?.designation.name,
                    }
                    : { id: 0, designation_name: "" },
                  first_name: record?.employee?.employee_name
                    ? record?.employee?.employee_name
                    : "",
                  id: record?.employee?.employee_id,
                };
                return (
                  <span
                    style={{
                      color: record.is_active ? "black" : "#CACACA",
                      cursor: "pointer",
                    }}
                  >
                    <EmployeeDetails details={empDetails} className=" w-48" profileWrap={true} />
                  </span>
                );
              },
            },
            {
              title: "Request Notes",
              dataIndex: "request_notes",
              key: "description",
              render: (_, record) =>
                record?.description?.length > 28 ? (
                  <Tooltip title={record?.description}>
                    {<span>{record?.description?.slice(0, 25) + "..."}</span>}
                  </Tooltip>
                ) : (
                  <p>{record?.description ? record?.description : "-"}</p>
                ),
            },
            {
              title: "Request Amount",
              dataIndex: "requested_amount",
              key: "requested_amount",
              render: (_, record) =>
                record?.requested_amount?.length > 7 ? (
                  <Tooltip title={record?.requested_amount}>
                    {
                      <span>
                        {record?.requested_amount?.slice(0, 20) + "..."}
                      </span>
                    }
                  </Tooltip>
                ) : (
                  <p>
                    {record?.requested_amount
                      ? toIndianCurrency(record?.requested_amount)
                      : "-"}
                  </p>
                ),
            },
            {
              title: "Status",
              dataIndex: "approval_status",
              key: "approval_status",
              render: (record) => {
                return (
                  <span className=" capitalize">{record}</span>
                );
              }
            },
          ]
          : [
            {
              title: "Employee Details",
              dataIndex: "emp_detail",
              key: "employee__employee_name",
              render: (_, record) => {
                let empDetails = {
                  ...record.employee,
                  designation_id: record?.employee?.designation
                    ? {
                      id: record?.employee?.designation.id,
                      designation_name: record?.employee?.designation.name,
                    }
                    : { id: 0, designation_name: "" },
                  first_name: record?.employee?.employee_name
                    ? record?.employee?.employee_name
                    : "",
                  id: record?.employee?.employee_id,
                };

                return (
                  <span
                    style={{
                      color: record.is_active ? "black" : "#CACACA",
                      cursor: "pointer",
                    }}
                  >
                    <EmployeeDetails details={empDetails} className=" w-48" profileWrap={true} />
                  </span>
                );
              },
            },
            {
              title: "Request Date",
              dataIndex: "request_date",
              key: "request_date",
              render: (_, record) => {
                const date = record?.request_date;
                return (
                  <p>
                    {date && dayjs(date).isValid() ? dayjs(date).format("DD/MM/YYYY") : "-"}
                  </p>
                );
              },
            },            
            {
              title: "Reason For Resignation",
              dataIndex: "reason",
              key: "reason",
              render: (record) => {
                const reason = record || "-";
                const displayedReason = reason.length > 18 ? `${reason.substring(0, 18)}...` : reason;

                return (
                  <Tooltip title={reason.length > 18 ? reason : ""}>
                    <span>{displayedReason}</span>
                  </Tooltip>
                );
              }
            },
            {
              title: "Status",
              dataIndex: "approval_status",
              key: "approval_status",
              render: (record) => {
                return (
                  <span className=" capitalize">{record}</span>
                );
              }
            },
          ];

  if (userPermission?.edit) {
    approvalInboxData === "Leave Approval"
      ? columns.push({
        title: "Action",
        key: "action",
        render: (_, record) => {
          const items = [
            {
              key: "1",
              label: <div className="empApproveTxt">Approval Log</div>,
            },
            {
              key: "2",
              label: <div className="empApproveTxt">Cancel Request</div>,
              disabled:
                !record?.enable_cancel_request ||
                record?.approval_status === "cancelled" ||
                record?.approval_status === "rejected",
            },
          ];

          return (
            <div className="flex items-center gap-5">
              <IconButton
                className=""
                title={record.approval_status === 'approved' ? 'Request has approved' : "Request Details"}
                icon={
                  <img
                    src={ImagePaths.ApproveIcon.default}
                    alt="Request Details"
                  />
                }
                disabled={record.approval_status === 'approved' || record.approval_status === 'rejected' || record.approval_status === 'cancelled'}
                onClick={() => (loading ? "" : handleEditClick(record))}
              />
              <Space size={"middle"}>
                <Dropdown
                  menu={{
                    items,
                    onClick: ({ key }) => {
                      if (key === "1") {
                        handleApprovalLog(record);
                      } else {
                        handleCancelPopup(record);
                      }
                    },
                  }}
                  trigger={["click"]}
                  placement="bottomLeft"
                  overlayStyle={styles.dropOverLay}
                >
                  <IconButton
                    title=""
                    icon={
                      <img src={ImagePaths.moreIcon.default} alt="more" />
                    }
                  />
                </Dropdown>
              </Space>
            </div>
          );
        },
      })
      : approvalInboxData === "Advance Salary"
        ? columns.push({
          title: "Action",
          key: "action",
          width: "15%",
          render: (_, record) => {
            const items = [
              {
                key: "1",
                label: <div className="empApproveTxt">Approval Log</div>,
              },
              {
                key: "2",
                label: <div className="empApproveTxt">Cancel Request</div>,
                disabled:
                  !record?.enable_cancel_request ||
                  record?.approval_status === "cancelled" ||
                  record?.approval_status === "rejected",
              },
            ];

            return (
              <div className="flex items-center gap-5">
                <IconButton
                  className=""
                  title={record.approval_status === 'approved' ? 'Request has approved' : "Request Details"}
                  icon={
                    <img
                      src={ImagePaths.ApproveIcon.default}
                      alt="Request Details"
                    />
                  }
                  disabled={record.approval_status === 'approved' || record.approval_status === 'rejected' || record.approval_status === 'cancelled'}
                  onClick={() => (loading ? "" : handleEditClick(record))}
                />
                <Space size={"middle"}>
                  <Dropdown
                    menu={{
                      items,
                      onClick: ({ key }) => {
                        if (key === "1") {
                          handleApprovalLog(record);
                        } else {
                          handleCancelPopup(record);
                        }
                      },
                    }}
                    trigger={["click"]}
                    placement="bottomLeft"
                    overlayStyle={styles.dropOverLay}
                  >
                    <IconButton
                      title=""
                      icon={
                        <img src={ImagePaths.moreIcon.default} alt="more" />
                      }
                    />
                  </Dropdown>
                </Space>
              </div>
            );
          },
        })
        : approvalInboxData === "Reimbursement"
          ? columns.push({
            title: "Action",
            key: "action",
            render: (_, record) => {
              const items = [
                {
                  key: "1",
                  label: <div className="empApproveTxt">Approval Log</div>,
                },
                {
                  key: "2",
                  label: <div className="empApproveTxt">Cancel Request</div>,
                  disabled:
                    !record?.enable_cancel_request ||
                    record?.approval_status === "cancelled" ||
                    record?.approval_status === "rejected",
                },
              ];

              return (
                <div className="flex items-center gap-5">
                  <IconButton
                    className=""
                    title={record.approval_status === 'approved' ? 'Request has approved' : "Request Details"}
                    icon={
                      <img
                        src={ImagePaths.ApproveIcon.default}
                        alt="Request Details"
                      />
                    }
                    disabled={record.approval_status === 'approved' || record.approval_status === 'rejected' || record.approval_status === 'cancelled'}
                    onClick={() => (loading ? "" : handleEditClick(record))}
                  />
                  <Space size={"middle"}>
                    <Dropdown
                      menu={{
                        items,
                        onClick: ({ key }) => {
                          if (key === "1") {
                            handleApprovalLog(record);
                          } else {
                            handleCancelPopup(record);
                          }
                        },
                      }}
                      trigger={["click"]}
                      placement="bottomLeft"
                      overlayStyle={styles.dropOverLay}
                    >
                      <IconButton
                        title=""
                        icon={
                          <img src={ImagePaths.moreIcon.default} alt="more" />
                        }
                      />
                    </Dropdown>
                  </Space>
                </div>
              );
            },
          })
          : columns.push(
            {
              title: "Action",
              key: "action",
              render: (_, record) => {
                const items = [
                  {
                    key: "1",
                    label: <div className="empApproveTxt">Approval Log</div>,
                  },
                  {
                    key: "2",
                    label: <div className="empApproveTxt">Cancel Request</div>,
                    disabled:
                      !record?.enable_cancel_request ||
                      record?.approval_status === "cancelled" ||
                      record?.approval_status === "rejected",
                  },
                ];
                return (
                  <div className="flex items-center gap-5">
                    {userPermission?.edit ?
                      <>
                        <IconButton
                          title={record.approval_status === 'approved' ? 'Request has approved' : "Approval"}
                          className="mr-3"
                          disabled={record.approval_status === 'approved' || record.approval_status === 'rejected' || 
                            record.approval_status === 'cancelled' || (record.level === 2 && record.approval_log[0].request__approval_status === 'approved'
                            && record.approved_by.id == sessionStorage.getItem('employee_id') && record.final_approved_by.id != sessionStorage.getItem('employee_id'))}
                          icon={<img src={ImagePaths.ApproveIcon.default} alt="View" />}
                          onClick={() => (loading ? "" : handleResignationEditClick(record))}
                        />
                        <Space size={"middle"}>
                          <Dropdown
                            menu={{
                              items,
                              onClick: ({ key }) => {
                                if (key === "1") {
                                  handleApprovalLog(record);
                                } else {
                                  handleCancelPopup(record);
                                }
                              },
                            }}
                            trigger={["click"]}
                            placement="bottomLeft"
                            overlayStyle={styles.dropOverLay}
                          >
                            <IconButton
                              title=""
                              icon={
                                <img src={ImagePaths.moreIcon.default} alt="more" />
                              }
                            />
                          </Dropdown>
                        </Space>
                      </>
                      : null}
                  </div>
                )
              },
            },
          );
  }

  const queryString = `?page_no=${pageNo}&page_size=${pageSize}&search=${searchText}&approval_status=${selectedFilterVal}&leave_id=${filterDatas.initialData.leave_type}&from_date=${filterDatas.initialData.from_date}&to_date=${filterDatas.initialData.to_date}&employee=${filterDatas.initialData.employee}`;

  const handleFilter = () => {
    setFilterVisible(true);
  };

  const handleFilterClick = (item) => {
    setSelectedFilterVal(item);
  };

  const handleSearch = () => {
    setPageNo(1);
    refetch(1,
      pageSize,
      combinedQuery,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend"
      ? "desc"
      : sorter.order === "ascend"
        ? "asc"
        : ""
      }&`;
    const sortQuery = sorter.order
      ? `${sortorder}column=${sorter.columnKey}`
      : "";
    setLoading(true);
    setData([])
    refetch(pagination.current, pageSize, combinedQuery, searchText, sortQuery);
    setSorting(sortQuery);
  };

  const handleEditClick = (details) => {
    if (editVisible && Object.keys(selectedDetails).length > 0) {
      ToastMsg("warning", "Please cancel the existing edit operation!");
    } else {
      setEditVisible(true);
      setSelectedDetails(details);
    }
  };

  const handleResignationEditClick = (details) => {
    navigate('/employee/approvalinbox/resignationRequest', { state: { editDetails: details } });
    setSelectedDetails(details);
    setEditVisible(true);
  };

  // Utility to debounce a function
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search);
    setReorderedColumns(reorderedList);
  }


  // Filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    refetch(
      1,
      pageSize,
      `leave_id=${data.leave_type ? data.leave_type : ""}&from_date=${data.from_date ? data.from_date : ""
      }&to_date=${data.to_date ? data.to_date : ""}&employee=${data.employee ? data.employee : ""
      }`,
      searchText,
      sorting
    );
    setFilterVisible(false);
  };

  const ClosePopUp = () => {
    setFilterVisible(false);
  };

  const clearFilter = () => {
    setFilterDatas((prevState) => ({
      ...prevState,
      from_date: "",
      to_date: "",
      leave_type: "",
      employee: "",
    }));
    setPageNo(1);
    refetch(1, pageSize, query, searchText, sorting);
  };

  const handleRowDoubleClick = (record) => {
    if (record.approval_status === "approved" || record.approval_status === "rejected" || record.approval_status === "cancelled" ||
      (record.level === 2 && record.approval_log[0].request__approval_status === 'approved' && record.approved_by.id == sessionStorage.getItem('employee_id')) 
      && record.final_approved_by.id != sessionStorage.getItem('employee_id')) {
      handleApprovalLog(record);
    } else if (approvalInboxData === "Resignation") {
      handleResignationEditClick(record);
    } else if (record.approval_status === "pending") {
      handleEditClick(record);
    } else {
      handleEditClick(record);
    }
  };

  const urlMap = {
    "Leave Approval": PAYROLL_LEAVE_APPROVAL,
    "Advance Salary": PAYROLL_ADVANCE_SALARY_APPROVAL,
    "Reimbursement": PAYROLL_REIMBURSEMENT_APPROVAL,
    "Resignation": EMPLOYEE_RESIGNATION_APPROVAL,
  };

  return (
    <div className="p-2">
      {/* Header */}
      {/* <h1 className=" font-[Urbanist] text-2xl font-medium">Approval Inbox</h1> */}

      {/* Leave types and request status count */}
      <div className="flex my-1">
        {selectedInbox.map((item, index) => {
          return (
            <CommonClickableApprovalCards
              key={index}
              label={item}
              testID={`${item}-${index}`}
              onClick={() => handleIndexPageName(item)}
              isSelected={approvalInboxData === item}
            />
          );
        })}
      </div>

      <Card className="h-full">
        <div className={"h-1/6"}>
          {/* Common header */}
          <CommonTblHeader
            pageName={approvalInboxData}
            pageInfo={pageInfo}
            columns={columns}
            handleSearch={handleSearch}
            onStateChange={handleStateChanges}
            isAddRequired={false}
            isDraftRequired={false}
            isDraft={false}
            isExcelRequired={false}
            approvalFilter={true}
            selectedFilterData={selectedFilter}
            selectedFilterVal={selectedFilterVal}
            handleFilter={handleFilter}
            handleFilterClick={handleFilterClick}
            query={queryString}
            url={urlMap[approvalInboxData] || ''}
            extras={extras}
          />
        </div>

        {/* Filter and table */}
        <div className="h-5/6 flex justify-center items-center mt-1">
          {filterVisible ? (
            <div className="w-1/4 mt-5" style={styles.fltContainer}>
              <PayrollFilter
                pageName={(() => {
                  // Mapping approvalInboxData values to PayrollFilter pageName
                  const pageNameMap = {
                    Reimbursement: "Reimbursements",
                    Resignation: "ResignationApproval",
                    "Leave Approval": "LeaveApproval",
                    "Advance Salary": "AdvanceSalary",
                  };

                  // Return the mapped pageName or a default value
                  return pageNameMap[approvalInboxData] || "Leave Approval";
                })()}
                filterDatas={filterDatas}
                closeFilter={ClosePopUp}
                filteredData={renderFilter}
                clearData={clearFilter}
              />
            </div>
          ) : null}
          <div
            className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"}
            style={styles.fltContainer}
          >
            <Table
              columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
              dataSource={loading ? [] : data[approvalInboxData] || []}
              loading={loading}
              style={{ "--table-container-height": tableContainerHeight }}
              scroll={{ y: `calc(${tableContainerHeight} - 30px)` }}
              onChange={handleTableChange}
              pagination={{
                style: { marginRight: 20, padding: 0 },
                defaultPageSize: pageSize,
                showSizeChanger: false,
                showQuickJumper: true,
                total: pageInfo?.totalCount || 0,
                pageSizeOptions: ["10", "20", "30"],
                pageSize,
                defaultCurrent: pageNo,
              }}
              key={dataUpdated ? "updated" : "not-updated"}
              rowClassName={(record) => {
                return record.id === highlightedId ? "highlighted-row" : "";
              }}
              onRow={(record) => ({
                onDoubleClick: () => handleRowDoubleClick(record),
                className: 'hover-row',
              })}
            />
          </div>
        </div>

        {/* leave approval options */}
        {confirmPopup && (
          <CommonDeletePopup
            handleCancelBtn={handlePopClose}
            handleDeleteBtn={handleCancelPost}
            sentence={"Are You Sure ? Do you want to cancel?"}
            okBtnLable={"Yes"}
          />
        )}
        {isApprovalLog ? (
          <ApprovalLogPopUp
            TblData={approvalData}
            onClose={handleApprovalLogCls}
          />
        ) : null}

        {/* edit leave request */}
        {isReasonPopup ? handleDescriptionPopup() : null}
        {editVisible && Object.keys(selectedDetails).length > 0 && approvalInboxData === "Leave Approval" && (
          <AddEditLeaveApproval
            editDetails={selectedDetails}
            onHide={handleCancel}
            onSave={handleSave}
            fromEdit={editVisible}
            leaveTypeList={
              filterDatas?.leaveTypeList?.length > 0
                ? filterDatas?.leaveTypeList
                : []
            }
            isEdit={userPermission?.edit}
          />
        )}

        {editVisible && Object.keys(selectedDetails).length > 0 && approvalInboxData === "Advance Salary" && (
          <EditPayrollAdvanceSalary
            editDetails={selectedDetails}
            onHide={handleCancel}
            onSave={handleSave}
            fromEdit={editVisible}
            isEdit={userPermission?.edit}
          />
        )}

        {editVisible && Object.keys(selectedDetails).length > 0 && approvalInboxData === "Reimbursement" && (
          <EditReimbursement
            editDetails={selectedDetails}
            onHide={handleCancel}
            onSave={handleSave}
            reimbursementTypeList={
              filterDatas?.reimbursementTypeList?.length > 0
                ? filterDatas?.reimbursementTypeList
                : []
            }
            isEdit={userPermission?.edit}
          />
        )}
      </Card>
    </div>
  );
};

export default NewApprovalInbox;
