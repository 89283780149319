import { getCSVData, getMethod } from "components/common/CommonFuntion";
import Item from "components/common/MigrationInputItems";
import MyButton from "components/ui/Button/MyButton";
import React, { useState, useCallback, useEffect } from "react";
import * as XLSX from "xlsx";
import { EMPLOYEE_MIGRATION_TEMPLATE } from "constants";
import { Button, Card, Modal, Spin, Tooltip, Upload } from "antd";
import { ImagePaths } from "utils/ImagePath";
import { useLocation, useNavigate } from "react-router-dom";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import { getEmployeeDesignation } from "hooks/api/masterApi/employeeDesignationApi";
import { getEmployeeType } from "hooks/api/masterApi/employeeTypeApi";
import { getEmployeeShift } from "hooks/api/configurationApi/employeeShiftApi";
import { getUserGroup } from "hooks/api/adminApi/userGroupApi";
import { getEmployeeGroup } from "hooks/api/masterApi/employeeGroupApi";
import { CommonDeletePopup } from "components/common/CommonComponent";
import { MIGRATIONDATA } from "constants";
import ToastMsg from "components/common/ToastMsg";
import { imgUploadSize } from 'components/common/enum';
import { UploadOutlined } from "@ant-design/icons";
import migration from "assets/migration.gif";
import { bloodGroupEnum, genderEnum, relationshipEnum, titleEnum } from "components/common/enum";

const MigrationTestTable = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    attachment: [],
    totalcount: 0,
    fileName: "",
  });
  const authtoken = sessionStorage.getItem("token");
  const location = useLocation();
  const { editDetails, limit } = location.state || {};

  console.log(editDetails, limit);

  const [validateSheet, setValidateSheet] = useState()

  // states for api call
  const [departmentlist, setdepartment] = useState([]);
  const [designationList, setdesignation] = useState([]);
  const [eType, setEType] = useState([]);
  const [eShift, setEShift] = useState([]);
  const [userGroup, setUserGroup] = useState([]);
  const [employeeGroupList, setemployeegroup] = useState([]);
  const [deleteItem, setDeleteClicked] = useState({ index: undefined, isDeleteClicked: false });

  const [isNotifyPopup, setIsNotifyPopup] = useState(false)
  const [migratekey, setmigrate] = useState(null)
  const [disableProcess, setProcess] = useState(true);
  const [iscancelPop, setIsCancelPop] = useState(false);
  const [rowErrors, setRowErrors] = useState({});
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const navigate = useNavigate();

  const handleClose = () => {
    setIsNotifyPopup(false);
  }

  const popupcancel = () => {
    setIsCancelPop(false);
  }

  const handleBack = () => {
    navigate("/datamigration/employeemigration");
  };

  const handleNavigate = () => {
    navigate("/datamigration/employeemigration/employeedetails", { state: { migration_id: migratekey } });
  }

  const handleCancel = () => {
    setFormData({
      attachment: [],
      totalcount: 0,
      fileName: "",
      processed_no: 0,
      conflict_no: 0,
    });
    setmigrate(null)
    setProcess(true);
    setTableData([])
    setIsCancelPop(false);
  }

  // notification
  const NotificationPopup = () => {
    return (
      <Modal
        centered
        open={true}
        footer={null}
        width={"34vw"}
        className="custom-modal"
        closable={false}
      >
        <p id="common_weekoffTxt">Employee has been migrated successfully. Kindly map the Reporting Manager for the employee by clicking on 'Allocate Reporting Manager' button</p>
        <div className='flex justify-end items-center  mt-20'>
          <MyButton htmlType="button" label="Cancel" onClick={handleClose} outlined={true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
          <MyButton htmlType="button" label="Allocate Reporting Manager" onClick={handleNavigate} loading={loading} paddingX={"0 1.2vw"} />
        </div>
      </Modal>
    )
  }

  // cancel popup
  const cancelPopup = () => {
    return (
      <Modal
        centered
        open={true}
        footer={null}
        width={"25vw"}
        className="custom-modal"
        closable={false}
      >
        <p id="common_weekoffTxt">Are you sure want cancel this Process ?</p>
        <div className='flex justify-end items-center  mt-20'>
          <MyButton htmlType="button" label="NO" onClick={popupcancel} outlined={true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
          <MyButton htmlType="button" label="Yes" onClick={handleCancel} loading={loading} paddingX={"0 1.2vw"} />
        </div>
      </Modal>
    )
  }

  // onchange for table fields
  const onChange = useCallback((id, field, value) => {
    setTableData((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return { ...item, [field]: value };
        }
        return item;
      });
    });
  }, []);

  const handleUploadChange = ({ target }) => {
    const file = target.files[0];

    if (file) {
      // Validate file type
      const isCSV = file.type === 'text/csv'; // Correct MIME type for CSV files
      if (!isCSV) {
        ToastMsg("error", "You can only upload CSV files!");
        resetFileInput();
        return false;
      }

      // Validate file size (ensure it's less than imgUploadSize MB)
      const imgUploadSize = 2; // Replace with the desired file size limit in MB
      const isWithinSizeLimit = file.size / 1024 / 1024 < imgUploadSize;
      if (!isWithinSizeLimit) {
        ToastMsg("error", `File must be smaller than ${imgUploadSize}MB!`);
        resetFileInput();
        return false;
      }

      // Update formData with the file name
      setFormData((prevData) => ({
        ...prevData,
        fileName: file.name, // Store the file name
      }));

      setLoading(true);
      const reader = new FileReader();

      reader.onload = (event) => {
        const result = event.target.result;
        const workbook = XLSX.read(result, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const rawData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const formattedData = formatData(rawData);
console.log(formattedData);

        if (formattedData.length > 0) {
          setTableData(formattedData);
        } else {
          ToastMsg("error", "No valid data found in the uploaded file.");
          resetFileInput();
        }

        setLoading(false);
      };

      reader.readAsBinaryString(file);
    }
  };

  const resetFileInput = () => {
    // Clear the file input and reset file name in formData
    setFormData((prevData) => ({
      ...prevData,
      fileName: "", // Reset file name
    }));

    // If you want to reset the file input visually, you can trigger a re-render:
    document.getElementById("fileInput").value = ""; // Clear the file input
  };

  // Helper function to determine if a field is mandatory
  const isMandatoryField = (fieldName) => {
    return fieldName.includes("*");
  };

  const formatData = (rawData) => {
    const headers = rawData[2];
    // console.log(headers, "headers");

    // // Filter and trim mandatory headers, storing the trimmed values
    // const mandatoryHeaders = headers
    //   .filter((header) => isMandatoryField(header)) // Check if it's a mandatory field
    //   .map((header) => header.replace(/\s+/g, "")); // Trim spaces from the header

    // console.log("Mandatory Headers:", mandatoryHeaders);
    // setValidateSheet(mandatoryHeaders);

    if (headers) {
      // Validation: Check if the first line contains the expected headers
      if (headers[0] !== "Title *" || headers[1] !== "First Name *") {
        alert("error", "The format of the uploaded Excel sheet does not match the downloaded template. Please review and try again.");
        return [];
      }
    }

    // Validation: Check if the file contains more than 1005 rows of data
    if (rawData.length > 1005) {
      alert("error", "The uploaded file contains more than 1005 rows. Please reduce the number of rows and try again.");
      return [];
    }

    // Check for empty rows after the first 3 lines
    const dataRows = rawData.slice(3);
    for (let i = 0; i < dataRows.length; i++) {
      const row = dataRows[i];
      const isEmptyRow = row.every(cell => cell === undefined || cell === null || cell === "");

      if (isEmptyRow) {
        alert("error", `The uploaded file contains empty rows after the headers. Please remove any empty rows and try again.`);
        return [];
      }
    }

    // Map valid rows into the desired format and process date cells
    return dataRows.map((row) => {
      const rowObject = {};
      row.forEach((cell, cellIndex) => {
        const trimmedCell = cell?.toString().trim();
        // Clean header by removing asterisks and spaces
        const header = headers[cellIndex]?.replace(/[\*\s]/g, "").trim();
        if (header) {
          // Process cell value if it's a date
          const processedValue = isDate(trimmedCell) ? parseAndFormatDate(trimmedCell) : trimmedCell;
          rowObject[header] = processedValue;
        }
      });
      return { index_id: (Math.random() * 100000).toFixed(0), ...rowObject };
    });
  };


  function isDate(value) {
    const dateRegex = /^\d{4}[-/](0[1-9]|1[0-2])[-/](0[1-9]|[12][0-9]|3[01])$|^(0[1-9]|[12][0-9]|3[01])[-/](0[1-9]|1[0-2])[-/]\d{4}$/;
    return dateRegex.test(value);
  }

  const handleDownload = () => {
    const queryString = "";
    getCSVData(EMPLOYEE_MIGRATION_TEMPLATE, queryString);
  };

  function parseAndFormatDate(dateString) {
    // Regular expression to check the format, allowing 1 or 2 digits for day/month
    const yyyyMmDdRegex = /^\d{4}[-/](0[1-9]|1[0-2])[-/](0[1-9]|[12][0-9]|3[01])$/;
    const ddMmYyyyRegex = /^(0[1-9]|[12][0-9]|3[01])[-/](0[1-9]|1[0-2])[-/]\d{4}$/;

    let year, month, day;

    // If the format is yyyy/mm/dd
    if (yyyyMmDdRegex.test(dateString)) {
      [year, month, day] = dateString.split('-');
    }
    // If the format is dd/mm/yyyy
    else if (ddMmYyyyRegex.test(dateString)) {
      [day, month, year] = dateString.split('-');
    } else {
      // Invalid format
      return dateString;
    }

    // Ensure the date is valid
    const date = new Date(year, month - 1, day); // JS months are 0-based

    if (date && date.getFullYear() === parseInt(year) &&
      date.getMonth() + 1 === parseInt(month) &&
      date.getDate() === parseInt(day)) {

      // Return the date in yyyy/mm/dd format
      return `${year}-${padZero(month)}-${padZero(day)}`;
    } else {
      return dateString;
    }
  }

  // Helper function to pad numbers with leading zeros if needed
  function padZero(num) {
    return num.toString().padStart(2, '0');
  }

  // changes for table validation
  const validateAllFields = (data) => {
    const errors = {};

    data.forEach((record) => {
      const recordErrors = {};
      Object.keys(record).forEach((field) => {
        const error = validateField(field, record[field], record);
        if (error) {
          recordErrors[field] = error;
        }
      });
      if (Object.keys(recordErrors).length > 0) {
        errors[record.index_id] = recordErrors;
      }
    });

    setRowErrors(errors);
  };

  // Validate on initial load
  useEffect(() => {
    validateAllFields(tableData);
  }, [tableData]);

  // validation rules
  const validationRules = {
    Title: (value) => {
      if (!value) return "Title should not be empty";
      const isValidTitle = titleEnum.some((option) => option.value === value);
      if (!isValidTitle) return "Invalid Title";
      return null;
    },
    FirstName: (value) => {
      if (!value) return "First Name should not be empty";
      if (value.length > 50) return "First Name should not exceed 50 characters";
      return null;
    },
    LastName: (value) => {
      if (!value) return "Last Name should not be empty";
      if (value.length > 50) return "Last Name should not exceed 50 characters";
      return null;
    },
    PersonalEmailID: (value, record) => {
      const MailComment = record?.comments?.find(
        (comment) => comment.key === "personal_email_id"
      );
      const isInvalid =
        MailComment?.value === value ||
        !value ||
        !emailPattern.test(value.toString());
      return isInvalid ? MailComment?.message || "Invalid Email ID" : null;
    },
    PersonalMobileNo: (value, record) => {
      const MobileComment = record?.comments?.find(
        (comment) => comment.key === "personal_mobile_no"
      );
      const isInvalid =
        MobileComment?.value === value ||
        !value ||
        !/^\d{10}$/.test(value.toString().trim());
      return isInvalid
        ? MobileComment?.message || "Mobile No should be in 10 digits"
        : null;
    },
    ProfessionalEmailID: (value, record) => {
      const MailComment = record?.comments?.find(
        (comment) => comment.key === "ProfessionalEmailID"
      );
      const isInvalid =
        MailComment?.value === value ||
        !value ||
        !emailPattern.test(value.toString());
      return isInvalid ? MailComment?.message || "Invalid Email ID" : null;
    },
    Department: (value) => {
      if (!value) return "Department should not be empty";
      const isValidDepartment = departmentlist.some(
        (option) => option.department_name === value
      );
      if (!isValidDepartment) return "Invalid Department";
      return null;
    },
    Designation: (value) => {
      if (!value) return "Designation should not be empty";
      const isValidDesignation = designationList.some(
        (option) => option.designation_name === value
      );
      if (!isValidDesignation) return "Invalid Designation";
      return null;
    },
    EmployeeType: (value) => {
      if (!value) return "Employee Type should not be empty";
      const isValidEmployeeType = eType.some(
        (option) => option.employee_type_name === value
      );
      if (!isValidEmployeeType) return "Invalid Employee Type";
      return null;
    },
    DateOfJoining: (value) => {
      if (!value) return "Date of Joining should not be empty";
      const formattedDate = parseAndFormatDate(value);
      const isValid = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(formattedDate);
      return !isValid ? "Invalid Date (YYYY-MM-DD)" : null;
    },
    Shift: (value) => {
      if (!value) return "Shift should not be empty";
      const isValidShift = eShift.some(
        (option) => option.shift_name === value
      );
      if (!isValidShift) return "Invalid Shift";
      return null;
    },
    City: (value) => {
      if (!value) return "City should not be empty";
      if (value.length > 50) return "City should not exceed 50 characters";
      return null;
    },
    State: (value) => {
      if (!value) return "State should not be empty";
      if (value.length > 50) return "State should not exceed 50 characters";
      return null;
    },
    Pincode: (value) => {
      // if (!value) return "Pincode should not be empty";
      if (value) {
        if (!/^\d{6}$/.test(value)) return "Pincode should be exactly 6 digits";
        return null;
      }
    },
    PresentAddressCity: (value) => {
      // if (!value) return "Present Address City should not be empty";
      if (value) {
        if (value.length > 50) return "Present Address City should not exceed 50 characters";
        return null;
      }
    },
    PresentAddressState: (value) => {
      // if (!value) return "Present Address State should not be empty";
      if (value) {
        if (value.length > 50) return "Present Address State should not exceed 50 characters";
        return null;
      }
    },
    PresentAddressPincode: (value) => {
      // if (!value) return "Present Address Pincode should not be empty";
      if (value) {
        if (value) {
          if (!/^\d{6}$/.test(value)) return "Present Address Pincode should be exactly 6 digits";
          return null;
        }
      }
    },
    Usergroup: (value) => {
      if (!value) return "User group name should not be empty";
      const isUserGroupName = userGroup.some(
        (option) => option.user_group_name === value
      );
      if (!isUserGroupName) return "Invalid user group name";
      return null;
    },
    // Username: (value) => {
    //   // if (!value) return "Username should not be empty";
    //   if (value.length > 50) return "Username should not exceed 50 characters";
    //   return null;
    // },
    // Password: (value) => {
    //   // if (!value) return "Password should not be empty";
    //   if (value.length > 50) return "Password should not exceed 50 characters";
    //   return null;
    // },
    Gender: (value) => {
      // if (!value) return "Gender should not be empty";
      if (value) {
        const isValidGender = genderEnum.some(
          (option) => option.text === value
        );
        if (!isValidGender) return "Invalid Gender type";
        return null;
      }
    },
    ContactPersonName: (value) => {
      // if (!value) return "Contact Person Name should not be empty";
      if (value) {
        if (value.length > 50) return "Contact Person Name should not exceed 50 characters";
        return null;
      }
    },
    RelationshipType: (value) => {
      // if (!value) return "Relationship Type should not be empty";
      if (value) {
        const isValidRelationship = relationshipEnum.some(
          (option) => option.text === value
        );
        if (!isValidRelationship) return "Invalid Relationship type";
        return null;
      }
    },
    ContactPersonMobileNo: (value) => {
      // if (!value) return "Contact Person Mobile No should not be empty";
      if (value) {
        if (!/^\d{10}$/.test(value)) return "Contact Person Mobile No should be exactly 10 digits";
        return null;
      }
    },
    EmployeeGroup: (value) => {
      // if (!value) return "Employee Group should not be empty";
      if (value) {
        const isValidEmployeeGroup = employeeGroupList.some(
          (option) => option.employee_group_name === value
        );
        if (!isValidEmployeeGroup) return "Invalid Employee Group type";
        return null;
      }
    },
    BloodGroup: (value) => {
      // if (!value) return "Blood Group should not be empty";
      if (value) {
        const isValidBloodGroup = bloodGroupEnum.some(
          (option) => option.text === value
        );
        if (!isValidBloodGroup) return "Invalid Blood Group type";
        return null;
      }
    },
    EmergencyContactPerson: (value) => {
      // if (!value) return "Emergency Contact Person should not be empty";
      if (value) {
        if (value.length > 50) return "Emergency Contact Person should not exceed 50 characters";
        return null;
      }
    },
    EmergencyContactNo: (value) => {
      // if (!value) return "Emergency Contact No should not be empty";
      if (value) {
        if (!/^\d{10}$/.test(value)) return "Emergency Contact No should be exactly 10 digits";
        return null;
      }
    },
    EmergencyRelationshipType: (value) => {
      // if (!value) return "Relationship Type should not be empty";
      if (value) {
        const isValidRelationship = relationshipEnum.some(
          (option) => option.text === value
        );
        if (!isValidRelationship) return "Invalid Relationship type";
        return null;
      }
    },
  };

  const validateField = (fieldName, value, record) => {
    const validationFn = validationRules[fieldName];
    return validationFn ? validationFn(value, record) : null;
  };

  const hasErrors = Object.values(rowErrors).some(
    (errors) => Object.values(errors).some((error) => error !== null)
  );

  /*call departments*/
  const getDepartmentList = async () => {
    try {
      const apiData = await getEmployeeAllDepartment(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setdepartment(apiData.data?.length > 0 ? [...apiData.data] : []);
      } else {
        setdepartment([]);
      }
    } catch (error) {
      setdepartment([]);
    }
  };

  /*call designation*/
  const getEmployeeDesignationList = async () => {
    try {
      const apiData = await getEmployeeDesignation(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setdesignation([...apiData.data]);
      } else {
        setdesignation([]);
      }
    } catch (error) {
      setdesignation([]);
    }
  };

  /* Employee Type */
  const getEmployeeTypeList = async () => {
    setEType([]);
    try {
      const apiData = await getEmployeeType(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setEType(apiData.data?.length > 0 ? [...apiData.data] : []);
      } else {
        setEType([]);
      }
    } catch (error) {
      setEType([]);
    }
  };

  /* Employee shift */
  const employeeShift = async () => {
    try {
      const apiData = await getEmployeeShift(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setEShift(apiData.data?.length > 0 ? [...apiData.data] : []);
      } else {
        setEShift([]);
      }
    } catch (error) {
      setEShift([]);
    }
  };

  const getUserGroupList = async () => {
    try {
      const apiData = await getUserGroup(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setUserGroup(apiData.data?.length > 0 ? [...apiData.data] : []);
      } else {
        setUserGroup([]);
      }
    } catch (error) {
      setUserGroup([]);
    }
  };

  /*call employee group*/
  const getEmpGrpList = async () => {
    try {
      const apiData = await getEmployeeGroup(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setemployeegroup(apiData.data?.length > 0 ? [...apiData.data] : [])
      } else {
        setemployeegroup([])
      }
    } catch (error) {
      setemployeegroup([])
    }
  }

  const getresponsemigrate = async (id) => {
    try {
      setLoading(true)
      const apiData = await getMethod(`${MIGRATIONDATA}?migration_id=${id ? id : migratekey}`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setTableData([...apiData.data.employee_data]);
        setFormData({
          ...formData,
          conflict_no: apiData.data.conflict_records,
          processed_no: apiData.data.processed_records,
        })
        setLoading(false)
      } else {
        setTableData([]);
        setLoading(false)
      }
    } catch (error) {
      setTableData([]);
      setLoading(false)
    }
  }

  useEffect(() => {
    getDepartmentList();
    getEmployeeDesignationList();
    getEmployeeTypeList();
    employeeShift();
    getUserGroupList();
    getEmpGrpList();
  }, []);

  const handleDeleteClicked = (index) => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      index: index,
      isDeleteClicked: true
    }));
  }

  const handleCancelBtn = () => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      index: undefined,
      isDeleteClicked: false
    }));
  }

  const deleteRow = (index) => {
    const newData = [...tableData];
    newData.splice(index, 1); // Remove the row at the given index
    setTableData(newData);

    setDeleteClicked((prevdata) => ({
      ...prevdata,
      index: undefined,
      isDeleteClicked: false
    }));
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleprocess = () => {
    setLoading(true);
    const updatedData = { data: tableData, migration_id: migratekey }
    fetch(MIGRATIONDATA, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${authtoken}`,
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.status === "success") {
          ToastMsg("success", data.message);
          await delay(1000);
          setFormData({
            ...formData,
            fileName: ""
          })
          setmigrate(data.migration_id)
          getresponsemigrate(data?.migration_id)
          setLoading(false);
        } else if (data.status === "fail") {
          ToastMsg("error", data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        error.json().then((data) => {
          // setErrors(data);
          setLoading(false);
        });
      });
    // setTimeout(() => {
    //   setLoading(false);
    // }, 3000); // 2000ms = 2 seconds
  };

  const handlemigrate = () => {
    setLoading(true);
    const updatedData = { data: tableData, migrate: true, migration_id: migratekey };  // Add "migrate: true" to tableData

    fetch(MIGRATIONDATA, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${authtoken}`,
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.status === "success") {
          ToastMsg("success", data.message);
          await delay(1000);
          setIsNotifyPopup(true)
          setLoading(false);
        } else if (data.status === "fail") {
          ToastMsg("error", data.message);
        }
      })
      .catch((error) => {
        error.json().then((data) => {
          // setErrors(data);
          setLoading(false);
        });
      });
    // setTimeout(() => {
    //   setLoading(false);
    // }, 3000); // 2000ms = 2 seconds
  };

  return (
    <div className="h-full">
      <Card className="p-3">
        <div className="flex items-center">
          <Tooltip title={"Back"} className="mr-2" placement="bottom">
            <img
              style={{ cursor: "pointer" }}
              src={ImagePaths.backArrow.default}
              alt="Back"
              className="commonTblBackIcon"
              onClick={handleBack}
            />
          </Tooltip>
          <p className="common_tltTxt">{`Employees (${tableData?.length})`}</p>
        </div>
        {(tableData.length > limit) && <label className="conflictrec" style={{ float: "inline-end" }}> You Can only process {limit} employees</label>}
        <div className="mt-7 flex align-center justify-between" >
          {/* <div className="flex gap-5">
            <MyButton onClick={handleDownload} label={"Download Sheet"} />
            <div className="flex items-center justify-between border border-gray-300 rounded-sm p-2 w-full" style={{ width: "20dvw", height: "6dvh" }}>
              <span style={{ width: "16dvw", overflow: "hidden" }} className="text-gray-500 px-5">{formData?.fileName ? formData?.fileName : "Browse Files"}</span>
              <Upload
                disabled={migratekey ? true : false}
                id={`notice_board_attachment`}
                maxCount={1}
                onChange={handleUploadChange}
                beforeUpload={(file) => {
                  setLoading(true)
                  const isCSV = file.type === 'text/csv'; // Correct MIME type for CSV files
                  if (!isCSV) {
                    ToastMsg("error", "You can only upload CSV files!");
                    return false;
                  }
                  const isWithinSizeLimit = file.size / 1024 / 1024 < imgUploadSize;
                  if (!isWithinSizeLimit) {
                    ToastMsg("error", `File must be smaller than ${imgUploadSize}MB!`);
                    return false;
                  }
                  setFormData({
                    ...formData,
                    fileName: file.name,
                  })
                  return false;
                }}
                showUploadList={false}
                accept=".csv">
                <Button
                  type="text"
                  icon={<UploadOutlined />}
                  className="bg-white border-none"
                  style={{ border: "1px solid #afacac", paddingLeft: "7px", paddingTop: "2px" }}
                />
              </Upload>
            </div> */}
          <div className="flex gap-5 items-center">
            <MyButton onClick={handleDownload} label={"Download Sheet"} />
            <input
              id="fileInput"
              type="file"
              className="block w-80 text-sm text-slate-500
                        file:mr-4 file:py-[6px] file:px-4
                        file:border-0
                        file:text-sm file:font-semibold
                        file:bg-[#334b49] file:text-white border border-y-1"
              accept=".xlsx, .xls, .csv"
              onChange={handleUploadChange}
              placeholder="Browse Files"
            />
            {migratekey ?
              <div>
                <p class="processrec">Processed records : {formData?.processed_no}</p>
                <p class="conflictrec">Conflict records : {formData?.conflict_no}</p>
              </div> : null
            }
          </div>
          <div className="flex">
            <MyButton label={"Cancel"} onClick={() => setIsCancelPop(true)} />
            {editDetails?.status !== "completed" ? <MyButton className="ml-5" type="button" disabled={(tableData.length > limit) || tableData.length <= 0 || loading || hasErrors} onClick={handleprocess} label={"Process"} bgColor={hasErrors ? "#d6d6d6" : "#334B49"} /> : null}
            <MyButton className="ml-5" onClick={editDetails?.status === "completed" ? handleNavigate : handlemigrate} style={{ backgroundColor: "grey" }} label={editDetails?.status === "completed" ? "Allocate Report Manager" : "Migrate"} bgColor={(tableData.length > 0 || !migratekey || formData?.conflict_no != 0) ? "#d6d6d6" : "#334B49"} disabled={(tableData.length > limit) || loading || tableData.length > 0 || !migratekey || formData?.conflict_no != 0} />
          </div>
        </div>
      </Card>

      <Card>

        {loading && (
          <div className="loaderOverlay">
            <Spin></Spin>
            {/* <img src={migration} style={{ width: "5dvw" }}></img> */}
          </div>
        )}

        {/* Scrollable container for the table with horizontal scrolling */}
        <div className="overflow-x-auto" style={{ height: "65dvh" }}>
          <table className=" w-max table-auto mb-2">
            <thead className="sticky top-0 bg-white shadow-md h-[50px] z-10">
              <tr className="text-left font-[Urbanist]">
                <th className="py-2 px-4 border" style={{ width: "100px" }}>Title</th>
                <th className="py-2 px-4 border" style={{ width: "160px" }}>First Name</th>
                <th className="py-2 px-4 border" style={{ width: "160px" }}>Last Name</th>
                <th className="py-2 px-4 border" style={{ width: "180px" }}>Personal Mail Id</th>
                <th className="py-2 px-4 border" style={{ width: "150px" }}>Personal Mobile No</th>
                <th className="py-2 px-4 border" style={{ width: "180px" }}>Professional Mail Id</th>
                <th className="py-2 px-4 border" style={{ width: "200px" }}>Department</th>
                <th className="py-2 px-4 border" style={{ width: "200px" }}>Designation</th>
                <th className="py-2 px-4 border" style={{ width: "200px" }}>Employee Type</th>
                <th className="py-2 px-4 border" style={{ width: "140px" }}>Date Of Joining</th>
                <th className="py-2 px-4 border" style={{ width: "150px" }}>Shift</th>
                <th className="py-2 px-4 border" style={{ width: "150px" }}>City</th>
                <th className="py-2 px-4 border" style={{ width: "150px" }}>State</th>
                <th className="py-2 px-4 border" style={{ width: "150px" }}>Pincode</th>
                <th className="py-2 px-4 border" style={{ width: "150px" }}>Present Address City</th>
                <th className="py-2 px-4 border" style={{ width: "150px" }}>Present Address State</th>
                <th className="py-2 px-4 border" style={{ width: "150px" }}>Present Address Pincode</th>
                <th className="py-2 px-4 border" style={{ width: "150px" }}>User group</th>
                {/* <th className="py-2 px-4 border">User Name</th>
                <th className="py-2 px-4 border">Password</th> */}
                <th className="py-2 px-4 border" style={{ width: "120px" }}>Gender</th>
                <th className="py-2 px-4 border" style={{ width: "150px" }}>Contact Person Name</th>
                <th className="py-2 px-4 border" style={{ width: "150px" }}>Relationship</th>
                <th className="py-2 px-4 border" style={{ width: "150px" }}>Contact Person Mobile No</th>
                <th className="py-2 px-4 border" style={{ width: "150px" }}>Employee Group</th>
                <th className="py-2 px-4 border" style={{ width: "150px" }}>Blood Group</th>
                <th className="py-2 px-4 border" style={{ width: "150px" }}>Emergency Contact Person</th>
                <th className="py-2 px-4 border" style={{ width: "150px" }}>Emergency Contact No</th>
                <th className="py-2 px-4 border" style={{ width: "150px" }}>Emergency Relationship</th>
                {migratekey ?
                  <th className="py-2 px-4 border" style={{ width: "150px" }}>Comments</th> : null}
                <th className="py-2 px-4 border">Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <Item
                  key={item.index_id}
                  item={item}
                  index={index}
                  onChange={onChange}
                  departmentlist={departmentlist}
                  designationList={designationList}
                  eType={eType}
                  eShift={eShift}
                  userGroup={userGroup}
                  employeeGroupList={employeeGroupList}
                  handleDeleteClicked={handleDeleteClicked}
                  validateField={validateField}
                  parseAndFormatDate={parseAndFormatDate}
                  migratekey={migratekey}
                />
              ))}
            </tbody>
          </table>
        </div>

        {deleteItem.isDeleteClicked ?
          <CommonDeletePopup
            handleCancelBtn={handleCancelBtn}
            handleDeleteBtn={() => deleteRow(deleteItem.index)} /> : null}
        {isNotifyPopup ? NotificationPopup() : null}
        {iscancelPop ? cancelPopup() : null}
      </Card>
    </div>
  );
};

export default MigrationTestTable;
