import { MYPLANS, USER_PROFILE } from 'constants';
import { getMethod } from "components/common/CommonFuntion";

    export const getmyplan = async () => {
        const url = `${MYPLANS}`
        return await getMethod(url);
      }

      export const userprofile = async () => {
        const url = `${USER_PROFILE}`
        return await getMethod(url);
      }

