export class ImagePaths {
    static appLogo = require("../assets/svg/appLogo.svg");
    static loginLogo = require("../assets/svg/LoginLogo.svg");
    static home = require("../assets/svg/home.svg");
    static dashboard = require("../assets/svg/Dashboard.svg");
    static admin = require("../assets/svg/admin.svg");
    static master = require("../assets/svg/master.svg");
    static configuration = require("../assets/svg/configuration.svg");
    static employee = require("../assets/svg/Employee.svg");
    static payroll = require("../assets/svg/payroll.svg");
    static attendance = require("../assets/svg/attendance.svg");
    static assetManagement = require("../assets/svg/Assetmanagement.svg");
    static reports = require("../assets/svg/reports.svg");
    static search = require("../assets/svg/SearchLine.svg");
    static filter = require("../assets/svg/filter.svg");
    static alignColumn = require("../assets/svg/alignColumn.svg");
    static export = require("../assets/svg/export.svg");
    static print = require("../assets/svg/print.svg");
    static draft = require("../assets/svg/draft.svg");
    static edit = require("../assets/svg/edit.svg");
    static editOff = require("../assets/svg/editOff.svg");
    static delete = require("../assets/svg/delete.svg");
    static logoutIcon = require("../assets/svg/menu_line.svg");
    static backArrow = require("../assets/svg/BackArrow.svg");
    static dragIcon = require("../assets/svg/dragIcon.svg");
    static defaultProfile = require("../assets/svg/Male.svg");
    static imageUpload = require("../assets/svg/imageUpload.svg");
    static documentPreview = require("../assets/svg/document.svg");
    static documentUpload = require("../assets/svg/documentUpload.svg");
    static companyProfile = require("../assets/svg/Comapanyprofile.svg");
    static pdfOn = require("../assets/svg/pdfOn.svg");
    static selectArrow = require("../assets/svg/arrow_drop_down.svg")
    static circleRight = require("../assets/svg/circleRight.svg");
    static circleWrong = require("../assets/svg/circleWrong.svg");
    static view = require("../assets/svg/view.svg");
    static bellIcon = require("../assets/svg/NotificationBell.svg");
    static playIcon = require("../assets/svg/playIcon.svg");
    static pauseIcon = require("../assets/svg/pauseIcon.svg");
    static notifyApprove = require("../assets/svg/notifyApprove.svg");
    static notifyReject = require("../assets/svg/notifyReject.svg");
    static birthDayIcon = require("../assets/svg/birthdayIcon.svg");
    static anniversaryIcon = require("../assets/svg/anniversaryIcon.svg");
    static modalEdit = require("../assets/svg/modalEdit.svg");
    static downloadPdf = require("../assets/svg/downloadPdf.svg");
    static moreIcon = require("../assets/svg/threedots.svg");
    static noDataFound = require("../assets/svg/noDataFound.svg");
    static clockReload = require("../assets/svg/clockreload.svg");
    static exportIcon = require("../assets/svg/exportIcon.svg");
    static previous = require("../assets/svg/previous.svg");
    static next = require("../assets/svg/next.svg");
    static filterFunnel = require("../assets/svg/FilterFunnel.svg");
    static mail = require("../assets/svg/Mail.svg");
    static call = require("../assets/svg/Call.svg");
    static location = require("../assets/svg/Location.svg");
    static addOff = require("../assets/svg/Addoff.svg");
    static teamLead = require("../assets/svg/teamlead.svg");
    static rosterIcon = require("../assets/svg/rosterIcon.svg");
    static reasonIcon = require("../assets/svg/reasonIcon.svg");
    static documentUploadOutline = require("../assets/svg/documentUploadOutline.svg");
    static documentPdfOff = require("../assets/svg/downloadPdfOff.svg");
    static documentPdfOn = require("../assets/svg/downloadPdfOn.svg");
    static documentUploadOff = require("../assets/svg/documentUploadOff.svg");
    static downArrow = require("../assets/svg/downArrow.svg");
    static ApproveIcon = require("../assets/svg/approval.svg");
    static attachment_line =require("../assets/svg/attachment_line.svg");
    static downloadArrow = require("../assets/svg/download.svg");
    static Upload = require("../assets/svg/Upload.svg");
    static myplan = require("../assets/svg/plan.svg");
    static tick_disabled = require("../assets/svg/Frame 74.svg");
    static tick_selected = require("../assets/svg/Frame 75.svg");
    static addicon = require("../assets/svg/plus.svg");   
    static contact = require("../assets/svg/Contact.svg");
    static check = require("../assets/svg/Check.svg");   
    static close = require("../assets/svg/Close.svg");   
    static migration = require("../assets/migration.gif");   
}
