import React, { useContext, useEffect, useState } from "react";
import { Card, Form, Input, Modal, Radio, Switch, Table, Tooltip, Typography, Upload } from "antd";
import { userprofile } from "hooks/api/myplanapi";
import useGetData from "components/common/useGetData";
import { ImagePaths } from "utils/ImagePath";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { imgUploadSize } from 'components/common/enum';
import ToastMsg from "components/common/ToastMsg";
import MyButton from "components/ui/Button/MyButton";
import { USER_PROFILE, PROFILE_CHANGE_PASSWORD } from "constants";
import { noBlankSpacesValidator, passwordValidator } from "components/common/validation";
import { toast } from "react-toastify";
import { AuthContext } from "context/auth/authentication";
import { Navigate } from "react-router-dom";

const EmployeeProfile = () => {
    const authtoken = sessionStorage.getItem("token");  
    const [imageUrl, setImageUrl] = useState("");
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [profileList, setProfileList] = useState([]);
    const [imageProfile, setimageProfile] = useState([]);
    const is_password_updated = JSON.parse(sessionStorage.getItem("is_password_updated"));
    const [isNotifyPopup, setIsNotifyPop] = useState(false);
    const { logoutauth } = useContext(AuthContext);

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);
    const [formData, setFormData] = useState({
        current_password: "",
        new_password: "",
        confirm_password: "",
        profile: []
    });
    const [data] = useGetData(userprofile);

    useEffect(() => {
        setImageUrl(data?.image_url)
    },[data])

    useEffect(() => {
        if(!is_password_updated){
            setIsNotifyPop(true);
        }
    },[is_password_updated])

    const changePasswordNotify = () => {
        return (
          <Modal
            centered
            open={true}
            footer={null}
            width={"20vw"}
            height={"10vw"}
            className="custom-modal"
            closable={false}
          >
            <p id="common_weekoffTxt">Kindly change your password for further process</p>
            <div className='flex justify-end items-center  mt-5'>
              <MyButton htmlType="button" label="ok" onClick={popupcancel} outlined={true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
            </div>
          </Modal>
        )
    }

    const popupcancel = () => {
        setIsNotifyPop(false);
      }

    const handleImgChange = (fileList) => {
        if (fileList?.length > 0 && fileList[0]?.originFileObj) {
            const uploadedFile = fileList[0].originFileObj;
            const imgUrl = URL.createObjectURL(uploadedFile);
            setFormData({
                ...formData,
                profile: fileList,
              });
            form.setFields([
                {
                    name: "profile",
                    value: uploadedFile ? [uploadedFile] : [],
                }
            ]);
            setProfileList(uploadedFile ? [uploadedFile] : []);
            setImageUrl(imgUrl);
            setimageProfile("profile_document", fileList);
            try {
                setLoading(true)
                const dataForm = new FormData();
                dataForm.append("profile", fileList[0]?.originFileObj)
                fetch(USER_PROFILE, {
                    method: "POST",
                    headers: {
                    'Authorization': `token ${authtoken}`
                    },
                    body: dataForm
                })
                    .then((response) => response.json())
                    .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        setLoading(false);
                        setFormData({
                        profile: [],
                        })
                    }
                    else if (data.status === "fail") {
                        setLoading(false);
                        ToastMsg("error", data.message);
                    }
                    })
                    .catch(error => {
                    setLoading(false);
                    ToastMsg("error", error.message);
                    });
                } catch (error) {
                ToastMsg("error", error.message);
                }
        }
    };
    const profileProps = {
        onRemove: (file) => {
            const index = profileList.indexOf(file);
            const newFileList = profileList.slice();
            newFileList.splice(index, 1);
            setProfileList(newFileList);
        },
        beforeUpload: (file) => {
            const isLt2M = file.size / 1024 / 1024 < imgUploadSize;
            if (!isLt2M) {
                ToastMsg("error", `Image must smaller than ${imgUploadSize}MB!`);
                return false;
            }
            setProfileList([...profileList, file]);
            return false;
        },
        profileList,
    };
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined style={{ fontSize: "1vw" }} /> : <PlusOutlined style={{ fontSize: "1vw" }} />}
            <div style={{ fontSize: "1vw", marginTop: 8 }}>Upload</div>
        </div>
    );
    const handlesave = () => {
        if(formData.confirm_password === formData.new_password){
            try {
                setLoading(true)
                const dataForm = new FormData();
                dataForm.append("current_password", formData.current_password ? formData.current_password : "");
                dataForm.append("new_password", formData.new_password ? formData.new_password : "");
                fetch(PROFILE_CHANGE_PASSWORD, {
                    method: "POST",
                    headers: {
                    'Authorization': `token ${authtoken}`
                    },
                    body: dataForm
                })
                    .then((response) => response.json())
                    .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        setLoading(false);
                        setFormData({
                        current_password: "",
                        new_password: "",
                        confirm_password: "",
                        })
                        logoutauth();
                        Navigate("/");
                    }
                    else if (data.status === "fail") {
                        setLoading(false);
                        ToastMsg("error", data.message);
                    }
                    })
                    .catch(error => {
                    setLoading(false);
                    ToastMsg("error", error.message);
                    });
                } catch (error) {
                ToastMsg("error", error.message);
                }
        }
        else{
            ToastMsg("error", "Confirm password should be same");
        }
    }

    const handleChange = (name, value) => {
        setFormData({
          ...formData,
          [name]: value,
        });
        form.setFieldsValue({
          ...formData,
          [name]: value,
        });
        triggerFieldValidation(name);
    };
    
    const triggerFieldValidation = (fieldName) => {
        form.validateFields([[fieldName]]);
    };
    
  const renderInput = (label, statekey, testID = '', maxLength = 150, required = false) => {
    return (
      <>
      <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
      <Input.Password
        autoComplete='off'
        style={styles.textField}
        type={"text"}
        name={statekey}
        value={formData[statekey]}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        onKeyDown={(e) => {
            if (e.key === ' ') {
              e.preventDefault();
            }
          }}
        data-testID={testID}
      />
      </>
    )
    }

    return (
        <Card style={{ height: "90dvh", width:"100%" }}>
            <div className="flex">
            <Card style={{overflowY:"scroll", height: "80dvh",width:"50%"}}>
            <div style={{ fontSize: "1.5vw", fontFamily: "Urbanist", fontWeight: "600" }} className={""} >
                Employee Profile
            </div>
            <Form.Item
                name="profile"
                fileList={profileList}
                defaultValue={[profileList]}
                style={{ textAlign: "center" }}
            >
                <div className="grid grid-cols-1 justify-center items-center" style={{ height: "20vh" }}>
                    <Upload
                        style={{ width: "100%", height: "100%" }}
                        {...profileProps}
                        listType="picture-circle"
                        showUploadList={false}
                        onChange={({ fileList }) => handleImgChange(fileList)}
                        fileList={profileList}
                        accept=".jpg,.jpeg,.png"
                        maxCount={1}
                    >
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt="avatar"
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                }}
                            />
                        ) : (uploadButton)}
                    </Upload>
                </div>
            </Form.Item>
            <div className="pb-5 mx-3" style={{borderBottom: "1px solid #cbcbcb",textAlign:"center"}}>
                <p className="common_addTltTxt">{data?.name}</p>
                <p>{data?.designation ? data?.designation : '-'}</p>
            </div>
            <div className="py-4 mx-3" style={{borderBottom: "1px solid #cbcbcb"}}>
                <p id="common_weekoffTxt" style={{ textAlign: "left" }}>Basic Information</p>
                <p className="flex pb-2"><img className="pr-3" src={ImagePaths.contact.default}></img> {data?.email ? data?.email : '-'}</p>
                <p className="flex pb-2"><img className="pr-3" src={ImagePaths.call.default}></img>{data?.phone ? data?.phone : '-'}</p>
                <p className="flex pb-2"><img className="pr-3" src={ImagePaths.location.default}></img>{data?.location ? data?.location : '-'}</p>
            </div>
            <div className=" p-3" style={{borderBottom: "1px solid #cbcbcb"}}>
                <p id="common_weekoffTxt" style={{ textAlign: "left" }}>Professional Information</p>
                <div className="grid grid-cols-3 gap-y-2">
                    <div>
                        <p className="common_employee_nameTxt">{"Employee ID"}</p>
                        <p className="">{data?.employee_id ? data?.employee_id : '-'}</p>
                    </div>
                    <div>
                        <p className="common_employee_nameTxt">{"Employee Type"}</p>
                        <p className="">{data?.employee_type ? data?.employee_type : '-'}</p>
                    </div>
                    <div>
                        <p className="common_employee_nameTxt">{"Blood Group"}</p>
                        <p className="">{data?.blood ? data?.blood : '-'}</p>
                    </div>
                    <div>
                        <p className="common_employee_nameTxt">{"Department"}</p>
                        <p className="">{data?.department ? data?.department : '-'}</p>
                    </div>
                    <div>
                        <p className="common_employee_nameTxt">{"Sub-Department"}</p>
                        <p className="">{data?.sub_department ? data?.sub_department : '-'}</p>
                    </div>
                    <div>
                        <p className="common_employee_nameTxt">{"Reporting Manager"}</p>
                        <p className="">{data?.reporting_manager ? data?.reporting_manager : '-'}</p>
                    </div>
                    <div>
                        <p className="common_employee_nameTxt">{"Shift"}</p>
                        <p className="">{data?.shift ? data?.shift : '-'}</p>
                    </div>
                </div>
            </div>
            <div className="p-3">
                <p id="common_weekoffTxt" style={{ textAlign: "left" }}>Bank Information</p>
                <div className="grid grid-cols-3">
                <div>
                    <p className="common_employee_nameTxt">{"Bank Name"}</p>
                    <p className="">{data?.bank_name ? data?.bank_name : '-'}</p>
                </div>
                <div>
                    <p className="common_employee_nameTxt">{"Account Number"}</p>
                    <p className="">{data?.account_number ? data?.account_number : '-'}</p>
                </div>
                <div>
                    <p className="common_employee_nameTxt">{"IFSC Code"}</p>
                    <p className="">{data?.ifsc ? data?.ifsc : '-'}</p>
                </div>
                </div>
            </div>
            </Card>
            <Card style={{ height: "80dvh",width:"50%"}}>
            <Form
            layout="vertical"
            form={form}
            requiredMark={true}
            name="basicform"
            onFinish={handlesave}
            style={{ height: "100%" }}
            >
                <div className="common_add_tltTxt">Change Password</div>
                <Form.Item
                    style={styles.formItem}
                    name="current_password"
                    rules={[{required: true, validator: noBlankSpacesValidator}]}
                >
                    {renderInput('Current Password', "current_password", 'current_pass', 50, true)}
                </Form.Item>    
                <Form.Item
                    style={styles.formItem}
                    name="new_password"
                   rules={[{required: true, validator: passwordValidator}]}
                >
                    {renderInput('New Password', "new_password", '', 50, true)}
                </Form.Item>    
                <Form.Item
                    style={styles.formItem}
                    name="confirm_password"
                    rules={[{required: true, validator: passwordValidator}]}
                >
                    {renderInput('Confirm Password', "confirm_password", 'doc_name', 50, true)}
                </Form.Item>   
                <div className="flex mt-5" style={{marginLeft: "8dvw"}}>
                    <MyButton htmlType="submit" label={"Save Changes"}  paddingX={"0 1vw"} />
                </div>
            </Form>
            </Card>
            </div>
            {isNotifyPopup ? changePasswordNotify() : null}
        </Card>
    );
}

const styles = {
    textField: {
      width: "23vw"
    },
    formItem: { 
      margin: "1vw"
    },
    emptyFormItem: {
      width: "23vw",
      margin: "1vw"
    }
  }

export default EmployeeProfile