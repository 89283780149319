import React, { useEffect, useState, useRef } from "react";
import { Card, Table, Tooltip, Space } from "antd";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import AdminFilter from "../AdminFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import {
  getUserGroupPagination,
} from "hooks/api/adminApi/userGroupApi";
import { ADMIN_USER_GROUP } from "constants";
import AddUserGroup from "./AddUserGroup";
import {checkUserPermissionFunc, deleteDraftItemsById} from "components/common/CommonFuntion";
import { CommonDeletePopup } from 'components/common/CommonComponent';

const initialData = {
  user_group_name: "",
  is_active: "",
};

function UserGroup() {
  const authtoken = sessionStorage.getItem("token");
  let query = "";
  let queryRef = useRef("");
  const pageSize = 10;

  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [searchText, setSearchText] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sorting, setSorting] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const [isDraft, setIsDraft] = useState(false);
  const [deleteItem, setDeleteClicked] = useState({ id: undefined, isDeleteClicked: false });
  const tableContainerHeight = (addVisible || editVisible) ? "64vh" : isDraft ? "77.5vh" : "72vh";

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  const userPermission = checkUserPermissionFunc("administrator", "User Group", "SubMenu");

  const queryString =
  "user_group_name=" + (filterDatas.user_group_name ? filterDatas.user_group_name : "") +
  "&active_status=" + (filterDatas.is_active !== undefined ? filterDatas.is_active : "") +
  "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const [data, pageInfo, loading, _message, refetch] = useApiData(
    getUserGroupPagination,
    {
      pageNo,
      pageSize,
      queryString,
      searchText,
      sorting,
    }
  );

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg("error", _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  const addClick = () => {
    setAddVisible(true);
    setEditVisible(false);
    setSelectedDetails({});
  };

  const handleEditClick = (details) => {
    if (editVisible && Object.keys(selectedDetails).length > 0) {
      ToastMsg("warning", "Please cancel the existing edit operation!");
    } else {
      setEditVisible(true);
      setSelectedDetails(details);
      setAddVisible(false);
    }
  };

  const handleSave = () => {
    setEditVisible(false);
    setAddVisible(false);
    setSelectedDetails({});
    setIsDraft(false);
    refetch(pageNo, pageSize, query, searchText, sorting);
    setDataUpdated(!dataUpdated);
  };

  const handleCancel = () => {
    setEditVisible(false);
    setAddVisible(false);
    setSelectedDetails({});
  };
  
  // filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    refetch(
      pageNo,
      pageSize,
      `user_group_name=${
        data.user_group_name ? data.user_group_name : ""
      }&active_status=${data.is_active !== undefined ? data.is_active : ""}`,
      searchText,
      sorting
    );
    setFilterVisible(false);
  };

  const ClosePopUp = () => {
    setFilterVisible(false);
  };

  const clearFilter = () => {
    setFilterDatas(initialData);
    setPageNo(1);
    refetch(1, pageSize, query, searchText, sorting);
  };
  //filter end

  const handleSearch = () => {
    setEditVisible(false);
    setAddVisible(false);
    setSelectedDetails({});
    setPageNo(1);
    refetch(1, pageSize, queryString, searchText, sorting);
    setDataUpdated(!dataUpdated);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc": sorter.order === "ascend" ? "asc": ""}&`;
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}`: "";
    queryRef.current = isDraft ? `is_draft=True` : "";
    refetch(pagination.current, pageSize, queryRef.current, queryString , searchText, sortQuery);
    setSorting(sortQuery);
  };

  const columns = [
    {
      title: "User Group Code",
      dataIndex: "code",
      key: "code",
      sorter: true,
      width: "17.05%",
      fixed: "left",
      render: (_, record) =>
        record?.code?.length > 20 ? (
          <Tooltip title={record?.code}>
            {
              <span style={{ color: record?.is_active ? "black" : "#CACACA" }}>
                {record?.code?.slice(0, 20) + "..."}
              </span>
            }
          </Tooltip>
        ) : (
          <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>
            {record?.code ? record?.code : "-"}
          </p>
        ),
    },
    {
      title: "User Group Name",
      dataIndex: "user_group_name",
      key: "user_group_name",
      sorter: true,
      width: "17.05%",
      fixed: "left",
      render: (_, record) =>
        record?.user_group_name?.length > 25 ? (
          <Tooltip title={record?.user_group_name}>
            {
              <span style={{ color: record?.is_active ? "black" : "#CACACA" }}>
                {record?.user_group_name?.slice(0, 27) + "..."}
              </span>
            }
          </Tooltip>
        ) : (
          <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>
            {record?.user_group_name ? record?.user_group_name : "-"}
          </p>
        ),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      sorter: true,
      width: "22%",
      fixed: "left",
      render: (_, record) =>
        record?.notes?.length > 35 ? (
          <Tooltip title={record?.notes}>
            {
              <span style={{ color: record?.is_active ? "black" : "#CACACA" }}>
                {record?.notes?.slice(0, 38) + "..."}
              </span>
            }
          </Tooltip>
        ) : (
          <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>
            {record?.notes ? record?.notes : "-"}
          </p>
        ),
    },
    {
      title: "Status",
      key: "is_active",
      dataIndex: "is_active",
      sorter: true,
      width: "16.25%",
      render: (_, record) =>
        record.is_active === true ? (
          <span style={{ color: "black" }}>Active</span>
        ) : (
          <span style={{ color: "#CACACA" }}>Inactive</span>
        ),
      align: 'center',
      fixed: "left",
    },
  ];
  if (userPermission?.edit) {
    columns.push({
      title: "Action",
      key: "action",
      align: "center",
      width: "10.6%",
      fixed: "left",
      render: (_, record) => (
        <div className="flex justify-center items-center">
          <Space size={"middle"}>
            <IconButton
              title="Edit"
              icon={<img src={ImagePaths.edit.default} alt="Edit" />}
              onClick={() => (loading ? "" : handleEditClick(record))}
              testID={'ug_btn_edit'}
            />
            {record.is_draft === true ?
              <IconButton
                title="Delete"
                icon={<img src={ImagePaths.delete.default} alt="Delete" />}
                onClick={() => (loading ? "" : handleDeleteClicked(record?.id))}
                testID={'ug_btn_delete'}
              /> : null}
          </Space>
        </div>
      ),
    })
  }

  const handleDeleteClicked = (id) => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      id: id,
      isDeleteClicked: true
    }));
  }

  const handleCancelBtn = () => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      id: undefined,
      isDeleteClicked: false
    }));
  }

  const handleDeleteBtn = async (id) => {
    try {
      const apiData = await deleteDraftItemsById(id, ADMIN_USER_GROUP);
      if (apiData && apiData.status === "success") {
        ToastMsg("success", apiData.message);
        handleDraft();
      } else {
        ToastMsg("error", apiData.message);
      }
    } catch (error) {
      ToastMsg("error", error.message);
    } finally {
      handleCancelBtn();
    }
  }

  const showAddEdit = () => {
    return (
      <AddUserGroup
        editDetails={selectedDetails}
        onHide={handleCancel}
        onSave={handleSave}
        fromEdit={editVisible}
        isActive = {userPermission?.active !== undefined ? userPermission?.active : true}
      />
    );
  };

  const handleFilter = () => {
    setFilterVisible(true);
  };

  const handleDraft = () => {
    setIsDraft(true);
    setFilterVisible(false);
    setSorting("");
    setPageNo(1);
    queryRef.current = `is_draft=True`;
    refetch(1, pageSize, queryRef.current, queryString, searchText, "");
  };

  const handleBackToHome = () => {
    setIsDraft(false);
    setSorting("");
    setPageNo(1);
    queryRef.current = "";
    refetch(1, pageSize, queryRef.current, queryString, searchText, "");
  };

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search);
    setReorderedColumns(reorderedList);
  };


  const printdata =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&user_group_name=" + (filterDatas.user_group_name ? filterDatas.user_group_name : "") +
    "&active_status=" + (filterDatas.is_active !== undefined ? filterDatas.is_active : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"}>
        <CommonTblHeader
          pageName={"User Group"}
          addVisible={addVisible}
          editVisible={editVisible}
          addComponent={showAddEdit}
          pageInfo={pageInfo}
          handleSearch={handleSearch}
          selectedDetails={selectedDetails}
          addClick={addClick}
          onStateChange={handleStateChanges}
          handleFilter={handleFilter}
          columns={columns}
          handleDraft={handleDraft}
          isDraft={isDraft}
          handleBackToHome={handleBackToHome}
          url={ADMIN_USER_GROUP}
          query={printdata}
          isAddRequired = {userPermission?.add ? true : false}
          isDraftRequired = {userPermission?.draft ? true : false}
          testID={'ug_btn_add'}
        />
      </div>
      <div className="h-5/6 flex justify-center items-center mt-1">
        {filterVisible ? (
          <div className="w-1/4 mt-5" style={styles.fltContainer}>
            <AdminFilter
              pageName={"UserGroup"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter}
            />
          </div>
        ) : null}
        <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
        <Table
          columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
          dataSource={data?.length > 0 ? data : null}
          loading={loading}
          style = {{"--table-container-height": tableContainerHeight}}
          scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
          onChange={handleTableChange}
          pagination={{
            style: { marginRight: 20, padding: 0 },
            defaultPageSize: pageSize,
            showSizeChanger: false,
            showQuickJumper: true,
            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
            pageSizeOptions: ["10", "20", "30"],
            pageSize,
            defaultCurrent: pageNo,
          }}
          key={isDraft ? (dataUpdated ? "draftUpdated" : "draftNotUpdated") : (dataUpdated ? "updated" : "not-updated")}
        />
        </div>
      </div>
      {deleteItem.isDeleteClicked ? <CommonDeletePopup handleCancelBtn={handleCancelBtn} handleDeleteBtn={() => handleDeleteBtn(deleteItem.id)} /> : null}
    </Card>
  );
}

export default UserGroup;
