import { useEffect, useRef, useState } from "react";
import { Card, Dropdown, Modal, Space, Spin, Table, Tag, Tooltip } from "antd";
import { useNavigate } from 'react-router-dom';
import EmployeeFilter from "../EmployeeFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { CommonDeletePopup, EmployeeDetails } from "components/common/CommonComponent";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import moment from "moment";
import { getTransReportingManagerPagination } from "../Transfer Inbox/TransReportingManagerApi";
import { TRANSFER_REPORTING_MANGER } from "constants";

const initialData = {
    designation_name: "",
    department_name: "",
    reporting_manager: "",
    emplyee_group: "",
    is_active: "",
    designationList: [],
    departmentlist: [],
    reportingManagerList: [],
    employeeGroupList: []
}

function TransReportManager() {
    const query = "";
    let queryRef = useRef("");
    const pageSize = 10;
    const authtoken = sessionStorage.getItem("token");
    const is_super_admin = JSON.parse(sessionStorage.getItem("is_super_admin"));
    const is_admin = JSON.parse(sessionStorage.getItem("is_admin"));
    const is_reporting_manager = JSON.parse(sessionStorage.getItem("reportingManager"));
    const isEditAccess = (is_super_admin || is_admin || is_reporting_manager);

    const [pageNo, setPageNo] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [sorting, setSorting] = useState("");
    const [dataUpdated, setDataUpdated] = useState(false);
    const [filterVisible, setFilterVisible] = useState(false);
    const [filterDatas, setFilterDatas] = useState({ initialData });
    const [reorderedColumns, setReorderedColumns] = useState([]);
    const [approvalData, setApprovalData] = useState([]);
    const [revertDetails, setRevertDetails] = useState([]);
    const [isApprovalLog, setIsApprovalLog] = useState(false);
    const [RevertPopup, setRevertPopup] = useState(false);
    const [loadings, setLoadings] = useState(false);

    const [editVisible, setEditVisible] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState({});
    const [isCancelled, setIsCancelled] = useState(false);
    const [isDraft, setIsDraft] = useState(false);

    const tableContainerHeight = isDraft ? "77.5vh" : "72vh";
    const approvalContainerHeight = "50vh";
    const navigate = useNavigate();

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    const queryString =
        "date=" + (filterDatas.designation_name ? filterDatas.designation_name : "") +
        "&employee=" + (filterDatas.department_name ? filterDatas.department_name : "") +
        "&status=" + (filterDatas.is_active !== undefined ? filterDatas.is_active : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");

    const [data, pageInfo, loading, _message, refetch] = useApiData(getTransReportingManagerPagination, {
        pageNo,
        pageSize,
        queryString,
        searchText,
        sorting
    });

    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
    }, [_message.errorMsgText]);

    const addClick = () => {
        setSelectedDetails({});
        navigate('/employee/transferreportingmanager/add', { state: { fromEdit: false, editDetails: {} } });
    };

    const handleEditClick = (details) => {
        if (editVisible && Object.keys(selectedDetails).length > 0) {
            ToastMsg("warning", "Please cancel the existing edit operation!")
        }
        else if (!editVisible) {
            setEditVisible(true);
            navigate('/employee/transferreportingmanager/add', { state: { fromEdit: true, editDetails: details } });
            setSelectedDetails(details);
        }
    };

    const handleSearch = () => {
        setPageNo(1);
        refetch(1,
            pageSize,
            queryString,
            searchText,
            sorting);
        setDataUpdated(!dataUpdated);
    }

    const handleDraft = () => {
        setIsDraft(true);
        setFilterVisible(false);
        setSorting("");
        setPageNo(1);
        queryRef.current = `is_draft=True`
        refetch(1,
            pageSize,
            queryRef.current,
            queryString,
            searchText,
            "");
    }

    const handleBackToHome = () => {
        setIsDraft(false);
        setSorting("");
        setPageNo(1);
        queryRef.current = "";
        refetch(1, pageSize, queryRef.current, queryString, searchText, "");
    };

    const handleClosePopup = () => {
        setRevertPopup(false)
    }

    const handleCancel = () => {
        setIsApprovalLog(false);
    }

    const handleRevert = () => {
        const authtoken = sessionStorage.getItem("token")
        setLoadings(true)
        try {
            let updatedFormData = {
                id: revertDetails?.id ? revertDetails?.id : null,
                revert_status: true,
                employee: revertDetails?.employee_details?.id,
                from_date: revertDetails?.from_date ? revertDetails?.from_date.split("T")[0] : null,
                to_date: revertDetails?.to_date ? revertDetails?.to_date.split("T")[0] : null,
                comments: revertDetails?.comments,
                approval_inbox_permissions: revertDetails?.approval_inbox_permissions ? JSON.stringify(revertDetails?.approval_inbox_permissions) : []
            };
            if (isCancelled) {
                updatedFormData = { ...updatedFormData, employee_approval_status: 'cancelled', revert_status: false };
            }
            fetch(TRANSFER_REPORTING_MANGER, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${authtoken}`
                },
                body: JSON.stringify(updatedFormData)
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        refetch(1,
                            pageSize,
                            queryString,
                            searchText,
                            sorting);
                        setLoadings(false)
                        setRevertPopup(false)
                        setIsCancelled(false)
                    }
                    else if (data.status === "fail") {
                        setLoadings(false);
                        ToastMsg("error", data.message);
                    }
                })
                .catch(error => {
                    setLoadings(false);
                    ToastMsg("error", error.message);
                });
        } catch (error) {
            ToastMsg("error", error.message);
        }
    }

    const handleClick = (record, key) => {
        if (key == 1) {
            setApprovalData(record);
            setIsApprovalLog(true)
        } else if (key == 2 || key == 3) {
            setRevertDetails(record)
            if (key == 3) {
                setIsCancelled(true)
            }
            setRevertPopup(true)
        } else {
            navigate('/employee/transferreportingmanager/add', { state: { fromEdit: false, id: null, editDetails: {} } });
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
        const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
        refetch(pagination.current,
            pageSize,
            queryString,
            searchText,
            sortQuery);
        setSorting(sortQuery)
    }

    const renderFilter = (data) => {
        setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
        setPageNo(1);
        refetch(1,
            pageSize,
            `designation=${data.designation_name !== undefined ? data.designation_name : ""}&status=${data.is_active ? data.is_active : ""}&department=${data.department_name !== undefined ? data.department_name : ""}&reporting_manager=${data.reporting_manager !== undefined ? data.reporting_manager : ""}&employee_group=${data.emplyee_group !== undefined ? data.emplyee_group : ""}`,
            searchText,
            sorting);
        setFilterVisible(false);
    }

    const ClosePopUp = () => {
        setFilterVisible(false);
    }

    const clearFilter = (data) => {
        setFilterDatas((prevState) => ({
            ...prevState,
            ...initialData,
            designationList: data?.designationList?.length > 0 ? data?.designationList : [],
            departmentlist: data?.departmentlist?.length > 0 ? data?.departmentlist : [],
            reportingManagerList: data?.reportingManagerList?.length > 0 ? data?.reportingManagerList : [],
            employeeGroupList: data?.employeeGroupList?.length > 0 ? data?.employeeGroupList : []
        }));
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
    }

    const columns = [
        {
            title: "Date & Time",
            dataIndex: "date",
            key: "date",
            width: "17%",
            sorter: true,
            fixed: "left",
            render: (_, record) => (<p style={{ color: record.is_active ? "black" : "#CACACA" }}>{record?.date ? moment(record?.date, "YYYY-MM-DDTHH:mm:ss").format("DD-MM-YYYY | hh:mmA") : "-"}</p>)
        },
        {
            title: "Employee Details",
            dataIndex: "employee_details",
            key: "employee_details",
            width: "22%",
            sorter: true,
            fixed: "left",
            render: (_, record) => {
                let empDetails = {
                    ...record.employee_details,
                    "designation_id": record?.employee_details.designation ? { id: record?.employee_details.designation.id, designation_name: record?.employee_details.designation.name } : { id: 0, designation_name: "" },
                    "first_name": record?.employee_details.employee_name ? record?.employee_details.employee_name : "",
                    "id": record?.employee_details.employee_id
                }
                return (<EmployeeDetails details={empDetails} />)
            }
        },
        {
            title: "Comments",
            dataIndex: "employee_group_id",
            key: "employee_group_id__employee_group_name",
            width: "36%",
            sorter: true,
            fixed: "left",
            render: (_, record) => {
                let colors = record?.is_active ? "black" : "#CACACA";
                return (record?.comments?.length > 50 ?
                    <Tooltip title={record?.comments}>{<span style={{ color: colors }}>{record?.comments?.slice(0, 50) + "..."}</span>}</Tooltip>
                    : <p style={{ color: colors }}>{record?.comments ? record?.comments : "-"}</p>)
            }
        },
        {
            title: "Status",
            key: "is_active",
            dataIndex: "is_active",
            sorter: true,
            width: "15%",
            render: (_, record) => {
                let colors = record.status === "rejected" || record.status === "reverted" || record.status === "cancelled" ? "#D94854" : record.status === "pending" ? "#E28F12" : "#0E862A";
                return (<Tag className="statusDiv" style={{ width: "8vw" }} color={colors} key={record.status}>{record.status}</Tag>)
            },
            fixed: "left",
        },
    ];

    columns.push({
        title: "Action",
        key: "action",
        align: 'center',
        width: "10%",
        fixed: "left",
        render: (_, record) => {
            const items = [
                {
                    key: "1",
                    label: (<div className="empApproveTxt">Approval Log</div>)
                },
                {
                    key: "2",
                    label: (<div className="empApproveTxt">Revert</div>),
                    disabled: record.status !== "approved"
                },
                {
                    key: "3",
                    label: (<div className="empApproveTxt">Cancel</div>),
                    disabled: record.status === "approved" || record.status === "cancelled" || record.status === "reverted"
                },
            ];
            return (
                <div className="flex justify-center items-center">
                    <Space size={"middle"} style={{ display: "flex", alignItems: "center" }}>
                        <IconButton title="Edit" marginTop={true}
                            disabled={record.status !== "pending"}
                            icon={<img src={record.status === "pending" ? ImagePaths.edit.default : ImagePaths.editOff.default} alt="Edit" />}
                            onClick={() => loading ? "" : handleEditClick(record)} />
                        <Dropdown menu={{ items, onClick: ({ key }) => { handleClick(record, key) } }} trigger={['click']} placement="bottomLeft">
                            <IconButton title="" icon={<img src={ImagePaths.moreIcon.default} alt="more" />} />
                        </Dropdown>
                    </Space>
                </div>
            )
        }
    })

    const handleFilter = () => {
        setFilterVisible(true)
    }

    const handleStateChanges = (search = "", reorderedList = []) => {
        setSearchText(search)
        setReorderedColumns(reorderedList)
    }

    const column2 = [
        {
            title: "Created Date & Time",
            dataIndex: "created_date",
            key: "created_date",
            sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
            sortDirections: ["descend", "ascend"],
            width: "20%",
            render: (_, record) => (<p style={{ color: "black" }}>{record?.created_date ? moment(record?.created_date, "YYYY-MM-DDTHH:mm:ss").format("DD-MM-YYYY & hh:mmA") : "-"}</p>)
        },
        {
            title: "Modified Date & Time",
            dataIndex: "modified_date",
            key: "modified_date",
            sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
            sortDirections: ["descend", "ascend"],
            width: "20%",
            render: (_, record) => (<p style={{ color: "black" }}>{record?.modified_date ? moment(record?.modified_date, "YYYY-MM-DDTHH:mm:ss").format("DD-MM-YYYY & hh:mmA") : "-"}</p>)
        },
        {
            title: "Created By",
            dataIndex: "created_by",
            key: "created_by",
            sorter: (a, b) => a.created_by.length - b.created_by.length,
            sortDirections: ["descend", "ascend"],
            width: "15%",
            render: (_, record) =>
            (record?.created_by?.length > 25 ?
                <Tooltip title={record?.created_by}>{<span style={{ color: "black" }}>{record?.created_by?.slice(0, 25) + "..."}</span>}</Tooltip>
                : <p style={{ color: "black" }}>{record?.created_by ? record?.created_by : "-"}</p>)
        },
        {
            title: "Status",
            dataIndex: "employee_approval_status",
            key: "employee_approval_status",
            sorter: (a, b) => a.employee_approval_status.length - b.employee_approval_status.length,
            sortDirections: ["descend", "ascend"],
            width: "15%",
            render: (_, record) =>
            (record?.employee_approval_status?.length > 20 ?
                <Tooltip title={record?.employee_approval_status}>{<span style={{ color: "black", textTransform: "capitalize" }}>{record?.employee_approval_status?.slice(0, 20) + "..."}</span>}</Tooltip>
                : <p style={{ color: "black", textTransform: "capitalize" }}>{record?.employee_approval_status ? record?.employee_approval_status : "-"}</p>)
        },
        {
            title: "Permission",
            dataIndex: "approval_inbox_permissions",
            key: "approval_inbox_permissions",
            sorter: (a, b) => {
                const aTrueCount = Object.values(a.approval_inbox_permissions || {}).filter(Boolean).length;
                const bTrueCount = Object.values(b.approval_inbox_permissions || {}).filter(Boolean).length;
                return aTrueCount - bTrueCount;
            },
            sortDirections: ["descend", "ascend"],
            width: "15%",
            render: (_, record) => {
                const approvalStatus = record?.approval_inbox_permissions;
                if (approvalStatus) {
                    const trueKeys = Object.keys(approvalStatus)
                        .filter((key) => approvalStatus[key])
                        .map((key) =>
                            key
                                .replace(/_/g, " ")
                                .replace(/\b\w/g, (char) => char.toUpperCase())
                        );
                    return trueKeys.length > 0 ? (
                        <Tooltip title={trueKeys.join(", ")}>
                            <span style={{ color: "black", textTransform: "capitalize" }}>
                                {trueKeys.join(", ")}
                            </span>
                        </Tooltip>
                    ) : (
                        <p style={{ color: "black", textTransform: "capitalize" }}>-</p>
                    );
                }
                return <p style={{ color: "black", textTransform: "capitalize" }}>-</p>;
            },
        },
        {
            title: "Comments",
            key: "comments",
            dataIndex: "comments",
            sorter: (a, b) => a.comments.length - b.comments.length,
            sortDirections: ["descend", "ascend"],
            width: "15%",
            render: (_, record) =>
            (record?.comments?.length > 28 ?
                <Tooltip title={record?.comments}>{<span style={{ color: "black" }}>{record?.comments?.slice(0, 25) + "..."}</span>}</Tooltip>
                : <p style={{ color: "black" }}>{record?.comments ? record?.comments : "-"}</p>)
        },
    ];

    const ApprovalLogPopUp = () => {
        return (
            <Modal title=""
                centered
                open={isApprovalLog}
                onCancel={handleCancel}
                footer={null}
                width={"65vw"}
                className="custom-modal"
            >
                <div style={{ width: "100%" }}>
                    <p className='common_add_tltTxt mb-5'>Approval Log</p>

                    <div className={"my-5 w-full"} style={styles.Container} >
                        <Table
                            columns={column2}
                            dataSource={approvalData?.approval_logs?.length > 0 ? approvalData?.approval_logs : null}
                            style={{ "--table-container-height": approvalContainerHeight, "--table-container-footer": "130px" }}
                            scroll={{ y: approvalData?.approval_logs?.length > 0 ? `calc(${approvalContainerHeight} - 130px)` : null }}
                            pagination={{
                                style: { marginRight: 20, padding: 0 },
                                showSizeChanger: false,
                                showQuickJumper: true,
                            }}
                        />
                    </div>
                </div>
            </Modal>
        )
    }


    const printdata =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&designation=" + (filterDatas.designation_name ? filterDatas.designation_name : "") +
        "&department=" + (filterDatas.department_name ? filterDatas.department_name : "") +
        "&reporting_manager=" + (filterDatas.reporting_manager ? filterDatas.reporting_manager : "") +
        "&employee_group=" + (filterDatas.emplyee_group ? filterDatas.emplyee_group : "") +
        "&status=" + (filterDatas.is_active !== undefined ? filterDatas.is_active : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");

    const styles = {
        fltContainer: {
            border: "1px solid #cbcbcb",
            height: tableContainerHeight,
        },
        Container: {
            border: "1px solid #cbcbcb",
            height: approvalContainerHeight,
        }
    }

    return (
        <Card className="h-full overflow-hidden">
            {loadings && (
                <div className="loaderOverlay">
                    <Spin />
                </div>
            )}
            <div className={"h-1/6"} >
                <CommonTblHeader pageName={"Transfer Reporting Manager"}
                    pageInfo={pageInfo} selectedDetails={selectedDetails}
                    handleSearch={handleSearch} addClick={addClick}
                    onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
                    url={TRANSFER_REPORTING_MANGER} query={printdata} handleBackToHome={handleBackToHome}
                    isAddRequired={isEditAccess ? true : false} isDraft={isDraft}
                    isDraftRequired={true} editVisible={editVisible} handleDraft={handleDraft}
                />
            </div>
            <div className="h-5/6 flex justify-center items-center  mt-1">
                {filterVisible ?
                    <div className="w-1/4 mt-5" style={styles.fltContainer}>
                        <EmployeeFilter
                            pageName={"Employees"}
                            filterDatas={filterDatas}
                            closeFilter={ClosePopUp}
                            filteredData={renderFilter}
                            clearData={clearFilter} />
                    </div>
                    : null}
                <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
                    <Table
                        columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
                        dataSource={data?.length > 0 ? data : null}
                        loading={loading}
                        style={{ "--table-container-height": tableContainerHeight }}
                        scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
                        onChange={handleTableChange}
                        pagination={{
                            style: { marginRight: 20, padding: 0 },
                            defaultPageSize: pageSize,
                            showSizeChanger: false,
                            showQuickJumper: true,
                            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                            pageSizeOptions: ["10", "20", "30"],
                            pageSize,
                            defaultCurrent: pageNo,
                        }}
                        key={dataUpdated ? "updated" : "not-updated"}
                    />
                </div>
            </div>
            {/* {isApprovalLog ? <ApprovalLogPopUp TblData={approvalData} onClose={handleApprovalLogCls} fromReportingManager={true} /> : null} */}
            {isApprovalLog ? ApprovalLogPopUp() : null}
            {RevertPopup ?
                <CommonDeletePopup
                    handleCancelBtn={handleClosePopup}
                    handleDeleteBtn={() => handleRevert()}
                    sentence={`Are You Sure ? Do you want to ${isCancelled ? 'Cancel' : 'Revert'} this data?`}
                    okBtnLable={`${isCancelled ? 'Ok' : 'Revert'}`}
                /> : null}
        </Card>
    );
}
export default TransReportManager;