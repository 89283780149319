import React, { useEffect, useState, useRef, useCallback } from "react";
import { Form, Input, Modal, DatePicker, Select, Tooltip, Card } from "antd";
import MyButton from "components/ui/Button/MyButton";
import ToastMsg from "components/common/ToastMsg";
import { disabledPastDate, handleOnKeyNumeric } from "components/common/validation";
import { EMPLOYEE_RESIGNATION_APPROVAL } from "constants";
import dayjs from 'dayjs';
import { useNavigate, useLocation } from "react-router-dom";
import { ImagePaths } from "utils/ImagePath";
import locale from 'antd/es/date-picker/locale/en_US';
import { noBlankSpacesValidator } from 'components/common/validation';

function AddEditResignationApproval() {
  const authtoken = sessionStorage.getItem("token");
  const companyid = sessionStorage.getItem("userId");
  const prevPropsRef = useRef();
  const dateFormat = "YYYY-MM-DD";

  const [formData, setFormData] = useState({
    employee_name: "",
    employee_id: "",
    department: "",
    designation: "",
    request_date: undefined,
    reason: "",
    approved_by: "",
    approval_date: undefined,
    notice_period: null,
    last_working_day: undefined,
    approval_comments: "",
    final_approved_by: "",
    final_approval_date: undefined,
    final_notice_period: null,
    final_last_working_day: undefined,
    final_approval_comments: "",
    level: "",
    fin_satge: false,
    approverlist: [],
    final_approverlist: [],
  });
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const { editDetails } = location.state || {};

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);
  useEffect(() => {
    let app = []
    app.push(editDetails?.approved_by);
    let finalapprover = []
    finalapprover.push(editDetails?.final_approved_by);
    let { request_from } = editDetails;
    setFormData((prevFormData) => ({
      ...prevFormData,
      id: editDetails?.id,
      employee_name: request_from?.employee_name,
      employee_id: request_from?.employee_id,
      designation: request_from?.designation?.name,
      department: request_from?.department?.name,
      request_date: editDetails?.request_date ? dayjs(editDetails?.request_date, dateFormat) : undefined,
      reason: editDetails?.reason ? editDetails?.reason : "",
      approved_by: editDetails?.approved_by?.id ? editDetails?.approved_by?.id : '',
      approval_date: (editDetails?.approval_date && editDetails.approval_date !== "NaT") ? dayjs(editDetails?.approval_date, dateFormat) : dayjs(),
      notice_period: (editDetails?.notice_period || editDetails?.notice_period == 0) ? editDetails?.notice_period : null,
      last_working_day: (editDetails?.last_working_day && editDetails?.last_working_day !== "NAT") ? dayjs(editDetails?.last_working_day, dateFormat) : undefined,
      approval_comments: editDetails?.approval_comments ? editDetails?.approval_comments : '',
      final_approved_by: editDetails?.final_approved_by?.id ? editDetails?.final_approved_by?.id : '',
      final_approval_date: editDetails?.final_approval_date ? dayjs(editDetails?.final_approval_date, dateFormat) : dayjs(),
      fin_satge: editDetails?.level == "2" ? true : false,
      approverlist: app,
      final_approverlist: finalapprover,
      level: editDetails?.level,
    }));
    form.setFieldsValue({
      employee_name: editDetails?.employee_name,
      employee_id: editDetails?.employee_id,
      request_date: editDetails?.request_date ? dayjs(editDetails?.request_date, dateFormat) : undefined,
      reason: editDetails?.reason ? editDetails?.reason : "",
      approved_by: editDetails?.approved_by?.id ? editDetails?.approved_by?.id : '',
      approval_date: (editDetails?.approval_date && editDetails.approval_date !== "NaT") ? dayjs(editDetails?.approval_date, dateFormat) : dayjs(),
      notice_period: (editDetails?.notice_period || editDetails?.notice_period == 0) ? editDetails?.notice_period : null,
      last_working_day: (editDetails?.last_working_day && editDetails?.last_working_day !== "NAT") ? dayjs(editDetails?.last_working_day, dateFormat) : undefined,
      approval_comments: editDetails?.approval_comments ? editDetails?.approval_comments : '',
      final_approved_by: editDetails?.final_approved_by?.id ? editDetails?.final_approved_by?.id : '',
      final_approval_date: editDetails?.final_approval_date ? dayjs(editDetails?.final_approval_date, dateFormat) : dayjs(),
      // final_notice_period: editDetails?.final_notice_period ? editDetails?.final_notice_period : '',
      // final_last_working_day: editDetails?.final_last_working_day ? dayjs(editDetails?.final_last_working_day, dateFormat) : undefined,
      // final_approval_comments: editDetails?.final_approval_comments ? editDetails?.approval_comments : '',
      fin_satge: editDetails?.level == "2" ? true : false,
      approverlist: editDetails?.approved_by ? editDetails?.approved_by : [],
      final_approverlist: editDetails?.final_approved_by ? editDetails?.final_approved_by : [],
      level: editDetails?.level,
    });
    prevPropsRef.current = editDetails;
  }, [editDetails])
  const handleBackCancel = useCallback(() => {
    form.resetFields();
    navigate('/employee/approvalinbox', { state: { selectedInboxVal: "Resignation" } });
  }, [navigate, form])
  const handleSubmit = useCallback((isRejectClked) => {
    const authtoken = sessionStorage.getItem("token")
    try {
      setLoading(true)
      let updatedFormData = { ...formData };
      updatedFormData.request_date = dayjs(updatedFormData.request_date).format(dateFormat);
      updatedFormData.approval_date = updatedFormData.approval_date ? dayjs(updatedFormData.approval_date).format(dateFormat) : undefined;
      updatedFormData.last_working_day = updatedFormData.last_working_day ? dayjs(updatedFormData.last_working_day).format(dateFormat) : null;
      updatedFormData.final_last_working_day = updatedFormData.final_last_working_day ? dayjs(updatedFormData.final_last_working_day).format(dateFormat) : undefined;
      updatedFormData.final_approval_date = updatedFormData.final_approval_date ? dayjs(updatedFormData.final_approval_date).format(dateFormat) : undefined;
      if (isRejectClked) {
        updatedFormData = { ...updatedFormData, approval_status: 'rejected' };
      } else {
        updatedFormData = { ...updatedFormData, approval_status: 'approved' };
      }
      fetch(EMPLOYEE_RESIGNATION_APPROVAL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${authtoken}`
        },
        body: JSON.stringify(updatedFormData)
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            setLoading(false);
            setFormData({
              employee_name: "",
              employee_id: "",
              department: "",
              designation: "",
              request_date: undefined,
              reason: "",
              approved_by: "",
              approval_date: undefined,
              notice_period: null,
              last_working_day: undefined,
              approval_comments: "",
              final_approved_by: "",
              final_approval_date: undefined,
              final_notice_period: null,
              final_last_working_day: undefined,
              final_approval_comments: "",
              level: "",
              fin_satge: false,
              approverlist: [],
              final_approverlist: [],
            })
            navigate('/employee/approvalinbox');
          }
          else if (data.status === "fail") {
            setLoading(false);
            ToastMsg("error", data.message);
          }
        })
        .catch(error => {
          setLoading(false);
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    }
  }, [formData]);

  const handleDatePickerChange = (date, name) => {
    const currentDate = dayjs();
    let noday = date.diff(currentDate, 'day')
    if (!date.isSame(currentDate, 'day')) {
      noday += 1
    }
    if (name === "last_working_day") {
      setFormData({
        ...formData,
        notice_period: noday,
        [name]: dayjs(date).format(dateFormat),
      });
      form.setFieldsValue({
        ...formData,
        notice_period: noday,
        [name]: dayjs(date).format(dateFormat),
      });
    }
    else if (name === "final_last_working_day") {
      setFormData({
        ...formData,
        final_notice_period: noday,
        [name]: dayjs(date).format(dateFormat),
      });
      form.setFieldsValue({
        ...formData,
        final_notice_period: noday,
        [name]: dayjs(date).format(dateFormat),
      });
    }
    else {
      setFormData({
        ...formData,
        [name]: dayjs(date).format(dateFormat),
      });
    }


  };

  const renderInput = (statekey, maxLength = 150, isReadOnly = false) => {
    return (
      <Input
        autoComplete='off'
        type={"text"}
        style={styles.textField}
        name={statekey}
        value={formData[statekey]}
        maxLength={maxLength}
        disabled={isReadOnly}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        data-testid={statekey}
      />
    )
  }

  const renderDatePicker = (statekey, isReadOnly = false) => {
    return (<DatePicker
      allowClear={false}
      inputReadOnly={true}
      locale={locale}
      value={formData[statekey] ? dayjs(formData[statekey], dateFormat) : statekey == "last_working_day" ? null : statekey == "final_last_working_day" ? null : dayjs()}
      format={"DD-MM-YYYY"}
      style={styles.textField}
      disabled={isReadOnly}
      onChange={(date) => handleDatePickerChange(date, statekey,)}
      placeholder=""
      disabledDate={disabledPastDate}
    />)
  }

  const handleChange = (name, value) => {
    if (name === "notice_period") {
      const updatedDate = dayjs().add(value, 'day').format(dateFormat);
      setFormData({
        ...formData,
        last_working_day: updatedDate,
        [name]: value,
      });
      form.setFieldsValue({
        ...formData,
        last_working_day: updatedDate,
        [name]: value,
      });
    }
    else if (name === "final_notice_period") {
      const updatedDate = dayjs().add(value, 'day').format(dateFormat);
      setFormData({
        ...formData,
        final_last_working_day: updatedDate,
        [name]: value,
      });
      form.setFieldsValue({
        ...formData,
        final_last_working_day: updatedDate,
        [name]: value,
      });
    }
    else {
      setFormData({
        ...formData,
        [name]: value,
      });
      form.setFieldsValue({
        ...formData,
        [name]: value,
      });
      form.validateFields([[name]]);
    }

  };

  const handleSave = useCallback(() => {
    handleSubmit(false);
  }, [handleSubmit]);

  const handleReject = useCallback(() => {
    handleSubmit(true);
  }, [handleSubmit]);




  return (
    <Card className='h-full'>
      <div className='h-1/6 flex justify-between items-center p-1 ' style={{ borderBottom: "1px solid #D6D6D6" }}>
        <div className='flex items-center'>
          <Tooltip title={"Back"} placement="bottom">
            <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
          </Tooltip>
          <p className='common_addTltTxt'>{"Resignation Request"}</p>
        </div>
      </div>
      <div className='h-5/6' style={{ margin: "16px 0px", overflowY: "scroll", height: "78dvh" }}>
        <Form
          layout="inline"
          form={form}
          colon={false}
          onFinish={handleSave}
          onSubmit={(e) => e.preventDefault()}
          requiredMark={false}
          className='w-full'
          name="basicform"
          initialValues={formData}
          data-testid='rg_form'
        >

          <div className="grid gap-4 grid-cols-3">
            <Form.Item name="employee_name">
              <div className='commonInputTlt'>Employee Name</div>
              {renderInput("employee_name", 150, true)}
            </Form.Item>
            <Form.Item name="employee_id">
              <div className='commonInputTlt'>Employee Id</div>
              {renderInput("employee_id", 150, true)}
            </Form.Item>
            <Form.Item name="department">
              <div className='commonInputTlt'>Department</div>
              {renderInput("department", 150, true)}
            </Form.Item>
          </div>
          <div className="grid gap-4 grid-cols-3 mt-5">
            <Form.Item name="designation">
              <div className='commonInputTlt'>Designation</div>
              {renderInput("designation", 150, true)}
            </Form.Item>
            <Form.Item name="request_date">
              <div className='commonInputTlt'>Requested Date</div>
              {renderDatePicker("request_date", true)}
            </Form.Item>
          </div>
          <div className='w-full grid my-5'>
            <Form.Item
              name="reason"
            >
              <div className='commonInputTlt'>Reason For Resignation<span className='requiredTxt'>*</span></div>
              <Input.TextArea
                bordered="true"
                className="mt-2 textarea"
                rows={3}
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
                maxLength={150}
                value={formData.reason ? formData.reason : ""}
                placeholder="Reason Fro Resignation"
                name="reason"
                disabled={true}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                data-testid={'rg_reason'}
              />
            </Form.Item>
          </div>
          <div className='tbl_headertxt'>Approver Details</div>
          <div className="grid gap-4 grid-cols-3">
            <Form.Item name="approved_by">
              <div className='commonInputTlt'>Approver</div>
              <Select
                style={styles.textField}
                value={formData.approved_by}
                name={"approved_by"}
                onChange={(value) => handleChange("approved_by", value)}
                data-testid={'rg_approver'}
                disabled={false}
              >
                {formData.approverlist.map((option) => (
                  <Select.Option key={option.id} value={option.id}>{option.employee_name}</Select.Option>
                ))}
              </Select>
        </Form.Item>
        <Form.Item name="approval_date">
            <div className='commonInputTlt'>Approval Date</div>
            {renderDatePicker("approval_date", true)}
        </Form.Item>
        <Form.Item 
          name="notice_period"
          rules={[{ required: true, message: "This field is required" }]}>
            <div className='commonInputTlt'>Notice Period <span className="requiredTxt">*</span></div>
            <Input
            autoComplete='off'
            type={"text"}
            style={styles.textField}
            name={"notice_period"}
            value={formData.notice_period}
            maxLength={3}
            onKeyDown={handleOnKeyNumeric}
            disabled={formData.fin_satge ? true : false}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            data-testid={"notice_period"}
        />
        </Form.Item>
        </div>
        <div className="grid gap-4 grid-cols-3 mt-5">
        <Form.Item 
          name="last_working_day"
          rules={[{ required: true, message: "This field is required" }]}>
            <div className='commonInputTlt'>Last Working Day <span className="requiredTxt">*</span></div>
            {renderDatePicker("last_working_day", formData.fin_satge)}
        </Form.Item>
        </div>
        <div className='w-full grid my-5'>
        <Form.Item
            name="approval_comments"
            rules={[{ required: true, message: "This field is required", validator:noBlankSpacesValidator}]}
          >
        <div className='commonInputTlt'>Comments<span className='requiredTxt'>*</span></div>
          <Input.TextArea
              bordered="true"
              className="mt-2 textarea"
              rows={3}
              autoSize={{
                minRows: 3,
                maxRows: 5,
              }}
              maxLength = {150}
              value={formData.approval_comments ? formData.approval_comments : ""}
              placeholder="Comments"
              name="approval_comments"
              disabled={ formData.fin_satge }
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              data-testid={'rg_app_commments'}
            />
            </Form.Item>
          </div>
          {formData.fin_satge ?
            <div>
              <div className='tbl_headertxt'>Final Approver Details</div>
              <div className="grid gap-4 grid-cols-3">
                <Form.Item name="final_approved_by">
                  <div className='commonInputTlt'>Final Approver</div>
                  <Select
                    style={styles.textField}
                    value={formData.final_approved_by}
                    name={"final_approved_by"}
                    onChange={(value) => handleChange("final_approved_by", value)}
                    data-testid={'rg_fin_approver'}
                    disabled={false}
                  >
                    {formData.final_approverlist.map((option) => (
                      <Select.Option key={option.id} value={option.id}>{option.employee_name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="final_approval_date">
                  <div className='commonInputTlt'>Final Approval Date</div>
                  {renderDatePicker("final_approval_date", true)}
                </Form.Item>
                <Form.Item
                  name="final_notice_period"
                  rules={[{ required: true, message: "This field is required" }]} >
                  <div className='commonInputTlt'>Notice Period <span className="requiredTxt">*</span></div>
                  <Input
                    autoComplete='off'
                    type={"text"}
                    style={styles.textField}
                    name={"final_notice_period"}
                    value={formData.final_notice_period}
                    maxLength={3}
                    onKeyDown={handleOnKeyNumeric}
                    disabled={false}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    data-testid={"final_notice_period"}
                  />
                </Form.Item>
              </div>
              <div className="grid gap-4 grid-cols-3 mt-5">
                <Form.Item
                  name="final_last_working_day"
                  rules={[{ required: true, message: "This field is required" }]}>
                  <div className='commonInputTlt'>Last Working Day <span className="requiredTxt">*</span></div>
                  {renderDatePicker("final_last_working_day")}
                </Form.Item>
              </div>
              <div className='w-full grid my-5'>
                <Form.Item
                  name="final_approval_comments"
                  rules={[{ required: true, message: "This field is required", validator: noBlankSpacesValidator }]}
                >
                  <div className='commonInputTlt'>Final Comments<span className='requiredTxt'>*</span></div>
                  <Input.TextArea
                    bordered="true"
                    className="mt-2 textarea"
                    rows={3}
                    autoSize={{
                      minRows: 3,
                      maxRows: 5,
                    }}
                    maxLength={150}
                    value={formData.final_approval_comments ? formData.final_approval_comments : ""}
                    placeholder="Comments"
                    name="final_approval_comments"
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    data-testid={'rg_finapp_commments'}
                  />
                </Form.Item>
              </div></div>
            : null}

          <Form.Item>
            <div className='flex justify-end items-end'>
              <MyButton htmlType="button" label={"Reject"} onClick={handleReject} bgColor={"#D94854"} loading={loading} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID='asgreg_btn_draft' />
              <MyButton htmlType="submit" label={"Approve"} loading={loading} paddingX={"0 1.2vw"} bgColor={"#0E862A"} />
            </div>
          </Form.Item>
        </Form>
      </div>
    </Card>
  );
}
const styles = {
  textField: {
    width: "23vw"
  },
  formItem: {
    margin: "1vw"
  },
  emptyFormItem: {
    width: "23vw",
    margin: "1vw"
  }
}

export default AddEditResignationApproval;

