import { EMPLOYEE_DOC } from "constants";
import { EMPLOYEE_USER_PERMISSION } from "constants";
import { TRANSFER_INBOX } from "constants";
import { TRANSFER_REPORTING_MANGER } from "constants";
import { prefillUrl } from "utils/StringUtils";

export const getTransReportingManagerPagination = async ({ pageNo, pageSize, query, search, sorting }) => {
  const authtoken = sessionStorage.getItem("token");
  let url = TRANSFER_REPORTING_MANGER;

  if (pageNo) {
    url = prefillUrl(url);
    url = `${url}page_no=${pageNo}`;
  }

  if (pageSize) {
    url = prefillUrl(url);
    url = `${url}&page_size=${pageSize}`;
  }

  if (query) {
    url = prefillUrl(url);
    url = `${url}&${query}`;
  }

  if (search) {
    url = prefillUrl(url);
    url = `${url}&search=${search}`;
  }

  if (sorting) {
    url = prefillUrl(url);
    url = `${url}&${sorting}`;
  }
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `token ${authtoken}` },
  };

  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};

export const getTransferInboxPagination = async ({ pageNo, pageSize, query, search, sorting }) => {
  const authtoken = sessionStorage.getItem("token");
  let url = TRANSFER_INBOX;

  if (pageNo) {
    url = prefillUrl(url);
    url = `${url}page_no=${pageNo}`;
  }

  if (pageSize) {
    url = prefillUrl(url);
    url = `${url}&page_size=${pageSize}`;
  }

  if (query) {
    url = prefillUrl(url);
    url = `${url}&${query}`;
  }

  if (search) {
    url = prefillUrl(url);
    url = `${url}&search=${search}`;
  }

  if (sorting) {
    url = prefillUrl(url);
    url = `${url}&${sorting}`;
  }
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `token ${authtoken}` },
  };

  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};

export const getUserpermissionById = async (id) => {
  const authtoken = sessionStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `token ${authtoken}` },
  };

  return await fetch(EMPLOYEE_USER_PERMISSION + `?employee=${id}`, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};