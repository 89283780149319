import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MyButton from 'components/ui/Button/MyButton';
import { Card, Checkbox, Input, Collapse, Tooltip } from 'antd'
import { ADMIN_USER_PERMISSION } from 'constants';
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";

const { Panel } = Collapse;
const permissionList = [
    {
        key: 1, label: 'Home',
        icon: ImagePaths.home.default, permission: true,
        active: false,
        edit: false,
        add: false,
        draft: false,
    },
    // {
    //     key: 2, label: 'Dashboard',
    //     icon: ImagePaths.dashboard.default, permission: false,
    //     active: false,
    //     edit: false,
    //     add: false,
    //     draft: false,
    // }, on hold
    {
        key: 3,
        label: 'Admin',
        icon: ImagePaths.admin.default, permission: false,
        subMenu: [
            { key: 3.1, label: 'Organization', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 3.2, label: 'User Group', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 3.3, label: 'User Permission', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 3.4, label: 'Notice Board', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 3.5, label: 'Password Policy', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 3.6, label: 'Organization Documents', permission: false, active: false, edit: false, add: false, draft: false },
        ],
    },
    {
        key: 4,
        label: 'Master',
        icon: ImagePaths.master.default, permission: false,
        subMenu: [
            { key: 4.1, label: 'Employee Type', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 4.2, label: 'Department', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 4.3, label: 'Designation', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 4.4, label: 'Leave Type', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 4.5, label: 'Employee Group', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 4.6, label: 'Reimbursements Type', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 4.7, label: 'Asset Type', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 4.8, label: 'Asset Category', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 4.9, label: 'Asset Sub Category', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 4.11, label: 'Letter Type', permission: false, active: false, edit: false, add: false, draft: false },
        ],
    },
    {
        key: 5,
        label: 'Configuration',
        icon: ImagePaths.configuration.default, permission: false,
        subMenu: [
            { key: 5.01, label: 'Shift Configuration', permission: false, active: false, edit: false, add: false, draft: false },
            // { key: 5.02, label: 'Salary Deduction', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 5.03, label: 'Holiday', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 5.04, label: 'Week off', permission: false, active: false, edit: false, add: false, draft: false },
            // { key: 5.05, label: 'Certificate', permission: false, active: false, edit: false, add: false, draft: false }, on hold
            // { key: 5.06, label: 'Approval Hierarchy', permission: false, active: false, edit: false, add: false, draft: false }, on hold
            { key: 5.07, label: 'Payroll Period', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 5.08, label: 'Subordinate', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 5.09, label: 'Custom Configuration', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 5.10, label: 'Salary Component', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 5.11, label: 'Salary Template', permission: false, active: false, edit: false, add: false, draft: false },
        ],
    },
    // {
    //     key: 6,
    //     label: 'Employee',
    //     icon: ImagePaths.employee.default, permission: false,
    //     subMenu: [
    //         { key: 6.1, label: 'Employees', permission: false, active: false, edit: false, add: false, draft: false },
    //         { key: 6.2, label: 'Leave Request', permission: false, active: false, edit: false, add: false, draft: false },
    //         { key: 6.3, label: 'Reimbursements Request', permission: false, active: false, edit: false, add: false, draft: false },
    //         { key: 6.4, label: 'Advance Salary Request', permission: false, active: false, edit: false, add: false, draft: false },

    //     ],
    // },
    {
        key: 6,
        label: 'Employee',
        icon: ImagePaths.employee.default, permission: false,
        subMenu: [
            { key: 6.5, label: 'Approval Inbox', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 6.6, label: 'TDS Declaration', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 6.7, label: 'Employee Onboarding', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 6.8, label: 'Transfer Reporting Manager', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 6.9, label: 'Transfer Inbox', permission: false, active: false, edit: false, add: false, draft: false },
        ],
    },
    {
        key: 7,
        label: 'Payroll',
        icon: ImagePaths.payroll.default, permission: false,
        subMenu: [
            { key: 7.1, label: 'Run Payroll', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 7.2, label: 'Payroll', permission: false, active: false, edit: false, add: false, draft: false },
            // { key: 7.3, label: 'Approval Inbox', permission: false, active: false, edit: false, add: false, draft: false }, //as per discussion
            { key: 7.4, label: 'Payslips', permission: false, active: false, edit: false, add: false, draft: false },
        ],
    },
    // {
    //     key: 8,
    //     label: 'Attendance',
    //     icon: ImagePaths.attendance.default, permission: false,
    //     subMenu: [
    //         { key: 8.1, label: 'Day wise Attendance', permission: false, active: false, edit: false, add: false, draft: false },
    //         { key: 8.2, label: 'Attendance Log', permission: false, active: false, edit: false, add: false, draft: false },
    //         // { key: 8.3, label: 'Timesheet', permission: false, active: false, edit: false, add: false, draft: false }, on hold
    //     ],
    // },
    {
        key: 9,
        label: 'Asset Management',
        icon: ImagePaths.assetManagement.default, permission: false,
        subMenu: [
            { key: 9.1, label: 'Asset Registry', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 9.2, label: 'Asset Employee Audit', permission: false, active: false, edit: false, add: false, draft: false },
        ]
    },
    {
        key: 10,
        label: 'Roster',
        icon: ImagePaths.home.default,
        permission: false,
        active: false,
        edit: false,
        add: false,
        draft: false,
    },
    {
        key: 11,
        label: 'Reports',
        icon: ImagePaths.assetManagement.default, permission: false,
        subMenu: [
            { key: 10.01, label: 'Day wise Attendance', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 10.02, label: 'Month Wise Attendance', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 10.03, label: 'Salary Process Bank', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 10.04, label: 'Employee Salary Summary', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 10.05, label: 'Payroll Detailed Summary', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 10.06, label: 'Employee ESI Statement', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 10.07, label: 'Employee PF Statement', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 10.08, label: 'Muster Roll', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 10.09, label: 'Employee Earning Deduction', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 10.10, label: 'Attendance Correction', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 10.11, label: 'Subordinates', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 10.12, label: 'Roster Report', permission: false, active: false, edit: false, add: false, draft: false },
            { key: 10.13, label: 'Leave Permission Report', permission: false, active: false, edit: false, add: false, draft: false },
        ]
    },
];

const EditUserPermission = () => {
    const [formData, setFormData] = useState({
        userGroup_name: "",
        userGroup_id: null,
        notes: "",
        permissionList: [...permissionList]
    });
    const [loadings, setLoadings] = useState(false);
    const prevPropsRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const { editDetails } = location.state || [];

    const assignPermissionList = (List) => {
        const updatedPermissionList = formData.permissionList.map(item => {
            const correspondingModule = List.find(apiModule =>
                apiModule.module_name.toLowerCase() === (item.label === "Admin" ? "administrator" : item.label.toLowerCase().replace(/ /g, "_"))
            );
            if (correspondingModule) {
                if (correspondingModule.module_name.toLowerCase() === "home" ||
                 correspondingModule.module_name.toLowerCase() === "dashboard"||
                 correspondingModule.module_name.toLowerCase() === "roster") {
                    return {
                        ...item,
                        ...correspondingModule.module_list[correspondingModule.module_name.toLowerCase()],
                    };
                } else {
                    const updatedSubMenu = item?.subMenu?.map(subMenu => {
                        const subModuleName = subMenu.label.toLowerCase().replace(/ /g, "_");
                        if (correspondingModule.module_list[subModuleName]) {
                            return {
                                ...subMenu,
                                ...correspondingModule.module_list[subModuleName]
                            };
                        }
                        return subMenu;
                    });
                    let allPermissionTrue = true;
                    let allActiveTrue = true;
                    let allEditTrue = true;
                    let allAddTrue = true;
                    let allDraftTrue = true;

                    updatedSubMenu.forEach(subMenu => {
                        if (!subMenu.permission) {
                            allPermissionTrue = false;
                        }
                        if (!subMenu.active) {
                            allActiveTrue = false;
                        }
                        if (!subMenu.edit) {
                            allEditTrue = false;
                        }
                        if (!subMenu.add) {
                            allAddTrue = false;
                        }
                        if (!subMenu.draft) {
                            allDraftTrue = false;
                        }
                    });
                    return {
                        ...item,
                        subMenu: updatedSubMenu,
                        permission: allPermissionTrue ? true : item.permission,
                        active: allActiveTrue,
                        edit: allEditTrue,
                        add: allAddTrue,
                        draft: allDraftTrue
                    };
                }
            } else {
                return item;
            }
        });
        setFormData((prevFormData) => ({
            ...prevFormData,
            permissionList: updatedPermissionList
        }))
    }

    useEffect(() => {
        if (editDetails !== null &&
            Object.keys(editDetails).length !== 0 &&
            JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
            JSON.stringify(formData.permissionList) !== JSON.stringify(editDetails)) {
            assignPermissionList(editDetails?.permissions?.length > 0 ? editDetails?.permissions : [])
            setFormData((prevFormData) => ({
                ...prevFormData,
                id: editDetails.id,
                notes: editDetails.notes ? editDetails.notes : "",
                userGroup_name: Object.keys(editDetails.usergroup).length > 0 ? editDetails?.usergroup?.user_group_name : "",
                userGroup_id: Object.keys(editDetails.usergroup).length > 0 ? editDetails?.usergroup?.usergroup_id : null,
            }));
            prevPropsRef.current = editDetails;
        }
    }, [editDetails, formData])

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const renderInput = (label, statekey, maxLength = 150, isReadOnly = false) => {
        return (
            <div>
                <div className='commonInputTlt'>{label}</div>
                <Input
                autoComplete='off'
                type={"text"}
                name={statekey}
                value={formData[statekey]}
                maxLength={maxLength}
                readOnly={isReadOnly}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
            </div>     
        )
    }

    const handleBackCancel = useCallback(() => {
        navigate('/admin/userpermission');
    }, [navigate])

    const handleSubmit = useCallback(() => {
        const authtoken = sessionStorage.getItem("token")
        try {
            setLoadings(true);
            let updatedFormData = { ...formData };

            let newPermissions = formData?.permissionList?.map(item => {
                let moduleObject = {
                    module_name: item?.label === "Admin" ? "administrator" : item?.label.toLowerCase().replace(/\s/g, "_") ,
                    module_list: {}
                };

                if (item.subMenu) {
                    item.subMenu.forEach(subItem => {
                        let subModuleObject = {
                            add: subItem.add,
                            edit: subItem.edit,
                            draft: subItem.draft,
                            active: subItem.active,
                            permission: subItem.permission
                        };

                        moduleObject.module_list[subItem.label.toLowerCase().replace(/\s/g, "_")] = subModuleObject;
                    });
                } else {
                    let subModuleObject = {
                        add: item.add,
                        edit: item.edit,
                        draft: item.draft,
                        active: item.active,
                        permission: item.permission
                    };

                    moduleObject.module_list[item.label.toLowerCase().replace(/\s/g, "_")] = subModuleObject;
                }

                return moduleObject;
            });
            updatedFormData["permissions"] = [...newPermissions];
            delete updatedFormData.permissionList;

            fetch(ADMIN_USER_PERMISSION, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${authtoken}`
                },
                body: JSON.stringify(updatedFormData)
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        handleBackCancel();
                        setLoadings(false);
                        setFormData({
                            userGroup_name: "",
                            userGroup_id: null,
                            notes: "",
                            permissionList: [...permissionList]
                        })
                    }
                    else if (data.status === "fail") {
                        setLoadings(false);
                        ToastMsg("error", data.message);
                    }
                })
                .catch(error => {
                    setLoadings(false);
                    ToastMsg("error", error.message);
                });
        } catch (error) {
            ToastMsg("error", error.message);
        }
    }, [formData, handleBackCancel]);

    let labelList = ["Active", "Edit", "Add", "Draft"]

    const handleCheckboxChange = (statekey, topLevelKey, value) => {
        setFormData(prevFormData => {
            const newList = prevFormData.permissionList.map(item => {
                if (item.key === topLevelKey) {
                    item[statekey] = value;
                    if (statekey === "permission" && value === false) {
                        item["active"] = value;
                        item["edit"] = value;
                        item["add"] = value;
                        item["draft"] = value;
                    }
                    if (item.subMenu?.length > 0) {
                        item.subMenu.forEach(subItem => {
                            subItem[statekey] = value;
                            if (statekey === "permission" && value === false) {
                                subItem.active = value;
                                subItem.edit = value;
                                subItem.add = value;
                                subItem.draft = value;
                            }
                        });
                        let isPermission = item.subMenu.every(value => value.permission === true);
                        item.permission = isPermission ? true : item.permission;
                    }
                }
                return item;
            });
            return {
                ...prevFormData,
                permissionList: newList
            };
        });
    };

    const handleSubMenuChange = (topLevelKey, subMenuKey, statekey, value) => {
        setFormData(prevFormData => {
            const updatedPermissionList = prevFormData.permissionList.map(item => {
                if (item.key === topLevelKey && item?.subMenu?.length > 0) {
                    const updatedSubMenu = item.subMenu.map(subItem => {
                        if (subItem.key === subMenuKey) {
                            if (statekey === "permission" && value === false) {
                                subItem.active = value;
                                subItem.edit = value;
                                subItem.add = value;
                                subItem.draft = value;
                                item.permission = value;
                            }
                            return { ...subItem, [statekey]: value };
                        }
                        return subItem;
                    });
                    let allPermissionTrue = true;
                    let allActiveTrue = true;
                    let allEditTrue = true;
                    let allAddTrue = true;
                    let allDraftTrue = true;
                    updatedSubMenu.forEach(subMenu => {
                        if (!subMenu.permission) {
                            allPermissionTrue = false;
                        }
                        if (!subMenu.active) {
                            allActiveTrue = false;
                        }
                        if (!subMenu.edit) {
                            allEditTrue = false;
                        }
                        if (!subMenu.add) {
                            allAddTrue = false;
                        }
                        if (!subMenu.draft) {
                            allDraftTrue = false;
                        }
                    });
                    return {
                        ...item,
                        subMenu: updatedSubMenu,
                        permission: allPermissionTrue ? true : item.permission,
                        active: allActiveTrue,
                        edit: allEditTrue,
                        add: allAddTrue,
                        draft: allDraftTrue
                    };
                }
                return item;
            })
            return {
                ...prevFormData,
                permissionList: updatedPermissionList
            };
        });
    };

    let isDefaultList = (formData?.permissionList[0].label === "Home" && formData?.permissionList[1].label === "Dashboard");
    return (
        <Card className='h-full'>
            <div className='h-1/6 flex items-center p-1 border-b'>
                <div className='flex items-center'>
                    <Tooltip title={"Back"} placement="bottom">
                        <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
                    </Tooltip>
                    <p className='common_addTltTxt' style={{ marginRight: "0.625vw" }}>Edit User Permission</p>
                </div>
            </div>
            <div className='h-5/6' style={{ marginTop: "1.8vw" }}>
                <div className='w-full grid grid-rows-1 grid-cols-3 gap-5 pb-6 border-b'>
                    {renderInput('User Group', "userGroup_name", 150, true)}
                    {renderInput('Notes', "notes", 150)}
                </div>
                <div className='w-full grid grid-rows-1 grid-cols-12 gap-8 mb-2 p-3 border-b'>
                    <p id="common_admin_orgTxt" className='col-span-8'>Permissions</p>
                    {labelList?.map((item, index) =>
                        (<p id="common_admin_orgTxt" className='px-2' key={`${item}${index + 1}`}>{item}</p>))}
                </div>
                <div className="overflow-y-auto" style={{ height: "52vh" }}>
                    { formData?.permissionList?.slice(0, isDefaultList ? 2 : 1)?.map((item) => (
                        <div key={item.key} id="userPermission_collapse_div"
                            className='w-full grid grid-rows-1 grid-cols-12 gap-8 items-center border-b'>
                            <Checkbox
                                className='col-span-8'
                                checked={item.permission || false}
                                onChange={(e) => handleCheckboxChange("permission", item.key, e.target.checked)}
                            >
                                <span className='userPermission-checkbox userPermission-checkbox-tlt'>{item.label}</span>
                            </Checkbox>
                            <Checkbox
                                checked={item.active || false}
                                className='px-1'
                                onChange={(e) => handleCheckboxChange("active", item.key, e.target.checked)}
                            />
                            <Checkbox
                                checked={item.edit || false}
                                onChange={(e) => handleCheckboxChange("edit", item.key, e.target.checked)}
                            />
                            <Checkbox
                                checked={item.add || false}
                                className='px-2'
                                onChange={(e) => handleCheckboxChange("add", item.key, e.target.checked)}
                            />
                            <Checkbox
                                checked={item.draft || false}
                                className='px-2'
                                onChange={(e) => handleCheckboxChange("draft", item.key, e.target.checked)}
                            />
                        </div>
                    ))}
                    <Collapse accordion
                        size="small"
                        expandIcon={({ isActive }) => (
                            <img
                                src={ImagePaths.selectArrow.default}
                                style={{
                                    transform: `rotate(${isActive ? 0 : 180}deg)`,
                                    transition: 'transform 0.3s',
                                }}
                                alt="arrow"
                            />
                        )}
                    >
                        {formData?.permissionList?.slice(isDefaultList ? 2 : 1)?.map((item) => (
                            item.subMenu && item.subMenu.length > 0 ? (
                            <Panel key={item.key}
                                header={
                                    <div key={`${item.key}${item.label}`}
                                        className='w-full grid grid-rows-1 grid-cols-12 gap-8'
                                        onClick={(e) => e.stopPropagation()}>
                                        <Checkbox
                                            className='col-span-8'
                                            checked={item.permission || false}
                                            onChange={(e) => handleCheckboxChange("permission", item.key, e.target.checked)}
                                        >
                                            <span className='userPermission-checkbox userPermission-checkbox-tlt'>{item.label}</span>
                                        </Checkbox>
                                        {(item.label === 'Payroll' || item.label === 'Attendance' || item.label === "Reports") ? <div></div> :
                                            <Checkbox
                                                checked={item.active || false}
                                                className='px-1'
                                                onChange={(e) => handleCheckboxChange("active", item.key, e.target.checked)}
                                            />}
                                        {(item.label === "Reports") ? <div></div> : <Checkbox
                                            checked={item.edit || false}
                                            className={(item.label === 'Payroll' || item.label === 'Attendance') ? "px-2" : ""}
                                            onChange={(e) => handleCheckboxChange("edit", item.key, e.target.checked)}
                                        />}
                                        {(item.label === 'Payroll' || item.label === 'Attendance' || item.label === "Reports") ? <div></div> :
                                            <Checkbox
                                                checked={item.add || false}
                                                className='px-2'
                                                onChange={(e) => handleCheckboxChange("add", item.key, e.target.checked)}
                                            />}
                                        {(item.label === 'Payroll' || item.label === 'Attendance' || item.label === "Reports") ? <div></div> :
                                            <Checkbox
                                                checked={item.draft || false}
                                                className='px-2'
                                                onChange={(e) => handleCheckboxChange("draft", item.key, e.target.checked)}
                                            />}
                                    </div>
                                }>
                                {item?.subMenu && (
                                    <div className="flex flex-col">
                                        {item.subMenu.map((subItem) => (
                                            <div key={subItem.key} id="userPermission_collapse_Subdiv"
                                                className='w-full grid grid-rows-1 grid-cols-12 gap-10'>
                                                <Checkbox
                                                    className='col-span-8 pl-8'
                                                    checked={subItem.permission || false}
                                                    onChange={(e) => handleSubMenuChange(item.key, subItem.key, "permission", e.target.checked)}
                                                >
                                                    <span className='userPermission-checkbox userPermission-checkbox-subTlt'>
                                                        {subItem.label}</span>
                                                </Checkbox>
                                                {(item.label === 'Payroll' || item.label === 'Attendance' || item.label === "Reports") ? <div></div> :
                                                <Checkbox
                                                    checked={subItem.active || false}
                                                    className='px-1'
                                                    onChange={(e) => handleSubMenuChange(item.key, subItem.key, "active", e.target.checked)}
                                                />}
                                                {(item.label === "Reports") ? <div></div> : <Checkbox
                                                    checked={subItem.edit || false}
                                                    className={(item.label === 'Payroll' || item.label === 'Attendance') ? "px-2" : ""}
                                                    onChange={(e) => handleSubMenuChange(item.key, subItem.key, "edit", e.target.checked)}
                                                />}
                                                {(item.label === 'Payroll' || item.label === 'Attendance' || item.label === "Reports") ? <div></div> :
                                                <Checkbox
                                                    checked={subItem.add || false}
                                                    className='px-2'
                                                    onChange={(e) => handleSubMenuChange(item.key, subItem.key, "add", e.target.checked)}
                                                />}
                                                {(item.label === 'Payroll' || item.label === 'Attendance' || item.label === "Reports") ? <div></div> :
                                                <Checkbox
                                                    checked={subItem.draft || false}
                                                    className='px-1'
                                                    onChange={(e) => handleSubMenuChange(item.key, subItem.key, "draft", e.target.checked)}
                                                />}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Panel>) 
                                : <div key={`${item.key}${item.label}`}
                                    id="userPermission_collapse_div"
                                    className='w-full grid grid-rows-1 grid-cols-12 gap-8 items-center border-b'
                                    onClick={(e) => e.stopPropagation()}>
                                    <Checkbox
                                        className='col-span-8'
                                        checked={item.permission || false}
                                        onChange={(e) => handleCheckboxChange("permission", item.key, e.target.checked)}
                                    >
                                        <span className='userPermission-checkbox userPermission-checkbox-tlt'>{item.label}</span>
                                    </Checkbox>
                                    {(item.label === 'Roster') ? <div></div> :
                                        <Checkbox
                                            className='px-1'
                                            checked={item.active || false}
                                            onChange={(e) => handleCheckboxChange("active", item.key, e.target.checked)}
                                        />}
                                    {(item.label === "Reports") ? <div></div> : <Checkbox
                                        checked={item.edit || false}
                                        className={"px-2"}
                                        onChange={(e) => handleCheckboxChange("edit", item.key, e.target.checked)}
                                    />}
                                    {(item.label === 'Payroll') ? <div></div> :
                                        <Checkbox
                                            checked={item.add || false}
                                            className='px-2'
                                            onChange={(e) => handleCheckboxChange("add", item.key, e.target.checked)}
                                        />}
                                    {(item.label === "Roster") ? <div></div> :
                                        <Checkbox
                                            checked={item.draft || false}
                                            className='px-2'
                                            onChange={(e) => handleCheckboxChange("draft", item.key, e.target.checked)}
                                        />}
                                </div>
                        ))}
                    </Collapse>
                </div>
                <div className='w-full relative inset-y-4 flex justify-end items-end'>
                    <MyButton htmlType="button" label="Cancel" onClick={handleBackCancel} outlined = {true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
                    <MyButton htmlType="submit" label="Update" onClick={handleSubmit} loading={loadings} paddingX={"0 1.2vw"} />
                </div>
            </div>
        </Card>
    );
}

export default EditUserPermission
