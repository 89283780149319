import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Form,
  Input,
  Modal,
  DatePicker,
  Select,
  Button,
  Tooltip,
  Upload,
  TimePicker,
} from "antd";
import MyButton from "components/ui/Button/MyButton";
import ToastMsg from "components/common/ToastMsg";
import {
  noBlankSpacesValidator,
  areValuesNotEmptyExceptKeys,
  extractImageName,
  extractFileExtension,
  formatTimeToAMPM,
} from "components/common/validation";
import { EMPLOYEE_LEAVE, APPROVAL_LEAVE_LIST } from "constants";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { getMethod } from "components/common/CommonFuntion";
import {
  imgUploadSize,
  leaveTypeConfigEnm,
  selectPeriodEnm,
} from "components/common/enum";
import { ImagePaths } from "utils/ImagePath";
import IconButton from "components/ui/Button/IconButton";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { CommonCarouselPopup } from "components/common/CommonComponent";
import moment from "moment";
import { getEmployeeLeavePolicy } from "hooks/api/masterApi/employeeLeavePolicyApi";

dayjs.extend(isBetween);

function AddEditLeaveRequest({
  fromEdit,
  editDetails,
  onHide,
  onSave,
  leaveTypeList,
  available_leave,
}) {
  const authtoken = sessionStorage.getItem("token");
  const companyid = sessionStorage.getItem("userId");
  const prevPropsRef = useRef();
  const dateFormat = "YYYY-MM-DD";

  const [formData, setFormData] = useState({
    leave_request_type: "leave",
    leave_type: "",
    period: "",
    from_date: undefined,
    to_date: undefined,
    rejoining_date: undefined,
    total_days: "",
    premission_date: "",
    start_time: "",
    end_time: "",
    total_duration: "",
    reason: "",
    company_id: parseInt(companyid),
    is_active: true,
    uploaded_documents: [],
  });

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const hideCursorRef = useRef(null);
  const [leaveApprovedList, setLeaveApprovedList] = useState([]);
  const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);
  const [remainingLeaves, setRemainingLeaves] = useState(null)
  const [isImgOpen, setImgOpen] = useState(false);
  const [remainingChars, setRemainingChars] = useState(250);
  const [currentSlide, setCurrentSlide] = useState(0);

  const getApprovedLeaveList = async () => {
    try {
      const apiData = await getMethod(APPROVAL_LEAVE_LIST);
      if (apiData && apiData.status === "success" && apiData.data) {
        setLeaveApprovedList([...apiData.data]);
      } else {
        setLeaveApprovedList([]);
      }
    } catch (error) {
      setLeaveApprovedList([]);
    }
  };

  const getEmployeeLeavePolicyList = async () => {
    try {
      const apiData = await getEmployeeLeavePolicy(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setLeaveTypeOptions(apiData.data); // Update casual leave options
      } else {
        setLeaveTypeOptions([]); // Clear options if no data
      }
    } catch (error) {
      console.error(error);
      setLeaveTypeOptions([]); // Clear options on error
    }
  };

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
    getApprovedLeaveList();
  }, [authtoken]);

  useEffect(() => {
    getEmployeeLeavePolicyList();
  }, []);

  function secondsToHours(seconds) {
    const hours = Math.floor(seconds / 3600); // Get the whole number of hours
    const minutes = Math.floor((seconds % 3600) / 60); // Get the remaining minutes
    const remainingSeconds = seconds % 60; // Get the remaining seconds

    // Format the output string as needed
    return `${hours}h ${minutes}m ${remainingSeconds}s`; // Example format: "1h 0m 0s"
  }

  useEffect(() => {
    if (
      editDetails !== null &&
      Object.keys(editDetails).length !== 0 &&
      JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
      JSON.stringify(formData) !== JSON.stringify(editDetails)
    ) {
      const updatedFormData = {
        id: editDetails.id,
        employee: editDetails.employee_id,
        leave_request_type: editDetails.leave_request_type || "",
        leave_type: editDetails.leave_type_id || "",
        period: editDetails.period || "",
        // Use dayjs to parse date strings into date objects
        from_date: editDetails.from_date
          ? dayjs(editDetails.from_date, "DD-MM-YYYY").locale("en")
          : undefined,
        to_date: editDetails.to_date
          ? dayjs(editDetails.to_date, "DD-MM-YYYY").locale("en")
          : undefined,
        rejoining_date: editDetails.rejoining_date
          ? dayjs(editDetails.rejoining_date, dateFormat)
          : undefined,
        premission_date: editDetails.premission_date
          ? dayjs(editDetails.premission_date, dateFormat)
          : undefined,
        start_time: editDetails.start_time
          ? dayjs(editDetails.start_time, "HH:mm")
          : undefined,
        end_time: editDetails.end_time
          ? dayjs(editDetails.end_time, "HH:mm")
          : undefined,
        total_duration: editDetails.total_duration
          ? secondsToHours(Number(editDetails.total_duration))
          : "0h 0m 0s",
        reason: editDetails.reason || "",
        is_active: editDetails.is_active,
        company_id: parseInt(editDetails.company_id),
        uploaded_documents:
          editDetails.uploaded_documents?.length > 0
            ? editDetails.uploaded_documents.map((url) => ({
              name: extractImageName(url),
              url: url,
            }))
            : [],
      };

      // Set state and form values
      setFormData(updatedFormData);

      form.setFieldsValue({
        leave_request_type: updatedFormData.leave_request_type,
        leave_type: updatedFormData.leave_type,
        period: updatedFormData.period,
        // Set the formatted date objects to the form fields
        from_date: updatedFormData.from_date ? updatedFormData.from_date : null,
        to_date: updatedFormData.to_date ? updatedFormData.to_date : null,
        rejoining_date: updatedFormData.rejoining_date
          ? updatedFormData.rejoining_date
          : null,
        premission_date: updatedFormData.premission_date
          ? updatedFormData.premission_date
          : null,
        start_time: updatedFormData.start_time
          ? updatedFormData.start_time
          : null,
        end_time: updatedFormData.end_time ? updatedFormData.end_time : null,
        total_duration: updatedFormData.total_duration,
        reason: updatedFormData.reason,
        uploaded_documents: updatedFormData.uploaded_documents,
      });

      prevPropsRef.current = editDetails;
    }
  }, [editDetails]);

  // reason calculation
  useEffect(() => {
    setRemainingChars(250 - formData.reason.length);
  }, [formData.reason]);

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    onHide();
    setFormData({
      leave_request_type: "leave",
      leave_type: "",
      period: "",
      from_date: undefined,
      to_date: undefined,
      rejoining_date: undefined,
      total_days: "",
      premission_date: "",
      start_time: "",
      end_time: "",
      total_duration: "",
      reason: "",
      company_id: parseInt(companyid),
      is_active: true,
      uploaded_documents: [],
    });
  };

  const handleSubmit = useCallback(
    (isDraftClked) => {
      let methods = fromEdit ? "PUT" : "POST";
      const authtoken = sessionStorage.getItem("token");
      try {
        setLoading(true);
        const dataForm = new FormData();
        // leave_request_type
        dataForm.append(
          "leave_request_type",
          formData.leave_request_type ? formData.leave_request_type : ""
        );
        // leave_type
        if (formData.leave_type) {
          dataForm.append("leave_type", formData.leave_type);
        } else {
          dataForm.delete("leave_type");
        }
        // period
        if (formData.period) {
          dataForm.append("period", formData.period ? formData.period : "");
        } else {
          dataForm.delete("period");
        }
        // from_date
        if (formData.from_date) {
          dataForm.append(
            "from_date",
            formData.from_date
              ? dayjs(formData.from_date, "DD-MM-YYYY").format(dateFormat)
              : ""
          );
        } else {
          dataForm.delete("from_date");
        }
        // to_date
        if (formData.to_date) {
          dataForm.append(
            "to_date",
            formData.to_date
              ? dayjs(formData.to_date, "DD-MM-YYYY").format(dateFormat)
              : ""
          );
        } else {
          dataForm.delete("to_date");
        }
        // rejoining_date
        if (formData.rejoining_date) {
          dataForm.append(
            "rejoining_date",
            formData.rejoining_date
              ? dayjs(formData.rejoining_date, "DD-MM-YYYY").format(dateFormat)
              : ""
          );
        } else {
          dataForm.delete("rejoining_date");
        }
        //   premission_date: "",
        if (formData.premission_date) {
          dataForm.append(
            "premission_date",
            formData.premission_date
              ? dayjs(formData.premission_date, "DD-MM-YYYY").format(dateFormat)
              : ""
          );
        } else {
          dataForm.delete("premission_date");
        }
        //   start_time,
        if (formData.start_time) {
          dataForm.append(
            "start_time",
            formData.start_time
              ? dayjs(formData.start_time).format("HH:mm:ss")
              : ""
          );
        } else {
          dataForm.delete("start_time");
        }
        //   end_time,
        if (formData.end_time) {
          dataForm.append(
            "end_time",
            formData.end_time ? dayjs(formData.end_time).format("HH:mm:ss") : ""
          );
        } else {
          dataForm.delete("end_time");
        }

        if (formData.total_duration) {
          // Convert the "1h 15m 0s" format to HH:mm:ss
          const totalDurationString = formData.total_duration;

          // Use a regular expression to extract hours, minutes, and seconds from the string
          const durationParts = totalDurationString.match(
            /(\d+)h\s(\d+)m\s(\d+)s/
          );

          if (durationParts) {
            const hours = parseInt(durationParts[1], 10); // Extract hours
            const minutes = parseInt(durationParts[2], 10); // Extract minutes
            const seconds = parseInt(durationParts[3], 10); // Extract seconds

            // Format as HH:mm:ss
            const formattedTotalDuration = `${String(hours).padStart(
              2,
              "0"
            )}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
              2,
              "0"
            )}`;

            dataForm.append("total_duration", formattedTotalDuration);
          } else {
            // Fallback in case of unexpected format
            console.error("Invalid total_duration format");
            dataForm.append("total_duration", "00:00:00");
          }
        } else {
          // Remove total_duration from the form data if not present
          dataForm.delete("total_duration");
        }

        // reason
        dataForm.append("reason", formData.reason ? formData.reason : "");
        dataForm.append(
          "is_active",
          formData.is_active ? formData.is_active : ""
        );
        dataForm.append(
          "company",
          formData.company_id ? formData.company_id : ""
        );
        dataForm.append("employee", formData.employee ? formData.employee : "");
        dataForm.append("request_type", "leave request");
        dataForm.append("approval_status", "pending");
        formData.uploaded_documents?.length > 0
          ? formData.uploaded_documents.forEach((image, index) => {
            if (image?.url) {
              dataForm.append(`uploaded_documents_${index + 1}`, image.url);
            } else {
              dataForm.append(
                `uploaded_documents_${index + 1}`,
                image.originFileObj
              );
            }
          })
          : dataForm.append("uploaded_documents", []);

        if (isDraftClked) {
          dataForm.append("is_draft", true);
        } else {
          dataForm.append("is_draft", false);
        }
        if (editDetails?.is_draft) {
          dataForm.append("approval_status", "pending");
        }
        if (fromEdit) {
          dataForm.append("id", formData.id);
        }

        fetch(EMPLOYEE_LEAVE, {
          method: methods,
          headers: {
            //'Content-Type': 'application/json',
            Authorization: `token ${authtoken}`,
          },
          body: dataForm,
        })
          .then((response) => response.json())
          .then(async (data) => {
            if (data.status === "success") {
              ToastMsg("success", data.message);
              onSave();
              handleCancel();
              setLoading(false);
              setFormData({
                code: "",
                Deduction_name: "",
                deduct_from: "",
                fixed: false,
                is_active: true,
                share: "",
                deduction_value: 0,
                notes: "",
                company_id: companyid,
                uploaded_documents: [],
              });
            } else if (data.status === "fail") {
              setLoading(false);
              ToastMsg("error", data.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            ToastMsg("error", error.message);
          });
      } catch (error) {
        ToastMsg("error", error.message);
      }
    },
    [formData, fromEdit, companyid, handleCancel, onSave]
  );

  const disabledDateFrom = (restrictionDate) => (current) => {
    if (restrictionDate) {
      const startDate = dayjs(restrictionDate, "DD-MM-YYYY");
      return current && current < startDate.startOf("day");
    }
    return current && current > dayjs().endOf("day");
  };

  const disabledApprovedLeaveDates = (current) => {
    const dateRanges = leaveApprovedList?.map((leave) => ({
      from: dayjs(leave.from_date),
      to: dayjs(leave.to_date),
    }));
    return (
      dayjs(current).isBefore(dayjs(), "day") ||
      dateRanges?.some((range) =>
        current.isBetween(range.from, range.to, "day", "[]")
      )
    );
  };

  const renderDatePicker = (statekey) => {
    return (
      <DatePicker
        allowClear
        inputReadOnly={true}
        value={formData[statekey] ? dayjs(formData[statekey]) : undefined} // Ensure date is in dayjs format
        format={"DD-MM-YYYY"}
        style={{ width: "100%" }}
        onChange={(date, dateString) => {
          let newFormData = { ...formData, [statekey]: date };

          if (statekey === "from_date") {
            if (formData.period === "full_day") {
              // Clear `to_date` when full day is selected and `from_date` is changed
              newFormData.to_date = undefined;
            } else if (
              formData.period === "first_half" ||
              formData.period === "second_half"
            ) {
              // Automatically set `to_date` to `from_date` for half-day periods
              newFormData.to_date = date;
            }
          }

          setFormData(newFormData); // Update form data state
          form.setFieldsValue(newFormData); // Sync with Ant Design form
        }}
        disabled={
          statekey === "to_date"
            ? !formData.from_date || formData.period !== "full_day" // Disable `to_date` unless full day is selected
            : false
        }
        disabledDate={
          statekey === "from_date"
            ? disabledApprovedLeaveDates // Function to disable specific approved leave dates
            : statekey === "to_date"
              ? (current) =>
                disabledApprovedLeaveDates(current) ||
                disabledDateFrom(formData.from_date)(current) // Disable dates earlier than `from_date`
              : statekey === "premission_date"
                ? null // No disabling logic for permission date
                : disabledDateFrom(formData.to_date)
        }
        placeholder=""
      />
    );
  };

  const checkAllMandatory = useCallback(
    (isDraftClked) => {
      if (!isDraftClked) {
        form
          .validateFields()
          .then(() => {
            handleSubmit(false);
          })
          .catch((error) => {
            console.error("Validation Error:", error);
          });
      } else {
        handleSubmit(true);
      }
    },
    [form, handleSubmit]
  );

  const handleSave = useCallback(() => {
    checkAllMandatory(false);
  }, [checkAllMandatory]);

  const handleSaveAsDraft = useCallback(() => {
    checkAllMandatory(true);
  }, [checkAllMandatory]);

  const keysToExclude = ["is_active", "company_id"];
  const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);

  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const generateLeaveSentence = (data) => {
    let sentenceParts = [];
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        let readableKey = key.replace(/([A-Z])/g, " $1").toLowerCase();
        sentenceParts.push(`${data[key]} ${readableKey}`);
      }
    }
    let sentence = sentenceParts.join(", ").replace(/,([^,]*)$/, " and$1");

    return `You have ${sentence}.`;
  };

  let leaveInfo =
    available_leave !== null ? generateLeaveSentence(available_leave) : "";

  const handleUploadChange = ({ fileList }) => {
    const filteredFileList = fileList.filter((file) => {
      const fileSizeMB = file.size ? file.size / (1024 * 1024) : true;
      const isImageOrPdf = file.type
        ? file.type.startsWith("image/") || file.type === "application/pdf"
        : true;

      if (!isImageOrPdf) {
        ToastMsg("error", "Unsupported file type");
        return false;
      } else if (fileSizeMB > imgUploadSize) {
        ToastMsg("error", `File size should be less than ${imgUploadSize}MB`);
        return false;
      } else {
        return true;
      }
    });
    setFormData({
      ...formData,
      uploaded_documents: filteredFileList,
    });
  };

  const beforeUpload = (file) => {
    const fileSizeMB = file.size / (1024 * 1024);
    const isImageOrPdf =
      file.type.startsWith("image/") || file.type === "application/pdf";
    if (!isImageOrPdf) {
      return false;
    } else if (fileSizeMB > imgUploadSize) {
      return false;
    }
    return true;
  };

  const handleRemoveImage = (index) => {
    const newUploadedImages = [...formData.uploaded_documents];
    newUploadedImages.splice(index, 1);
    setFormData({
      ...formData,
      uploaded_documents: newUploadedImages,
    });
  };

  const handlePreviewImage = (e, index) => {
    e.preventDefault()    
    if (formData?.uploaded_documents.length > 0) {
      setCurrentSlide(index); // Set the clicked image index
      setImgOpen(true);
    }
  };

  const handlePreviewCancel = () => {
    setImgOpen(false);
  };

  const handleTimeChange = (field, value) => {
    setFormData((prevData) => {
      const updatedFormData = {
        ...prevData,
        [field]: value,
      };

      // Only calculate duration if both start and end times are set
      if (updatedFormData.start_time && updatedFormData.end_time) {
        const duration = calculateDuration(
          updatedFormData.start_time,
          updatedFormData.end_time
        );
        updatedFormData.total_duration = duration; // Set the formatted duration
      }

      return updatedFormData;
    });
  };

  const calculateDuration = (startTime, endTime) => {
    if (startTime && endTime) {
      const start = dayjs(startTime, "HH:mm");
      const end = dayjs(endTime, "HH:mm");

      let totalSeconds = end.diff(start, "second"); // Calculate the difference in seconds

      console.log(totalSeconds);

      // If the duration is negative (spanning past midnight), add 24 hours (in seconds)
      if (totalSeconds < 0) {
        totalSeconds += 24 * 3600; // 24 hours * 3600 seconds per hour
      }

      if (totalSeconds >= 0) {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        // Return the formatted duration like "1h 0m 0s"
        return `${hours}h ${minutes}m ${seconds}s`;
      }
    }
    return "0h 0m 0s"; // Default return value if no valid duration
  };

  const ImageUploader = () => {
    return (
      <div>
        <div
          className="flex justify-center items-center"
          style={{ width: "100%", height: "15vw", backgroundColor: "#cbcbcb" }}
        >
          <div
            className="flex justify-center items-center"
            style={{
              width: "90%",
              height: "90%",
              border: "2px dashed gray",
              borderRadius: "0.3vw",
              position: "relative",
            }}
          >
            {formData.uploaded_documents?.length === 6 ? (
              <p id="common_reimburse_supportTxt">
                You have reached the maximum limit of 6 file attachments.
              </p>
            ) : (
              <Upload
                accept="image/*, application/pdf"
                beforeUpload={beforeUpload}
                onChange={handleUploadChange}
                fileList={formData.uploaded_documents}
                multiple
                maxCount={6}
                showUploadList={false}
              >
                <div className="flex flex-col justify-center items-center">
                  <img
                    src={ImagePaths.imageUpload.default}
                    alt="upload image"
                    style={{
                      width: "2.5vw",
                      height: "2.5vw",
                      marginBottom: "0.8vw",
                    }}
                  />
                  <p id="common_cancelTxt" style={{ marginBottom: "0.8vw" }}>
                    Drag And Drop Or
                  </p>
                  <Button
                    type="text"
                    id="common_cancelTxt"
                    size="small"
                    style={{ borderRadius: 0, marginBottom: "0.8vw" }}
                  >
                    Click Here To Upload
                  </Button>
                  <p id="common_reimburse_supportTxt">
                    Supported Format Pdf And All Image Formats
                  </p>
                </div>
              </Upload>
            )}
          </div>
        </div>
        {formData.uploaded_documents?.length > 0 ? (
          <div className="flex justify-between items-center flex-wrap mt-2">
            {formData.uploaded_documents.map((file, index) => (
              <div
                key={index}
                className="flex justify-between items-center border-b w-96"
                style={{ paddingInline: "0.3dvw" }}
              >
                {file?.name?.length > 15 ? (
                  <Tooltip title={file?.name}>
                    {
                      <span style={{ fontSize: "0.8vw" }}>
                        {"..." + file?.name?.slice(-14)}
                      </span>
                    }
                  </Tooltip>
                ) : (
                  <p style={{ fontSize: "0.8vw" }}>
                    {file?.name ? file?.name : "-"}
                  </p>
                )}
                <div className="flex justify-between items-center">
                  <IconButton
                    title="View"
                    icon={<EyeOutlined style={styles.closeIcon} />}
                    onClick={(e) => handlePreviewImage(e, index)}
                  />
                  <IconButton
                    title="Delete"
                    icon={<DeleteOutlined style={styles.closeIcon} />}
                    onClick={() => handleRemoveImage(index)}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={"65vw"}
    >
      <div className="w-full">
        {/* dialog header */}
        <p id="common_ApprovalTxt" className="text-lg font-semibold mb-4">
          {fromEdit ? "Edit Leave" : "Apply For Leave"}
        </p>
        <Form
          layout="vertical"
          form={form}
          name="basicform"
          onFinish={handleSave}
          onSubmit={(e) => e.preventDefault()}
          colon={false}
          requiredMark={false}
          style={{ width: "100%" }}
          initialValues={true}
        >
          <div className="flex flex-col lg:flex-row gap-6 items-start">
            {/* Left Column */}
            <div className="w-full lg:w-4/5">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {/* Request Type */}
                <Form.Item
                  label="Request Type"
                  name="leave_request_type"
                  rules={[{ required: true, message: "This field is required" }]}
                  tooltip={{
                    title: "This field is required",
                    icon: <span style={{ color: "red" }}>*</span>,
                  }}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    value={formData.leave_request_type || ""}
                    name="leave_request_type"
                    onChange={(value) => {
                      setFormData({ ...formData, leave_request_type: value });
                      form.setFieldValue("leave_request_type", value);
                    }}
                    filterOption={filterOption}
                    ref={hideCursorRef}
                    onSelect={() => hideCursorRef.current.blur()}
                  >
                    {leaveTypeConfigEnm.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                {/* Conditional Fields */}
                {formData.leave_request_type === "leave" && (
                  <Form.Item
                    label="Leave Type"
                    name="leave_type"
                    rules={[{ required: true, message: "This field is required" }]}
                    tooltip={{
                      title: "This field is required",
                      icon: <span style={{ color: "red" }}>*</span>,
                    }}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      value={formData.leave_type}
                      onChange={(value) => {
                        setFormData((prevData) => ({
                          ...prevData,
                          leave_type: value,
                        }));
                        form.setFieldsValue({ leave_type: value });

                        // Find the selected leave type to get remaining leaves
                        const selectedLeave = leaveTypeOptions.find((option) => option.id === value);
                        setRemainingLeaves(selectedLeave ? selectedLeave.remaining_leaves : null);
                      }}
                    >
                      {Array.isArray(leaveTypeOptions) &&
                        leaveTypeOptions.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.leave_name}
                          </Select.Option>
                        ))}
                    </Select>

                    {remainingLeaves !== null && (
                      <p style={{ fontSize: "0.6rem", marginTop:"2px", color: remainingLeaves === 0 ? "red": "#8c8c8c" }}>
                        Remaining Leaves: {remainingLeaves}
                      </p>
                    )}
                  </Form.Item>
                )}

                {formData.leave_request_type !== "permission" && (
                  <Form.Item
                    label="Select Period"
                    name="period"
                    rules={[{ required: true, message: "This field is required" }]}
                    tooltip={{
                      title: "This field is required",
                      icon: <span style={{ color: "red" }}>*</span>,
                    }}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      value={formData.period || ""}
                      name="period"
                      onChange={(value) => {
                        setFormData({ ...formData, period: value || "" });
                        form.setFieldValue("period", value || "");
                      }}
                      filterOption={filterOption}
                      ref={hideCursorRef}
                      onSelect={() => hideCursorRef.current.blur()}
                    >
                      {selectPeriodEnm.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.text}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                {/* Date Pickers */}
                {(formData.leave_request_type === "leave" ||
                  formData.leave_request_type === "work_from_home") && (
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <Form.Item
                        label="From Date"
                        name="from_date"
                        rules={[{ required: true, message: "This field is required" }]}
                        tooltip={{
                          title: "This field is required",
                          icon: <span style={{ color: "red" }}>*</span>,
                        }}
                      >
                        {renderDatePicker("from_date", formData.from_date)}
                      </Form.Item>
                      <Form.Item
                        label="To Date"
                        name="to_date"
                        rules={[{ required: true, message: "This field is required" }]}
                        tooltip={{
                          title: "This field is required",
                          icon: <span style={{ color: "red" }}>*</span>,
                        }}
                      >
                        {renderDatePicker("to_date", formData.to_date)}
                      </Form.Item>
                    </div>
                  )}

                {/* Permission Fields */}
                {formData.leave_request_type === "permission" && (
                  <>
                    <Form.Item
                      label="Select Date"
                      name="premission_date"
                      rules={[{ required: true, message: "This field is required" }]}
                      tooltip={{
                        title: "This field is required",
                        icon: <span style={{ color: "red" }}>*</span>,
                      }}
                    >
                      {renderDatePicker("premission_date")}
                    </Form.Item>
                    <div className="flex flex-col gap-2">
                      <div className="flex justify-between items-center">
                        <div className="commonInputTlt">Select Time</div>
                        <div className="commonInputTlt">
                          Total duration: {formData?.total_duration || 0}
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row gap-4">
                        <Form.Item label="Start Time">
                          <TimePicker
                            value={formData.start_time}
                            onChange={(time) => handleTimeChange("start_time", time)}
                            use12Hours
                            format="h:mm:ss"
                            className="w-full"
                          />
                        </Form.Item>
                        <Form.Item label="End Time">
                          <TimePicker
                            value={formData.end_time}
                            onChange={(time) => handleTimeChange("end_time", time)}
                            use12Hours
                            format="h:mm:ss"
                            className="w-full"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </>
                )}

              </div>
              {/* Reason Section */}
              <Form.Item
                label="Reason"
                name="reason"
                rules={[
                  { required: true, message: "This field is required" },
                  { validator: noBlankSpacesValidator },
                ]}
                tooltip={{
                  title: "This field is required",
                  icon: <span style={{ color: "red" }}>*</span>,
                }}
              >
                <Input.TextArea
                  className="mt-2 w-full"
                  maxLength={250}
                  rows={3}
                  autoSize={{ minRows: 3, maxRows: 4 }}
                  placeholder="Enter Reason"
                  value={formData.reason}
                  onChange={(e) => {
                    setFormData({ ...formData, reason: e.target.value });
                    form.setFieldsValue({ reason: e.target.value });
                  }}
                />
              </Form.Item>
                {/* Character Count Display */}
                <div style={{ fontSize: "0.6rem", marginTop:"-5px", color: "#8c8c8c" }}>
                  {remainingChars} characters remaining
                </div>
            </div>

            {/* Right Column */}
            <div className="w-full lg:w-4/5">
              <Form.Item name="uploaded_documents">
                <ImageUploader />
              </Form.Item>
            </div>
          </div>


          {/* Action Buttons */}
          <Form.Item>
            <div className="flex justify-end items-center gap-4 mt-2">
              <MyButton
                htmlType="button"
                label="Cancel"
                onClick={handleCancel}
                outlined
              />
              {!fromEdit && (
                <MyButton
                  htmlType="button"
                  label="Draft"
                  onClick={handleSaveAsDraft}
                  disabled={!isDataAvail}
                  bgColor={isDataAvail ? "#334B49" : "#cbcbcb"}
                  loading={loading}
                />
              )}
              <MyButton
                htmlType="submit"
                label={fromEdit ? "Update Request" : "Send Request"}
                loading={loading}
                disabled={remainingLeaves === 0 ? true : false}
                bgColor={remainingLeaves === 0 ? "#cbcbcb" : "#334B49"}
              />
            </div>
          </Form.Item>
        </Form>
      </div>

      {/* Image Preview Modal */}
      {isImgOpen && formData?.uploaded_documents.length > 0 && (
        <Modal
          centered
          open={isImgOpen}
          onCancel={handlePreviewCancel}
          footer={null}
          width={"50vw"}
        >
          <CommonCarouselPopup documents={[...formData?.uploaded_documents]}  initialSlide={currentSlide} />
        </Modal>
      )}
    </Modal>

  );
}
const styles = {
  closeIcon: {
    color: "#04B7B1",
    fontSize: "0.95vw",
    fontWeight: "bolder",
  },
};
export default AddEditLeaveRequest;
